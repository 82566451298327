export const statusColors = {
  FAILED: {
    text: 'error.main',
    icon: 'error',
    progress: 'error',
    uploadBox: 'error.30p',
  },
  LOADING: {
    text: 'text.disabled',
    icon: 'primary',
    progress: 'primary',
    uploadBox: 'info.back',
  },
  COMPLETE: {
    text: 'text.disabled',
    icon: 'primary',
    progress: 'primary',
    uploadBox: 'info.back',
  },
};
