/**
 *
 * Asynchronously loads the component for ListAdminGroups
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ListAdminGroups = lazyLoad(
  () => import('./index'),
  module => module.ListAdminGroups,
);
