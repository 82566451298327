import React, { PropsWithChildren } from 'react';
import ReactModal from 'react-modal';
import { Box, IconButton, SxProps } from '@mui/material';
import { Close } from '@mui/icons-material';
import style from './style';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  closeModalBtnStyleProps?: any;
  closeModalBtnStyle?: any;
  contentSx?: SxProps;
  hideOnClose?: boolean;
}

/**
 * #### Usage
 * Wrapper for creating a modal with ReactModal
 *
 * ! Deprecated method, use modal.new component instead
 */
export function Modal({
  children,
  isOpen,
  onClose,
  hideOnClose,
  closeModalBtnStyle,
  closeModalBtnStyleProps,
  contentSx,
}: Props & PropsWithChildren<{}>) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={{
        content: { ...style.content, ...contentSx },
        overlay: style.overlay,
      }}
    >
      <Box sx={{ ...style.closeModalBtnStyle, ...closeModalBtnStyle }}>
        {onClose && !hideOnClose && (
          <IconButton onClick={onClose} sx={closeModalBtnStyleProps}>
            <Close />
          </IconButton>
        )}
      </Box>
      {children}
    </ReactModal>
  );
}
