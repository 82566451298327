import { useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Box, Divider, Link, Stack, Typography, Grid } from '@mui/material';
import { capitalize } from '@mui/material/utils';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OSS_URL } from 'utils/urls';
import { BottomRightGroupButtons } from 'app/components/Buttons/BottomRightGroupButtons';
import { ControlledInput } from 'app/components/Inputs/ControlledInput';
import { ControlledInputCountry } from 'app/components/Inputs/ControlledInputCountry';
import { ControlledInputPhone } from 'app/components/Inputs/ControlledInputPhone';
import MultipleChipCheckbox from 'app/components/MultipleChipCheckBox';
import { getInstance } from 'utils/http';

import style from './style';
import { Input } from 'app/components/Inputs';

interface UserInformationFormProps {
  useFormInstance: UseFormReturn<any>;
  isValid: Boolean;
  isLocked: boolean;
  setIsLocked: (value: boolean) => void;
}
export function UserInformationForm({
  useFormInstance,
  isValid,
  isLocked,
  setIsLocked,
}: UserInformationFormProps) {
  const { t } = useTranslation();
  const {
    register,
    watch,
    formState: { errors },
    control,
  } = useFormInstance;
  const [parent] = useAutoAnimate();
  const [isSuccessful, setIsSuccessful] = useState(false);

  const chipsTranslations = t('profile.chips', { returnObjects: true });

  const profile = Object.entries(chipsTranslations['profile']).map(
    ([value, label]) => ({ value, label }),
  );
  const interest = Object.entries(chipsTranslations['interest']).map(
    ([value, label]) => ({ value, label }),
  );

  const fear = Object.entries(chipsTranslations['fear']).map(
    ([value, label]) => ({ value, label }),
  );

  const handleEditInfo = () => {
    setIsLocked(!isLocked);
  };

  const handleCancelEditInfo = () => {
    setIsLocked(true);
  };

  const handleChangePassword = async () => {
    if (isSuccessful) return;

    const instance = getInstance(OSS_URL);
    const result = await instance
      .post('/user/self-service/password-reset')
      .catch(console.error);

    if (result?.status === 200 || result?.status === 201) {
      setIsSuccessful(true);
    }
  };

  const getUpdatedOptions = (field, option) => {
    const isSelected = (field.value || []).some(
      selected => selected.value === option.value,
    );

    return isSelected
      ? (field.value || []).filter(selected => selected.value !== option.value)
      : [...(field.value || []), option];
  };

  return (
    <Stack spacing={2} ref={parent}>
      <Box sx={style.boxContainerStyles}>
        <Typography variant="h5">
          {capitalize(t('profile.header.yourProfile'))}
        </Typography>
        {isLocked ? (
          <BottomRightGroupButtons
            primaryBtnText={capitalize(t('profile.buttons.editInfo'))}
            primaryBtnStyles={style.primaryButtonStyles}
            onPrimaryBtnClick={handleEditInfo}
          />
        ) : (
          <BottomRightGroupButtons
            secondaryBtnText={capitalize(t('profile.buttons.save'))}
            secondaryBtnDisabled={!isValid}
            cancelBtnText={capitalize(t('profile.buttons.cancel'))}
            onCancelBtnClick={handleCancelEditInfo}
          />
        )}
      </Box>
      {isLocked ? (
        <Stack direction="row" spacing={2}>
          <Input
            type="text"
            value={watch('firstName') + ' ' + watch('lastName')}
            name="fullName"
            label={capitalize(t('signUp.placeholders.fullName'))}
            size="medium"
            disabled={isLocked}
          />
          <Input
            type="text"
            value={watch('address')}
            name="viewAddress"
            label={capitalize(t('stakeholder.addressField'))}
            size="medium"
            disabled={isLocked}
          />
        </Stack>
      ) : (
        <>
          <Stack direction="row" spacing={2}>
            <ControlledInput
              watch={watch}
              type="text"
              register={register}
              errors={errors.firstName}
              name="firstName"
              label={capitalize(t('signUp.placeholders.firstName'))}
              size="medium"
              disabled={isLocked}
            />
            <ControlledInput
              watch={watch}
              type="text"
              register={register}
              errors={errors.lastName}
              name="lastName"
              label={capitalize(t('signUp.placeholders.lastName'))}
              size="medium"
              disabled={isLocked}
            />
          </Stack>
          <ControlledInput
            watch={watch}
            type="text"
            register={register}
            errors={errors.address}
            name="address"
            label={capitalize(t('stakeholder.addressField'))}
            size="medium"
            disabled={isLocked}
            otherRules={{
              required: false,
            }}
          />
        </>
      )}

      {!isLocked && (
        <>
          <ControlledInputCountry
            useFormInstance={useFormInstance}
            size="medium"
            name="country"
            disabled={isLocked}
            otherRules={{
              required: false,
            }}
          />
          <ControlledInputPhone
            useFormInstance={useFormInstance}
            size="medium"
            disableInput={isLocked}
            otherRules={{
              required: false,
            }}
          />
        </>
      )}

      {/* Here the chips part begins */}
      <Typography variant="h5">
        {capitalize(t('profile.header.personal'))}
      </Typography>
      <Controller
        name="profile"
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <MultipleChipCheckbox
            selectedOptions={field.value}
            label={capitalize(t('profile.details.question1'))}
            options={profile}
            disabled={isLocked}
            onOptionToggle={option => {
              const updatedOptions = getUpdatedOptions(field, option);
              field.onChange(updatedOptions);
            }}
          />
        )}
      />
      <Controller
        name="interests"
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <MultipleChipCheckbox
            selectedOptions={field.value}
            label={capitalize(t('profile.details.question2'))}
            options={interest}
            disabled={isLocked}
            onOptionToggle={option => {
              const updatedOptions = getUpdatedOptions(field, option);
              field.onChange(updatedOptions);
            }}
          />
        )}
      />
      <Controller
        name="fears"
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <MultipleChipCheckbox
            selectedOptions={field.value}
            label={capitalize(t('profile.details.question3'))}
            options={fear}
            disabled={isLocked}
            onOptionToggle={option => {
              const updatedOptions = getUpdatedOptions(field, option);
              field.onChange(updatedOptions);
            }}
          />
        )}
      />
      {/* Here the chips part ends */}
      <Divider />
      <Typography variant="h6">
        {capitalize(t('profile.header.account'))}
      </Typography>
      <Box sx={style.boxContainerStyles}>
        <Grid item xs={6}>
          <ControlledInput
            watch={watch}
            type="text"
            register={register}
            errors={errors.email}
            name="email"
            disabled={true}
            label={capitalize(t('stakeholder.emailField'))}
            size="medium"
            otherRules={{
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: capitalize(t('signIn.emailAddress.formatError')),
              },
            }}
          />
        </Grid>
        <Box marginTop="15px">
          {isSuccessful ? (
            <Typography sx={style.linkChangePassword}>
              {capitalize(t('profileSettings.successful'))}
            </Typography>
          ) : (
            <Typography
              sx={style.linkChangePassword}
              onClick={() => handleChangePassword()}
            >
              {capitalize(t('profileSettings.link'))}
            </Typography>
          )}
        </Box>
      </Box>
    </Stack>
  );
}
