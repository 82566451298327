import { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { signupSaga } from './saga';
import { InviteTokenData, SignupState, UserFormData } from './types';

const savedTokenData = localStorage.getItem('inviteTokenData');

export const initialState: SignupState = {
  isFetching: false,
  isSuccess: false,
  isRetryingCode: false,
  signupResponse: undefined,
  countries: [],
  verificationCode: null,
  verificationFails: null,
  verificationSuccess: null,
  sendVerificationSuccess: null,
  sendVerificationFails: null,
  inviteTokenData: savedTokenData ? JSON.parse(savedTokenData) : undefined,
  isLoaded: Boolean(savedTokenData),
};

const slice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    doSignup(state, action: PayloadAction<UserFormData>) {
      state.isFetching = true;
      state.formData = action.payload;
      state.signupError = null;
    },
    signupSuccess(
      state,
      action: PayloadAction<{ _id: string; email: string }>,
    ) {
      state.isFetching = false;
      state.isSuccess = true;
      state.signupResponse = action.payload;
      sessionStorage.setItem('demographicInfo', JSON.stringify({}));
      sessionStorage.setItem('onboardingLastStep', '0');
      state.signupError = null;
    },
    signupError(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.signupError = action.payload;
    },
    getCountries() {},
    getCountriesSuccess(state, action: PayloadAction<any>) {
      state.countries = action.payload;
    },
    clearSignupState(state) {
      state.isFetching = false;
      state.isLoaded = false;
      state.isSuccess = false;
      state.signupError = null;
    },
    clearInvitationCode(state) {
      state.isFetching = false;
      state.isSuccess = false;
      state.inviteTokenData = undefined;
      state.signupError = null;
      state.isLoaded = false;
      localStorage.removeItem('inviteTokenData');
    },
    verifyCode(state, action: PayloadAction<any>) {
      state.isFetching = true;
      state.isSuccess = false;
      state.verificationCode = action.payload;
      state.verificationFails = null;
    },
    verifyCodeSuccess(state) {
      state.isFetching = false;
      state.isSuccess = true;
      state.verificationCode = null;
      state.verificationSuccess = true;
      state.verificationFails = false;
    },
    verifyCodeError(state) {
      state.isFetching = false;
      state.isSuccess = false;
      state.verificationFails = true;
      state.verificationSuccess = false;
    },
    verifyInviteTokenData(state, action: PayloadAction<{ token: string }>) {
      state.isFetching = true;
      state.signupError = null;
      state.isLoaded = false;
    },
    verifyInviteTokenSuccess(state, action: PayloadAction<InviteTokenData>) {
      state.isFetching = false;
      state.isSuccess = true;
      state.inviteTokenData = action.payload;
      localStorage.setItem('inviteTokenData', JSON.stringify(action.payload));
      state.signupError = null;
      state.isLoaded = true;
    },
    verifyInviteTokenError(state, action: PayloadAction<{ message }>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isLoaded = false;
      state.inviteTokenData = undefined;
      localStorage.removeItem('inviteTokenData');
      state.signupError = action.payload;
    },
    sendVerificationCode(state, action: PayloadAction<{ retry?: boolean }>) {
      state.isFetching = true;
      state.isSuccess = false;
      state.isRetryingCode = action.payload.retry || false;
      state.sendVerificationFails = null;
      state.sendVerificationSuccess = null;
    },
    sendVerificationCodeSuccess(state) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isRetryingCode = false;
      state.sendVerificationFails = null;
      state.sendVerificationSuccess = true;
    },
    sendVerificationCodeFails(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isRetryingCode = false;
      state.sendVerificationFails = action.payload;
      state.sendVerificationSuccess = null;
    },
    cleanVerificationCode(state) {
      state.isFetching = false;
      state.isSuccess = false;
      state.verificationFails = false;
      state.verificationCode = null;
      state.isRetryingCode = false;
      state.sendVerificationFails = null;
      state.sendVerificationSuccess = false;
    },
    createInviteToken(state, action: PayloadAction<{ email: string }>) {
      state.isFetching = true;
      state.signupError = null;
      state.isLoaded = false;
    },
    createInviteTokenSuccess(state, action: Action) {
      state.isFetching = false;
      state.isSuccess = true;
      state.signupError = null;
      state.isLoaded = true;
    },
    createInviteTokenError(state, action: PayloadAction<{ message }>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isLoaded = false;
      state.signupError = action.payload;
    },
  },
});

export const { actions: signupActions, reducer } = slice;

export const useSignupSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: signupSaga });
  return { actions: slice.actions };
};
