import { muiTheme } from 'styles/muiTheme';

export default {
  buttonsStyles: { display: 'flex', justifyContent: 'flex-end' },
  removeButtonStyles: {
    minWidth: 'unset',
    color: muiTheme.palette.secondary.light,
    ':hover': { background: 'none' },
  },
  cardListStyles: { marginX: 0, cursor: 'pointer' },
  titleStyles: { fontSize: '14px', color: 'text.primary' },
  currentValueStyles: { fontSize: '14px', color: 'primary.main' },
  subtitleStyles: { fontSize: '11px', color: 'text.secondary' },
  menuItemStyles: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  iconStyles: {
    color: 'action.active',
  },
  buttonSx: {
    backgroundColor: 'unset',
    fontSize: 11,
    color: 'action.active',
    cursor: 'pointer',
    minWidth: 'unset',
    padding: '6px 2px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'unset',
    },
    display: 'flex',
    alignItems: 'center',
  },
  tooltipSx: {
    backgroundColor: 'primary.contrast',
    color: 'black',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
  },
  iStyle: {
    display: 'flex',
    alignItems: 'center',
  },
};
