/**
 *
 * Asynchronously loads the component for AddTaskManagerPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AddTaskManagerPage = lazyLoad(
  () => import('./index'),
  module => module.AddTaskManagerPage,
);
