import { Grid, Typography } from '@mui/material';
import { ColumnTextProps } from './props';
import { muiTheme } from 'styles/muiTheme';
import style from './style';

export function ColumnText(props: ColumnTextProps) {
  const { gridSize, cellSx, title, titleSx, subtitle, subtitleSx } = props;

  return (
    <Grid item xs={gridSize} sx={cellSx}>
      {title && (
        <Typography
          variant="body1"
          color={muiTheme.palette.addition.taskManagerDarkGreyColor}
          fontWeight="bold"
          sx={{ ...style.typographySx, ...titleSx }}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          variant="body2"
          color={muiTheme.palette.addition.tableListGreyColor}
          sx={{ ...style.typographySx, ...subtitleSx }}
        >
          {subtitle}
        </Typography>
      )}
    </Grid>
  );
}
