import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { muiTheme } from 'styles/muiTheme';
import { Box, Container, Link, InputLabel, Typography } from '@mui/material';
import { Button, Input } from 'app/components';

import { REGULAR_EXPRESSION_EMAIL } from 'constants/reg-ex';
import { capitalize } from '@mui/material/utils';

interface Props {
  onSubmit: (data: any) => void;
}

export function ResetPasswordForm({ onSubmit }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  });

  const { t } = useTranslation();

  return (
    <Container sx={muiTheme.variants.boxContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={muiTheme.variants.boxStyles}>
          <InputLabel htmlFor="password" sx={muiTheme.variants.primaryLabel}>
            <Typography variant="h5">
              {capitalize(t('resetPasswordForm.header.resetPassword'))}
            </Typography>
          </InputLabel>
          <InputLabel
            sx={{
              ...muiTheme.variants.secondaryLabel,
            }}
          >
            {capitalize(t('resetPasswordForm.helperText.instructions'))}
          </InputLabel>
          <Box sx={muiTheme.variants.boxMarginStyles}>
            <Box sx={muiTheme.variants.pxBox}>
              <Input
                sx={muiTheme.variants.widthField}
                id="email"
                data-test="signup-email-input"
                label={capitalize(t('resetPasswordForm.label.emailAddress'))}
                variant="outlined"
                focused
                isError={Boolean(errors.email)}
                helperText={errors.email && `${errors.email.message}`}
                {...register('email', {
                  required: capitalize(t('thisFieldIsRequired')),
                  pattern: {
                    value: REGULAR_EXPRESSION_EMAIL,
                    message: capitalize(t('signIn.emailAddress.formatError')),
                  },
                })}
                size="medium"
                placeholder={t('signIn.placeholders.email') as string}
              />
            </Box>
          </Box>
          <Box sx={muiTheme.variants.mtmbBox}>
            <Button
              id="submitButton"
              data-test="reset-password-submit-button"
              label={capitalize(t('resetPasswordForm.button.sendInstructions'))}
              fullWidth
              disabled={!isValid}
              variant="contained"
              type="submit"
            />
          </Box>
          <Container sx={muiTheme.variants.displayContainer}>
            <Box sx={muiTheme.variants.mrBox}>
              <InputLabel>
                <Typography variant="h7">
                  {capitalize(
                    t('resetPasswordForm.helperText.rememberedYourPassword'),
                  )}
                </Typography>
              </InputLabel>
            </Box>
            <Box sx={muiTheme.variants.mlBox}>
              <Link
                href="/uss/signin"
                sx={muiTheme.variants.primaryLink}
                data-test="reset-password-log-in-link"
              >
                {capitalize(t('resetPasswordForm.link.log-in'))}
              </Link>
            </Box>
          </Container>
        </Box>
      </form>
    </Container>
  );
}
