export default {
  ribbonBoxStyle: {
    backgroundColor: 'text.primary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    bottom: '0px',
    right: '0px',
    left: '0px',
  },
  ribbonBoxStyleTop: {
    backgroundColor: 'text.primary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 900,
  },
  ribbonTextStyle: {
    fontSize: 16,
    color: 'primary.contrast',
    textAlign: 'center',
    m: '4px',
  },
  childrenStyle: {
    minHeight: 'calc(100vh - 7.8rem)',
    display: 'flex',
    marginTop: '28px',
  },
};
