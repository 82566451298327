import shadows from 'styles/shadows';

export default {
  cardStyles: {
    padding: '16px',
    border: '1px solid',
    borderColor: 'secondary.12p',
    boxShadow: shadows[12],
    maxWidth: '90%',
    height: '280px',
  },
  gridStyles: { alignItems: 'center', margin: '16px 0px' },
  heading: { padding: 1, margin: '0 1' },
  modalstyles: { width: '45%' },
  noClientStyles: {
    display: 'grid',
    gap: 1,
    alignItems: 'center',
    justifyItems: 'center',
    padding: '28px 30px',
    margin: '0 12px',
    backgroundColor: 'secondary.12p',
    border: `1px solid`,
    borderColor: 'secondary.8p',
    borderRadius: '4px',
    width: 'initial',
  },
  containerSx: {
    padding: '1em 0',
    margin: '1em 0',
  },
};
