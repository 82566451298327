import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { OSS_URL } from 'utils/urls';
import { getInstance } from 'utils/http';
import { assetLiabilityActions as actions } from '.';
import {
  AccountingDataReports,
  Asset,
  AssetInput,
  Item,
  Liability,
  LiabilityInput,
  UpdateAsset,
  UpdateItem,
  viewAsset,
} from './types';
import { toastActions } from '../ToastSlice';
import {
  CurrencyStateProps,
  ValidCurrencies,
} from 'providers/CurrencyProvider/types';
import { defaultErrorMessage } from 'constants/error';
import { groupActions } from '../GroupSlice';
import { currencyConversion } from 'app/components/CurrencyValue';
import { capitalize } from '@mui/material/utils';

export function* getAssetsSaga(action: PayloadAction<string>) {
  yield delay(500);
  try {
    const groupId = action.payload;
    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.get,
      `/group/${groupId}/item?itemType=GROUP_ASSET`,
    );
    if (response === void 0) {
      throw new Error('an error ocurred getting assets');
    }
    if (!response.data?.payload) {
      throw new Error(defaultErrorMessage);
    }
    // to be changed when only use context api

    const currencyState: CurrencyStateProps = JSON.parse(
      // @ts-ignore
      localStorage.getItem('currencyState'),
    );
    const assetList = response.data.payload.map(item => {
      return getRenderItemValue(item, currencyState);
    });
    yield put(actions.getAssetsSuccess(assetList));
  } catch (error: any) {
    let errorMessage =
      error.message || error.message.length > 0
        ? error.message
        : defaultErrorMessage;
    if (error?.response?.status === 403) {
      errorMessage = 'Cannot load asset list due to lack of permissions';
    }
    yield put(
      toastActions.showErrorToast({
        show: true,
        message: errorMessage,
      }),
    );
    yield put(
      actions.getAssetsError({
        ...error,
        message: errorMessage,
      }),
    );
  }
}

export function* getLiabilitiesSaga(action: PayloadAction<string>) {
  yield delay(500);
  try {
    const instance = getInstance(OSS_URL);
    const groupId = action.payload;
    const response = yield call(
      instance.get,
      `/group/${groupId}/item?itemType=GROUP_LIABILITY`,
    );
    if (response === void 0) {
      throw new Error('an error ocurred getting liabilities');
    }
    if (!response.data?.payload) {
      throw new Error(defaultErrorMessage);
    }
    const currencyState: CurrencyStateProps = JSON.parse(
      // @ts-ignore
      localStorage.getItem('currencyState'),
    );
    const liabilityList = response.data.payload.map(item => {
      return getRenderItemValue(item, currencyState);
    });
    yield put(actions.getLiabilitiesSuccess(liabilityList));
  } catch (error: any) {
    let errorMessage =
      error.message || error.message.length > 0
        ? error.message
        : defaultErrorMessage;
    if (error?.response?.status === 403) {
      errorMessage = 'Cannot load liability list due to lack of permissions';
    }
    yield put(
      toastActions.showErrorToast({
        show: true,
        message: errorMessage,
      }),
    );
    yield put(
      actions.getLiabilitiesError({
        ...error,
        message: errorMessage,
      }),
    );
  }
}

export function* createAsset(action: PayloadAction<AssetInput>) {
  const instance = getInstance(OSS_URL);
  const payload = action.payload;
  const currentGroup = payload.groupId;
  try {
    const response = yield call(
      instance.post,
      `/group/${currentGroup}/asset`,
      action.payload.baseContext,
    );
    yield put(actions.createAssetSuccess(response.data.payload));
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: 'Asset created successfully',
      }),
    );
  } catch (error: any) {
    yield put(actions.createAssetError(error.message));
    yield put(
      toastActions.showErrorToast({
        show: true,
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* editItem(action: PayloadAction<UpdateItem>) {
  const instance = getInstance(OSS_URL);
  try {
    const { _id, baseContext, groupId } = action.payload;
    if (!_id) {
      throw new Error('_id is required');
    }
    if (!groupId) {
      throw new Error('groupId is required');
    }
    if (!baseContext) {
      throw new Error('baseContext is required');
    }
    const data = {
      baseContext,
    } as UpdateItem;
    yield call(instance.put, `/group/${groupId}/item/${_id}`, data);
    yield put(actions.editItemSuccess());
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: 'Item updated successfully',
      }),
    );
  } catch (error: any) {
    yield put(actions.editItemError(error as Error));
    yield put(
      toastActions.showErrorToast({
        show: true,
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* editAsset(action: PayloadAction<UpdateAsset>) {
  const instance = getInstance(OSS_URL);
  try {
    const { _id, groupId } = action.payload;
    if (!groupId || !_id) {
      throw new Error('groupId is required');
    }
    yield call(
      instance.patch,
      `/group/${groupId}/asset/${_id}`,
      action.payload,
    );
    yield put(actions.editAssetSuccess());
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: 'Asset updated successfully',
      }),
    );
  } catch (error: any) {
    yield put(actions.editAssetError(error as Error));
    yield put(
      toastActions.showErrorToast({
        show: true,
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* editLiability(action: PayloadAction<Liability>) {
  const instance = getInstance(OSS_URL);
  try {
    const { _id, groupId } = action.payload;
    if (!groupId || !_id) {
      throw new Error('groupId is required');
    }
    const data = {
      ...action.payload,
    } as Partial<Liability>;
    yield call(instance.patch, `/group/${groupId}/liability/${_id}`, data);
    yield put(actions.editLiabilitySuccess());
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: 'Liability updated successfully',
      }),
    );
  } catch (error: any) {
    yield put(actions.editLiabilityError(error as Error));
    yield put(
      toastActions.showErrorToast({
        show: true,
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* createLiability(action: PayloadAction<LiabilityInput>) {
  const instance = getInstance(OSS_URL);
  const currentGroup = action.payload.groupId;
  try {
    const response = yield call(
      instance.post,
      `/group/${currentGroup}/liability`,
      action.payload.baseContext,
    );
    yield put(actions.createLiabilitySuccess(response.data.payload));
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: 'Liability created successfully',
      }),
    );
  } catch (error: any) {
    yield put(actions.createLiabilityError(error.message));
    yield put(
      toastActions.showErrorToast({
        show: true,
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* getAssestLiabilityCalculation(action: PayloadAction<string>) {
  yield delay(500);
  try {
    const instance = getInstance(OSS_URL);
    const groupId = action.payload;
    let response;
    if (groupId) {
      response = yield call(instance.get, `/calculation/${groupId}`);
    }
    if (response === void 0) {
      throw new Error('an error ocurred getting assets');
    }
    if (!response?.data) {
      throw new Error(defaultErrorMessage);
    }
    yield put(actions.getAssetsLiabilityCalculationSuccess(response.data));
  } catch (error: any) {
    yield put(
      actions.getAssetsError({
        ...error,
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* getAssetView(action: PayloadAction<viewAsset>) {
  const instance = getInstance(OSS_URL);
  try {
    const { itemId, groupId } = action.payload;
    const response = yield call(
      instance.get,
      `/group/${groupId}/asset/${itemId}`,
    );
    if (response === void 0) {
      throw new Error('an error ocurred getting asset value');
    }
    if (!response.data?.payload) {
      throw new Error(defaultErrorMessage);
    }
    const currencyState = JSON.parse(
      // @ts-ignore
      localStorage.getItem('currencyState'),
    );
    const result = getRenderItemValue(response.data.payload, currencyState);

    yield put(actions.getAssetViewSuccess(result as Asset));
  } catch (error: any) {
    let errorMessage =
      error.message || error.message.length > 0
        ? error.message
        : defaultErrorMessage;
    if (error?.response?.status === 403) {
      errorMessage = `You don't have permission to view this asset`;
    }
    yield put(
      toastActions.showErrorToast({
        show: true,
        message: errorMessage,
      }),
    );
    yield put(
      actions.getAssetViewError({
        ...error,
        message: errorMessage,
      }),
    );
  }
}

export function* getAccountingData(action: PayloadAction<viewAsset>) {
  const instance = getInstance(OSS_URL);
  try {
    const { itemId, groupId } = action.payload;
    const response = yield call(
      instance.get<AccountingDataReports>,
      `/group/${groupId}/item/${itemId}/accounting`,
    );
    if (response === void 0) {
      throw new Error('an error ocurred getting accounting data');
    }
    const { data } = response;
    if (!data) {
      throw new Error(defaultErrorMessage);
    }
    yield put(actions.getAccountingDataSuccess(data));
  } catch (error: any) {
    let errorMessage =
      error.message || error.message.length > 0
        ? error.message
        : defaultErrorMessage;
    if (error?.response?.status === 403) {
      errorMessage = `You don't have permission to view this asset`;
    }

    yield put(actions.getAccountDataError(error as Error));

    yield put(
      toastActions.showErrorToast({
        show: true,
        message: errorMessage,
      }),
    );
    yield put(
      actions.getAssetViewError({
        ...error,
        message: errorMessage,
      }),
    );
  }
}

export function* deleteAssetSaga(action: PayloadAction<viewAsset>) {
  const instance = getInstance(OSS_URL);
  try {
    const { itemId, groupId } = action.payload;
    yield call(instance.delete, `/group/${groupId}/asset/${itemId}`);
    yield put(actions.deleteAssetSuccess());
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: 'Asset deleted successfully',
      }),
    );
  } catch (error: any) {
    yield put(actions.deleteAssetError(error as Error));
    yield put(
      toastActions.showErrorToast({
        show: true,
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* getLiabilityView(action: PayloadAction<viewAsset>) {
  const instance = getInstance(OSS_URL);
  try {
    const { itemId, groupId } = action.payload;

    const response = yield call(
      instance.get,
      `/group/${groupId}/liability/${itemId}`,
    );
    if (response === void 0) {
      throw new Error('an error ocurred getting liability value');
    }
    if (!response.data?.payload) {
      throw new Error(defaultErrorMessage);
    }
    const currencyState = JSON.parse(
      // @ts-ignore
      localStorage.getItem('currencyState'),
    );
    const result = getRenderItemValue(response.data.payload, currencyState);
    yield put(actions.getLiabilityViewSuccess(result as Liability));
  } catch (error: any) {
    let errorMessage =
      error.message || error.message.length > 0
        ? error.message
        : defaultErrorMessage;
    if (error?.response?.status === 403) {
      errorMessage = `You don't have permission to view this liability`;
    }
    yield put(
      toastActions.showErrorToast({
        show: true,
        message: errorMessage,
      }),
    );
    yield put(
      actions.getLiabilityViewError({
        ...error,
        message: errorMessage,
      }),
    );
  }
}

export function* deleteLiabilitySaga(action: PayloadAction<viewAsset>) {
  const instance = getInstance(OSS_URL);
  try {
    const { itemId, groupId } = action.payload;

    yield call(instance.delete, `/group/${groupId}/liability/${itemId}`);
    yield put(actions.deleteLiabilitySuccess());
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: 'Liability deleted successfully',
      }),
    );
  } catch (error: any) {
    yield put(actions.deleteLiabilityError(error as Error));
    yield put(
      toastActions.showErrorToast({
        show: true,
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* handleItemChanges() {
  yield put(groupActions.cleanupGroupOperation());
  yield put(actions.cleanUpItemOperation());
}

export const getRenderItemValue = (
  item: Item,
  currency: CurrencyStateProps,
): Item => {
  const convertedValuesList = [
    'purchasePrice',
    'recentValuation',
    'cost',
    'sharePrice',
    'recentSharePrice',
    'totalCommitment',
    'capitalCommitment',
    'remainingCommitment',
    'ebitda',
    'estimatedValue',
    'value',
    'totalValue',
  ];
  const renderValues = convertedValuesList.reduce(
    (values = {}, pName) => {
      values[`render${capitalize(pName)}`] = currencyConversion(
        currency,
        item.baseContext.currency,
        item.baseContext[pName],
      );
      return values;
    },
    {} as Record<string, number>,
  );

  let positionList = item.baseContext.positions;

  if (positionList && positionList.length) {
    positionList = positionList.map(position => ({
      ...position,
      renderMarketValue: currencyConversion(
        currency,
        position.currency as ValidCurrencies,
        position.marketValue,
      ),
      renderBookValue: currencyConversion(
        currency,
        position.currency as ValidCurrencies,
        position.bookValue,
      ),
      renderGainAmount: currencyConversion(
        currency,
        position.currency as ValidCurrencies,
        position.gainAmount,
      ),
    }));
  }

  return {
    ...item,
    baseContext: {
      ...item.baseContext,
      renderCurrency: currency.base,
      positions: positionList,
      ...renderValues,
    },
  };
};

export function* assetLiabilitySaga() {
  yield takeLatest(actions.getAssets.type, getAssetsSaga);
  yield takeLatest(actions.getLiabilities.type, getLiabilitiesSaga);
  yield takeLatest(actions.createAsset.type, createAsset);
  yield takeLatest(actions.createLiability.type, createLiability);
  yield takeLatest(actions.editItem.type, editItem);
  yield takeLatest(actions.editAsset.type, editAsset);
  yield takeLatest(actions.editLiability.type, editLiability);
  // yield takeLatest(actions.getAssets.type, getAssestLiabilityCalculation);
  yield takeLatest(actions.getAssetView.type, getAssetView);
  yield takeLatest(actions.deleteAsset.type, deleteAssetSaga);
  yield takeLatest(actions.getLiabilityView.type, getLiabilityView);
  yield takeLatest(actions.deleteLiability.type, deleteLiabilitySaga);

  yield takeLatest(actions.createAssetSuccess.type, handleItemChanges);
  yield takeLatest(actions.editItem.type, handleItemChanges);
  yield takeLatest(actions.editAssetSuccess.type, handleItemChanges);
  yield takeLatest(actions.deleteAssetSuccess.type, handleItemChanges);
  yield takeLatest(actions.createLiabilitySuccess.type, handleItemChanges);
  yield takeLatest(actions.deleteLiabilitySuccess.type, handleItemChanges);
  yield takeLatest(actions.editLiabilitySuccess.type, handleItemChanges);
  yield takeLatest(actions.getAccountingData.type, getAccountingData);
}
