export const utilities = {
  flexRowCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexColumCentered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
