import { defaultErrorMessage } from 'constants/error';
import { call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { getInstance } from 'utils/http';
import { request } from 'utils/request';
import { UBE_URL } from 'utils/urls';
import { signupActions as actions } from '.';
import { toastActions } from '../toast-slice';
import { selectFormData, selectCode, selectIsRetryingCode } from './selectors';
import { UserFormData } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

const uaaInstance = getInstance(UBE_URL);

export function* signupUser() {
  yield delay(500);
  const formData: UserFormData = yield select(selectFormData);
  const demographicData = JSON.parse(
    sessionStorage.getItem('demographicInfo') ?? '{}',
  );
  if (!formData) {
    yield put(actions.signupError({ message: 'Invalid data' }));
    return;
  }
  const signUpData = {
    ...formData,
    demographics: demographicData || {},
    phoneNumber: `${formData?.phoneNumber?.replace(/\s/g, '')}`,
  };
  try {
    const response = yield call(request, `${UBE_URL}/user`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(signUpData),
    });
    if (!response) {
      throw new Error(defaultErrorMessage);
    }
    if (response.error) {
      throw new Error(defaultErrorMessage);
    }
    yield put(
      actions.signupSuccess({
        ...response.payload,
        email: formData.email,
      }),
    );
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: 'Signup success',
      }),
    );
  } catch (err) {
    const error = (err as Error) || new Error(defaultErrorMessage);
    yield put(
      toastActions.showErrorToast({
        show: true,
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
    yield put(
      actions.signupError({
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* getCountries() {
  yield delay(500);
  try {
    const response = yield call(
      request,
      `${UBE_URL}/signup/static/country-list`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
    if (response === void 0) {
      throw new Error('not found countries');
    }
    if (!response.payload) {
      throw new Error(defaultErrorMessage);
    }
    yield put(
      actions.getCountriesSuccess(
        response.payload
          .filter(({ priority }: { priority: number }) => priority < 2)
          .sort((a, b) => a.dialCode - b.dialCode),
      ),
    );
  } catch (error: any) {
    yield put(
      actions.signupError({
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* sendVerificationCodeSaga() {
  yield delay(500);
  const isRetryingCode = yield select(selectIsRetryingCode);
  try {
    const response = yield call(
      uaaInstance.post,
      `/task/verify-user${isRetryingCode ? '?retry=true' : ''}`,
    );

    if (response === void 0) {
      throw new Error('an error ocurred sending code');
    }
    if (!response.data?.payload?.success) {
      throw new Error(defaultErrorMessage);
    }
    yield put(actions.sendVerificationCodeSuccess());
  } catch (error: any) {
    yield put(
      actions.sendVerificationCodeFails({
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* verifyCodeSaga() {
  yield delay(500);
  try {
    const code = yield select(selectCode);
    // @ts-ignore

    if (!code) throw new Error(defaultErrorMessage);

    const response = yield call(uaaInstance.patch, `/task/verify-user`, {
      code,
    });

    if (response === void 0) {
      throw new Error('an error ocurred verifying code');
    }
    if (!response.data) {
      throw new Error(defaultErrorMessage);
    }
    if (response.data?.type === 'USER_VERIFICATION_SUCCESS') {
      yield put(actions.verifyCodeSuccess());
      yield put(
        toastActions.showSuccessToast({
          show: true,
          message: 'Code verified successfully',
        }),
      );
    } else {
      throw new Error('An invalid code was provided');
    }
  } catch (error: any) {
    yield put(actions.verifyCodeError());
    yield put(
      toastActions.showErrorToast({
        show: true,
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* verifyInviteTokenSaga(
  action: PayloadAction<{ token: string }>,
) {
  try {
    const { token } = action.payload;
    const searchParams = new URLSearchParams();
    searchParams.append('token', token);
    const response = yield call(
      uaaInstance.get,
      `/signup/invitetoken?${searchParams.toString()}`,
    );

    if (response === void 0) {
      throw new Error('an error ocurred verifying code');
    }
    if (!response.data) {
      throw new Error(defaultErrorMessage);
    }
    if (response.data?.type !== 'TOKEN_DATA_RETRIEVED') {
      throw new Error('An invalid code was provided');
    }
    if (!response.data.payload.isValid) {
      throw new Error('An invalid code was provided');
    }
    if (!response.data.payload.isValid && response.data.payload.email) {
      throw new Error('The code has expired');
    }
    yield put(actions.verifyInviteTokenSuccess(response.data.payload));
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: 'Code verified successfully',
      }),
    );
  } catch (error: any) {
    yield put(
      actions.verifyInviteTokenError({
        message: error.message || defaultErrorMessage,
      }),
    );
    yield put(
      toastActions.showErrorToast({
        show: true,
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* createInviteTokenSaga(
  action: PayloadAction<{ email: string }>,
) {
  try {
    const response = yield call(
      uaaInstance.post,
      `/signup/invitetoken`,
      action.payload,
    );

    if (response === void 0) {
      throw new Error('an error ocurred');
    }
    if (!response.data) {
      throw new Error(defaultErrorMessage);
    }

    yield put(actions.createInviteTokenSuccess(response.data.payload));
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: 'You had been added to the waiting list',
      }),
    );
  } catch (error: any) {
    yield put(
      actions.createInviteTokenError({
        message: error.message || defaultErrorMessage,
      }),
    );
    yield put(
      toastActions.showErrorToast({
        show: true,
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* clearSignUpState() {
  yield put(actions.clearInvitationCode());
  yield put(actions.clearSignupState());
}

export function* signupSaga() {
  yield takeLatest(actions.verifyInviteTokenData.type, verifyInviteTokenSaga);
  yield takeLatest(actions.createInviteToken.type, createInviteTokenSaga);
  yield takeLatest(actions.createInviteTokenSuccess.type, clearSignUpState);
  yield takeLatest(actions.doSignup.type, signupUser);
  yield takeLatest(actions.getCountries.type, getCountries);
  yield takeLatest(actions.sendVerificationCode.type, sendVerificationCodeSaga);
  yield takeLatest(actions.verifyCode.type, verifyCodeSaga);
}
