import { Typography, Card } from '@mui/material';
import { capitalize } from '@mui/material/utils';
import { useTranslation } from 'react-i18next';
import { GroupData } from 'app/features/GroupSlice/types';
import * as styles from './styles';
interface Props {
  selectedGroup?: GroupData;
}

export const Header = ({ selectedGroup }: Props) => {
  const { t } = useTranslation();
  return (
    <Card elevation={0} sx={styles.Container}>
      {selectedGroup && (
        <Typography variant="h5" fontWeight="600">
          {t('scenarioModeling.home.headers.holdings', {
            groupName: selectedGroup.groupNameDisplay,
          })}
        </Typography>
      )}
    </Card>
  );
};
