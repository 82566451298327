import { Grid, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './style';
import { StripePaymentHistory } from 'app/features/StripeSlice/types';
import moment from 'moment';

interface Props {
  list: StripePaymentHistory[] | undefined;
}

const renderEqGridItems = (titleList: string[]) => {
  return titleList.map((title: string, index: number) => (
    <Grid item key={index} xs={3}>
      <Typography variant="caption" textTransform="capitalize">
        {title}
      </Typography>
    </Grid>
  ));
};

export function StripePaymentHistoryTable({ list }: Props) {
  const { t } = useTranslation();
  const tableHeadersList = [
    t('paymentHistory.table.issueDate'),
    t('paymentHistory.table.description'),
    t('paymentHistory.table.amount'),
    t('paymentHistory.table.status'),
  ];

  return (
    <Box sx={style.boxStyles}>
      <Box sx={style.containerSx}>
        <Grid container sx={style.headerStyles}>
          {renderEqGridItems(tableHeadersList)}
        </Grid>
        {list?.map((item, index) => (
          <Box>
            <Grid container>
              <Grid item xs={3}>
                <Typography variant="caption">
                  {moment.unix(item.created).format('MM/DD/YYYY')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="caption">{item.description}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="caption">{item.amount}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="caption">{item.status}</Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
