import { muiTheme } from 'styles/muiTheme';

export const AddAssetStyle = {
  buttonFlex: {
    width: '100%',
    padding: '20px 0',
    paddingRight: '60px',
    background: 'transparent',
  },
  multiDropDown: {
    marginTop: '-20px',
    marginBottom: '20px',
    width: '40%',
    background: 'transparent',
    maxWidth: '40%',
  },
};

export const EditLiabilityStyle = {
  associateBox: {
    borderTop: 'unset',
    fontFamily: 'RobotoRegular',
    fontSize: 14,
    color: 'black',
    borderBottom: '0px',
    background: '#F8F8F9',
    padding: '0 13px',
  },
  associateText: {
    textTransform: 'capitalize',
    color: '#40464D',
    paddingBottom: '6px',
    fontSize: '14px',
    fontFamily: 'RobotoMedium',
  },
  loaderBox: {
    position: 'absolute',
    top: '0',
    left: '0px',
    right: '0',
    width: '100%',
    height: '100%',
  },
  loaderText: {
    color: '#40464D',
    paddingBottom: '6px',
    fontSize: '18px',
    fontFamily: 'RobotoMedium',
    marginLeft: '350px',
    marginTop: '40px',
  },
  modalTitle: {
    marginTop: '20px',
    minHeight: '50px',
    fontWeight: '400',
    fontSize: '14px',
  },

  modalRemoveTitle: {
    marginTop: '20px',
    minHeight: '50px',
    fontWeight: '400',
    fontSize: '14px',
  },
};

export const StyledDashboard = {
  flexStyle: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
  },
  flexSpaceBetween: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  toggleBox: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
  },
  addNew: {
    fontSize: '14px',
    color: '#000',
    padding: '12px 12px',
    cursor: 'pointer',
    borderLeft: '3px solid #fff',
    fontWeight: 'bold',
    borderTop: '1px solid #ccc',
    ':hover': {
      background: '#f6f6f6',
      borderLeft: '3px solid #daa634',
    },
  },
  groupName: {
    fontSize: '14px',
    color: '#000',
    padding: '7px 12px',
    cursor: 'pointer',
    borderLeft: '3px solid #fff',
    ':hover': {
      background: '#f6f6f6',
      borderLeft: '3px solid #daa634',
    },
  },
  groupBox: { maxHeight: '150px', overflow: 'auto' },
  groupBoxParent: {
    border: '1px solid #ddd',
    width: '200px',
    position: 'absolute',
    zIndex: '999',
    background: '#fff',
    borderRadius: '4px',
    top: '46px',
  },
  boxTitle: { color: 'text.secondary' },
  itemTitle: {
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
  },
  boxValue: {
    color: 'text.primary',
  },
  boxPrimaryValue: {
    color: 'primary.main',
  },
  boxBorder: {
    width: '100%',
    padding: '12px',
    border: '1px solid',
    borderColor: 'other.outlineBorder',
    borderRadius: '4px',
    display: 'inline-block',
    backgroundColor: 'white',
  },
  boxBorderBottom: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    marginBottom: 2,
    borderRadius: 0,
    paddingBottom: 2,
    borderBottom: '1px solid rgba(156, 150, 142, 0.3);',
  },
};

export const connectedAccountsStyle = {
  accountType: {
    width: '300px',
    color: 'black',
    variant: 'subtitle2',
    marginRight: '100px',
  },
  accountNumber: {
    marginRight: '25px',
    variant: 'subtitle2',
  },
  connectedInstitutionText: {
    marginTop: '4px',
    marginBottom: '4px',
    fontSize: '28px',
    fontWeight: '600',
  },
  accountTitleBox: {
    display: 'flex',
    padding: '15px 32px',
    borderBottom: '1px solid #E5E8E7',
  },
  accountID: {
    variant: 'subtitle2',
    fontWeight: '600',
    flexGrow: 1,
  },
  tagButton: {
    color: 'black',
  },
  pendingIcon: {
    filter:
      'invert(45%) sepia(55%) saturate(5515%) hue-rotate(350deg) brightness(97%) contrast(95%)',
    height: '14px',
    paddingRight: '6px',
  },
  errorIcon: {
    filter:
      'invert(76%) sepia(35%) saturate(6921%) hue-rotate(346deg) brightness(90%) contrast(81%)',
    height: '14px',
    paddingRight: '6px',
  },
  errorTooltipSx: {
    backgroundColor: 'white',
    color: '#727475',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    fontSize: '12px',
    width: '280px',
    height: '146px',
    textAlign: 'center',
    padding: '20px',
  },
  pendingTooltipSx: {
    backgroundColor: 'white',
    color: '#727475',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    fontSize: '10px',
    width: '280px',
    height: '90px',
    textAlign: 'center',
    padding: '20px',
  },
  tryAgainButton: {
    float: 'center',
    mt: '20px',
    // maxWidth: '70px',
    // maxHeight: '20px',
    // minWidth: '70px',
    // minHeight: '20px',
    // fontSize: '10px',
  },
  deleteButton: {
    color: '#C8291D',
    boxShadow: 'none',
    backgroundColor: '#fff',
    border: '0',
    padding: '0',
    cursor: 'pointer',
    ':hover': {
      background: 'none',
    },
  },
  deleteModal: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0',
      width: '580px',
      textAlign: 'center',
    },
  },
  deleteAccount: {
    color: '#C8291D',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '5px',
  },
  deleteAccountBox: {
    background: '#F3F3F3',
    borderTop: '2px solid #E5E8E7',
    padding: '30px',
  },
  noKeepButton: {
    width: '104px',
    justifyContent: 'center',
    marginRight: 4,
    backgroundColor: 'white',
    border: '1px solid #758580',
  },
  noKeepText: {
    color: '#40464D',
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '4px',
  },
  yesDeleteButton: {
    width: '182px',
    justifyContent: 'center',
    color: 'white',
    backgroundColor: '#C8291D',
  },
  yesDeleteText: {
    color: 'white',
    fontSize: '14px',
    margin: '4px',
  },
};

export const ussLandingStyle = {
  outerContainer: {
    maxWidth: '1394px',
  },
  rightContainer: {
    maxWidth: '620px',
  },
  disclaimer: {
    color: muiTheme.palette.secondary.light,
    marginBottom: '16px',
  },
  inputContainer: {
    maxHeight: '56px',
  },
};
