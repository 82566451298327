import { muiTheme } from 'styles/muiTheme';

export default {
  inputLabelStyle: {
    mb: 1,
    fontFamily: 'RobotoRegular',
    fontSize: 14,
    color: muiTheme.palette.addition.textDarkGrey,
  },
};
