import { HoldingReadingInterface } from 'app/features/AssetLiabilitySlice/types';
import ConsolidationCalcuationBar from 'app/components/ConsolidationCalcuationBar';

type Props = {
  heading?: any;
  holdings: HoldingReadingInterface[] | null;
  benchmarkData?: Array<{ className: string; value: number }> | null;
};

export const Benchmarks = ({
  heading = 'Current holdings',
  holdings,
  benchmarkData,
}: Props) => {
  return (
    <>
      <ConsolidationCalcuationBar
        heading={heading}
        nodata={Boolean(holdings && holdings.length > 0)}
        readings={holdings}
        //TODO review if we need to show all tags or only the ones that are showing/plan to show
        // legendClasses={benchmarkData?.map(({ className }) => className)}
      />
      {benchmarkData ? (
        <ConsolidationCalcuationBar
          heading="Benchmarking: Tiger 21"
          nodata={true}
          readings={benchmarkData}
          // legendClasses={benchmarkData?.map(({ className }) => className)}
        />
      ) : null}
    </>
  );
};
