export default {
  boxStyles: {
    minHeight: '500px',
    width: '100%',
    marginTop: '16px',
    marginBottom: '24px',
  },
  headerStyles: {
    borderBottom: `1px solid`,
    borderColor: 'secondary.main',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 33px 7px 33px',
    width: 'initial',
  },
  defaultButtonStyles: {
    textTransform: 'uppercase',
    marginLeft: '12px',
    marginBottom: '12px',
    color: 'primary.contrast',
    padding: '8px 22px',
  },
  containerSx: {
    padding: '0 2em',
  },
};
