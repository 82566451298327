import { Grid, Typography, Box } from '@mui/material';
import { Button } from 'app/components';
import { ReactComponent as AlertCircleIcon } from 'app/assets/icons/alert-circle.svg';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import typographys from 'styles/typographys';
import style from './style';
import { StakeholderCard } from '../StakeholderCard';
import { ContactSummary } from 'app/features/AddressBookSlice/types';

interface Props {
  stakeholderList: ContactSummary[] | undefined;
  editOnClick?: (stakeholder: ContactSummary) => () => void;
  accessOnClick?: (stakeholder: ContactSummary) => () => void;
  deleteOnClick?: (stakeholder: ContactSummary) => () => void;
  addOnClick?: () => void;
}

const renderEqGridItems = (titleList: string[]) => {
  return titleList.map((title: string, index: number) => (
    <Grid item key={index} xs={4}>
      <Typography
        variant="caption"
        color="text.secondary"
        textTransform="capitalize"
      >
        {title}
      </Typography>
    </Grid>
  ));
};

export function StakeHolderTable({
  stakeholderList,
  editOnClick,
  accessOnClick,
  deleteOnClick,
  addOnClick,
}: Props) {
  const { t } = useTranslation();
  const tableHeadersList = [t('name'), t('contactDetails'), '', ''];

  return (
    <Box sx={style.boxStyles}>
      <Box sx={style.topBorderStyles}></Box>
      <Grid container sx={style.headerStakeholderStyles}>
        {renderEqGridItems(tableHeadersList)}
      </Grid>
      <Box sx={style.containerSx}>
        {stakeholderList?.length !== 0 ? (
          stakeholderList?.map((stakeholder, index) => (
            <StakeholderCard
              {...stakeholder}
              key={index}
              editOnClick={
                editOnClick && !stakeholder.canImpersonate
                  ? editOnClick(stakeholder)
                  : undefined
              }
              accessOnClick={
                accessOnClick && !stakeholder.canImpersonate
                  ? accessOnClick(stakeholder)
                  : undefined
              }
              deleteOnClick={deleteOnClick && deleteOnClick(stakeholder)}
            />
          ))
        ) : (
          <Box sx={style.noStakeholderStyles}>
            <AlertCircleIcon width={15} height={15} stroke="#9C968E" />
            <Typography variant="caption" color="secondary.main">
              {t('stakeholder.noStakeholder')}
            </Typography>
          </Box>
        )}
      </Box>
      <Button
        label={capitalize(t('addNew'))}
        variant="contained"
        onClick={addOnClick}
        sx={{ ...style.defaultButtonStyles, ...typographys.buttonLarge }}
      ></Button>
    </Box>
  );
}
