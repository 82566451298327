import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import style from './style';

const MultipleChipCheckbox = ({
  options,
  selectedOptions,
  onOptionToggle,
  label,
  disabled,
}) => {
  const handleOptionToggle = option => {
    onOptionToggle(option);
  };

  const getSelectedOptions = option =>
    (selectedOptions || []).some(selected => selected.value === option.value);

  return (
    <>
      <Typography variant="h7" mb={3} color="text.primary">
        {label}
      </Typography>
      <Stack direction="row" spacing={1}>
        {options.map(option => (
          <Chip
            key={option.value}
            label={option.label}
            disabled={disabled}
            sx={getSelectedOptions(option) ? style.chipSelected : style.chip}
            clickable
            color={getSelectedOptions(option) ? 'primary' : 'default'}
            onClick={() => handleOptionToggle(option)}
            onDelete={
              getSelectedOptions(option)
                ? () => handleOptionToggle(option)
                : undefined
            }
          />
        ))}
      </Stack>
    </>
  );
};

export default MultipleChipCheckbox;
