import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { ConsolidatedGroupData } from 'constants/keyValue';
import { initialState } from '.';
import { GroupData, GroupTypes } from './types';
import { Item } from '../AssetLiabilitySlice/types';
// import { Shares } from 'constants/stakeholder';

const selectSlice = (state: RootState) =>
  (state && state.group) || initialState;

export const selectGroup = createSelector([selectSlice], state => state);

export const selectGroups = createSelector(
  [selectSlice],
  ({ groups }) => groups,
);

export const selectIsValidGroupId = createSelector(
  [selectSlice, (_, groupId?: string) => groupId],
  (state, groupId) => {
    const foundGroup = state.groups.find(({ _id }) => groupId === _id);
    const isValidGroupId =
      !state.groups.length ||
      !groupId ||
      groupId === ConsolidatedGroupData._id ||
      Boolean(foundGroup);
    return isValidGroupId;
  },
);

export const selectGroupsCount = createSelector(
  [selectSlice],
  ({ groups }) => groups.length,
);

export const selectGroupsIsFetching = createSelector(
  [selectSlice],
  groupState => groupState.isFetching,
);
export const createGroupSuccess = createSelector(
  [selectSlice],
  groupState => groupState.isCreateSuccess,
);
export const selectGroupsLoaded = createSelector(
  [selectSlice],
  ({ loaded }) => loaded,
);
export const selectGroupsError = createSelector(
  [selectSlice],
  ({ serviceError }) => serviceError,
);
export const selectAssetsAverages = createSelector(
  [selectSlice],
  groupState => groupState.assetsAverages,
);

export const selectConsolidationContext = createSelector(
  [selectSlice],
  ({ groups, currentGroupId }) => {
    const currentGroup: GroupData | undefined = groups.find(
      ({ _id }) => _id === currentGroupId,
    );
    // groups that contains only owned shared items
    const acceptedGroups = groups.filter(
      group =>
        group.groupStakePercentage && Number(group.groupStakePercentage) > 0,
    );
    return {
      all: acceptedGroups,
      current: currentGroup,
    };
  },
);
export const selectConsolidationCalculations = createSelector(
  [selectSlice],
  groupState => groupState.groupCalculations,
);

export const selectGroupIntegrations = createSelector([selectSlice], state =>
  state.groups.flatMap(g =>
    g.integrations.map(d => ({ ...d, groupId: g._id })),
  ),
);

export const selectFormData = createSelector([selectSlice], ({ formData }) => {
  if (formData && formData.groupStakePercentage) {
    return {
      ...formData,
      groupStakePercentage: Number(formData.groupStakePercentage),
    };
  }
  return formData;
});

export const selectStakeholderData = createSelector(
  [selectSlice],
  groupState => groupState.stakeholderData,
);
export const selectStakeholderAssets = createSelector(
  [selectSlice],
  groupState => groupState.stakeholderAssets,
);
export const selectStakeholderLiabilities = createSelector(
  [selectSlice],
  groupState => groupState.stakeholderLiabilities,
);
export const selectCurrentStakeholder = createSelector(
  [selectSlice],
  groupState => groupState.stakeholderData || null,
);

export const selectCurrentStakeholderId = createSelector(
  [selectSlice],
  groupState => groupState.stakeholderData?.actorId || null,
);

export const selectAddStakeholderIsSuccess = createSelector(
  [selectSlice],
  groupState => groupState.addStakeholderIsSuccess,
);

export const selectAddStakeholderIsFetching = createSelector(
  [selectSlice],
  groupState => groupState.addStakeholderIsFetching,
);

export const selectAddStakeholderError = createSelector(
  [selectSlice],
  groupState => groupState.addStakeholderError,
);

export const selectEditStakeholderIsSuccess = createSelector(
  [selectSlice],
  groupState => groupState.editStakeholderIsSuccess,
);

export const selectEditStakeholderIsFetching = createSelector(
  [selectSlice],
  groupState => groupState.editStakeholderIsFetching,
);

export const selectEditStakeholderError = createSelector(
  [selectSlice],
  groupState => groupState.editStakeholderError,
);

export const selectUpdateStakeholderIsSuccess = createSelector(
  [selectSlice],
  groupState => groupState.updateStakeholderIsSuccess,
);

export const selectUpdateStakeholderIsFetching = createSelector(
  [selectSlice],
  groupState => groupState.updateStakeholderIsFetching,
);

export const selectUpdateStakeholderError = createSelector(
  [selectSlice],
  groupState => groupState.updateStakeholderError,
);

export const selectDeleteStakeholderIsSuccess = createSelector(
  [selectSlice],
  groupState => groupState.deleteStakeholderIsSuccess,
);

export const selectDeleteStakeholderIsFetching = createSelector(
  [selectSlice],
  groupState => groupState.deleteStakeholderIsFetching,
);

export const selectDeleteStakeholderError = createSelector(
  [selectSlice],
  groupState => groupState.deleteStakeholderError,
);

export const selectIsFetching = createSelector(
  [selectSlice],
  groupState => groupState.isFetching,
);

export const selectIsCreatingGroup = createSelector(
  [selectSlice],
  groupState => groupState.isCreatingGroup,
);

export const selectIsSuccess = createSelector(
  [selectSlice],
  groupState => groupState.isSuccess,
);

export const selectGroupTypes = createSelector(
  [selectSlice],
  groupState => groupState.groupTypes,
);

export const selectGroupTypesIsFetching = createSelector(
  [selectSlice],
  groupState => groupState.groupTypesIsFetching,
);

export const selectGroupTypesIsSuccess = createSelector(
  [selectSlice],
  groupState => groupState.groupTypesIsSuccess,
);

export const selectGroupTypesError = createSelector(
  [selectSlice],
  groupState => groupState.groupTypesError,
);

export const selectGroupsWithConsolidated = createSelector(
  [selectSlice],
  groupState => {
    let mappedGroups: GroupData[] = [];
    if (groupState.groups) {
      mappedGroups = [ConsolidatedGroupData, ...groupState.groups];
    }
    return mappedGroups;
  },
);

export const selectCurrentGroupId = createSelector(
  [selectSlice],
  groupState => groupState.currentGroupId,
);

export const selectCurrentGroup = createSelector(
  [selectSlice, (_, groupId?: string) => groupId],
  (groupState, groupId) => groupState.groups.find(g => g._id === groupId),
);

export const selectPersonalGroup = createSelector([selectSlice], groupState =>
  groupState.groups.find(g => g.groupType === GroupTypes.PERSONAL),
);

export const selectStakeholders = createSelector(
  [selectSlice],
  groupState => groupState.stakeholders,
);

export const selectUniqueUsersFromAvailableStakeholders = createSelector(
  [selectSlice],
  groupState =>
    groupState.groups
      .flatMap(g => g.stakeholders)
      .filter(
        (value, index, self) =>
          index === self.findIndex(t => t.userId === value.userId),
      ),
);

export const selectItemByGroup = groupId =>
  createSelector([selectSlice], groupState => {
    return groupState.groups
      .filter(({ _id }) => _id === groupId)
      .reduce((itemList, { items, _id }) => {
        if (groupId !== _id) return itemList;
        itemList.push(...items);
        return itemList;
      }, [] as Item[]);
  });

export const selectItemsByConsolidationGroup = createSelector(
  [selectSlice],
  groupState => {
    return groupState.groups
      ?.filter(({ items }) => items.length > 0)
      ?.map(({ items, groupName }) => ({ items, groupName }));
  },
);

export const selectItemsByConsolidationGroupWithoutGroup = createSelector(
  [selectSlice],
  groupState => {
    return groupState.groups
      ?.flatMap(({ items }) => items)
      .filter(items => items);
  },
);

export const selectStakeholdersGroupId = createSelector(
  [selectSlice],
  groupState => groupState.stakeholdersGroupId,
);

export const selectStakeholdersIsFetching = createSelector(
  [selectSlice],
  groupState => groupState.stakeholdersIsFetching,
);

export const selectStakeholdersError = createSelector(
  [selectSlice],
  groupState => groupState.stakeholdersError,
);

// TODO move this and share logic to backend
export const selectGroupsByUserId = createSelector([selectSlice], groupState =>
  groupState.groups.filter(
    ({ groupStakePercentage }) =>
      groupStakePercentage && Number(groupStakePercentage) > 0,
  ),
);

export const selectCurrentGroupIsShared = (userId?: string) =>
  createSelector([selectSlice], groupState => {
    if (!userId) return false;
    if (
      !groupState.currentGroupId ||
      groupState.currentGroupId === ConsolidatedGroupData._id
    )
      return false;
    const currentGroup = groupState.groups.find(
      ({ _id }) => groupState.currentGroupId === _id,
    );
    return currentGroup && !currentGroup.groupStakePercentage;
  });

export const selectItemIdMappings = createSelector([selectSlice], groupState =>
  groupState.groups.reduce(
    (acc, group) => {
      group.items.forEach(({ _id }) => (acc[_id] = group._id));
      return acc;
    },
    {} as Record<string, string>,
  ),
);
