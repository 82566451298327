export const container = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const pageContainer = {
  width: 760,
};

export const pagesContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  marginTop: '20px',
  zIndex: 2,
};

export const button = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'addition.lightGreyLabelColor',
  height: '64px',
  width: '56px',
  padding: 0,
  borderRadius: '50%',
  ':hover': {
    backgroundColor: 'addition.lightGreyLabelColor',
  },
};
