import { useState } from 'react';
import { Box, Typography, Link } from '@mui/material';
import { CalculationBar, CalculationLegend } from 'app/components';

import style from './style';
import { useTranslation, Trans } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { LabelText } from 'app/components/LabelText/Loadable';
import { ErrorOutline } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { TFunction } from 'i18next';
import { HoldingReadingInterface } from 'app/features/AssetLiabilitySlice/types';

interface Props {
  heading?: string;
  nodata?: boolean;
  readings: HoldingReadingInterface[] | null;
  legendClasses?: string[];
}
const ConsolidationCalcuationBar = (props: Props) => {
  const { heading, nodata, readings, legendClasses } = props;
  const [hoveredBar, setHoveredBar] = useState('');
  const { t } = useTranslation();
  return (
    <Box mb={4} width={1} sx={style.boxContainerStyles}>
      <Box sx={style.disclaimerBoxStyle}>
        <Typography sx={style.headingStyles}>{heading}</Typography>
        {heading === 'Benchmarking: Tiger 21' && (
          <Tooltip
            sx={style.disclaimerStyles}
            title={
              <Trans
                i18nKey={'benchmark.disclaimer'}
                t={(s => capitalize(t(s))) as TFunction}
                components={{
                  bold: <strong />,
                  redirect: (
                    <Link
                      component="button"
                      onClick={() =>
                        window.open(
                          'https://tiger21.com/',
                          '_blank',
                          'rel=noopener noreferrer',
                        )
                      }
                    />
                  ),
                }}
              />
            }
            placement="top"
          >
            <Button>{capitalize(t('learnMore'))}</Button>
          </Tooltip>
        )}
      </Box>

      {!nodata && (
        <Box mt={2} mb={4}>
          <LabelText
            title={<ErrorOutline sx={{ verticalAlign: 'middle' }} />}
            text={capitalize(t('consolidation.info.notEnoughData'))}
            variant="variantIcon"
          />
        </Box>
      )}

      {nodata && (
        <>
          <CalculationBar
            calculationBarValues={readings || []}
            setHoveredBar={setHoveredBar}
          />
          <CalculationLegend
            hoveredBar={hoveredBar}
            availableClasses={legendClasses}
          />
        </>
      )}
    </Box>
  );
};

export default ConsolidationCalcuationBar;
