/**
 *
 * Asynchronously loads the component for ListItemAccordion
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ListItemAccordion = lazyLoad(
  () => import('./index'),
  module => module.ListItemAccordion,
);
