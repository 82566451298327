import { muiTheme } from 'styles/muiTheme';

export const emptyScenarioStyles = {
  ...muiTheme.variants.userHomeContentStyles,
  position: 'relative',
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
};

export const emptyScenarioBanner = {
  backgroundColor: muiTheme.palette.addition.infoBackgroundColor,
  padding: '24px',
  borderRadius: '5px',
  boxShadow: muiTheme.variants.modalBoxShadow,
};

export const alert = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  width: '95%',
  top: '30px',
  color: 'primary.contrast',
  backgroundColor: 'primary.main',
  padding: '12px',
  borderRadius: '5px',
  boxShadow: muiTheme.variants.modalBoxShadow,
};
