import { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { userTasksSaga } from './saga';
import { NewUserTask, UserTask, UserTasksState } from './types';

export const initialState: UserTasksState = {
  isFetching: false,
  isModifying: false,
  isSuccess: false,
  loaded: false,
  error: undefined,
  typeFilter: undefined,
  userTasks: [],
  newTaskPayload: undefined,
  newTaskSuccessResponse: undefined,
  updateUserTask: undefined,
  updateUserTaskSuccessResponse: undefined,
  deleteTasks: [],
  statusTasks: undefined,
  typeTasks: false,
  addNewTask: false,
};

const slice = createSlice({
  name: 'userTasks',
  initialState,
  reducers: {
    getAllUserTasks(state, action: Action) {
      state.isFetching = true;
      state.typeFilter = undefined;
      state.error = undefined;
      state.loaded = false;
    },
    getUserTasksByType(state, action: PayloadAction<string>) {
      state.isFetching = true;
      state.typeFilter = action.payload;
      state.error = undefined;
    },
    getUserTasksSuccess(state, action: PayloadAction<UserTask[]>) {
      state.isFetching = false;
      state.isSuccess = true;
      state.userTasks = action.payload;
      state.error = undefined;
      state.loaded = true;
    },
    getUserTasksError(state, action: PayloadAction<{ message: string }>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.loaded = false;
      state.error = action.payload;
    },
    createNewUserTask(state, action: PayloadAction<NewUserTask>) {
      state.isFetching = true;
      state.newTaskPayload = action.payload;
      state.error = undefined;
    },
    newUserTasksSuccess(state, action: PayloadAction<{ _id: string }>) {
      state.newTaskSuccessResponse = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
      state.isModifying = true;
      state.newTaskPayload = undefined;
      state.error = undefined;
    },
    newUserTasksError(state, action: PayloadAction<{ message: string }>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isModifying = false;
      state.error = action.payload;
    },
    cleanUpTasks(state, action: Action) {
      state.isFetching = false;
      state.isSuccess = false;
      state.loaded = false;
      state.isModifying = false;
    },
    updateUserTask(
      state,
      action: PayloadAction<{ task: UserTask; message?: string }>,
    ) {
      state.isFetching = true;
      state.updateUserTask = action.payload.task;
      state.error = undefined;
    },
    updateTasksSuccess(state, action: PayloadAction<{ replaced: number }>) {
      state.updateUserTaskSuccessResponse = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
      state.updateUserTask = undefined;
      state.error = undefined;
      state.isModifying = true;
    },
    updateTasksError(state, action: PayloadAction<{ message: string }>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isModifying = false;
      state.error = action.payload;
    },
    deleteUserTask(state, action: PayloadAction<{ _id: string }>) {
      state.deleteTaskPayload = action.payload;
      state.isFetching = true;
      state.error = undefined;
    },
    deleteTasksSuccess(state, action: PayloadAction<{ deleted: number }>) {
      state.isFetching = false;
      state.isSuccess = true;
      state.isModifying = true;
      state.error = undefined;
    },
    deleteTasksError(state, action: PayloadAction<{ message: string }>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isModifying = false;
      state.error = action.payload;
    },
    statusTask(state, action: PayloadAction<string>) {
      state.statusTasks = action.payload;
    },
    typeTaskArchived(state, action: PayloadAction<boolean>) {
      state.typeTasks = action.payload;
    },
    addNewTask(state, action: PayloadAction<boolean>) {
      state.addNewTask = action.payload;
    },
  },
});

export const { actions: userTasksActions, reducer } = slice;

export const useUserTasksSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: userTasksSaga });
  return { actions: slice.actions };
};
