export type UserTask = {
  _id: string;
  groupId?: string;
  userTaskName: string;
  priority: number;
  status: 'todo' | 'doing' | 'completed';
  dueDate: string;
  description: string;
  createdOn: string;
  updatedOn: string;
  archived: boolean;
  createdBy: string;
  stakeholders: Stakeholders[];
  assignedTo: string;
  ownership: string;
  links: LinkItem;
  isNew?: boolean; //may use this for showing New tasks
  newTasks?: number; //may use this for showing New tasks
};

export enum TypeTasks {
  TO_DO = 'todo',
  DOING = 'doing',
  COMPLETED = 'completed',
}

export enum Tasks {
  TASKS = 'Tasks',
  ENTITIES = 'ENTITIES',
}

export interface Stakeholders {
  _id: string;
  accessLevel: string;
}
export type LinkItem = {
  type: string;
  item: string;
};

export type NewUserTask = {
  userTaskName: string;
  groupId?: string;
  priority: number;
  status: 'todo' | 'doing' | 'completed';
  dueDate: string;
  description: string;
  links: LinkItem;
};

export type deleteUserTask = {
  _id: string;
};

export type UserTasksState = {
  isFetching: boolean;
  isModifying: boolean;
  isSuccess: boolean;
  loaded: boolean;
  error?: { message: string };
  userTasks: UserTask[];
  userTask?: UserTask;
  typeFilter?: string;
  deleteTaskPayload?: deleteUserTask;
  newTaskPayload?: NewUserTask;
  newTaskSuccessResponse?: { _id: string };
  updateUserTask?: UserTask;
  updateUserTaskSuccessResponse?: { replaced: number };
  deleteTasks?: UserTask[];
  statusTasks?: string;
  typeTasks: boolean;
  addNewTask: boolean;
};
