import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Box, Typography, Button } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import * as styles from './styles';

export const PDFViewer = ({ src }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };

  return (
    <Box sx={styles.container}>
      <Button
        variant="contained"
        sx={styles.button}
        onClick={() =>
          setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1))
        }
        style={{ left: '-80px' }}
      >
        <ChevronLeft />
      </Button>
      <Button
        variant="contained"
        sx={styles.button}
        onClick={() =>
          setPageNumber(prevPageNumber =>
            Math.min(prevPageNumber + 1, numPages),
          )
        }
        style={{ right: '-80px' }}
      >
        <ChevronRight />
      </Button>
      <Box sx={styles.pageContainer}>
        <Box sx={styles.pageContainer}>
          <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
            <Page width={760} pageNumber={pageNumber} />
          </Document>
          <Box sx={styles.pagesContainer}>
            <Typography color="white">
              Page {pageNumber} of {numPages}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
