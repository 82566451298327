import React, { forwardRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import {
  Box,
  BaseTextFieldProps,
  IconButton,
  InputAdornment,
  TextField,
  StandardTextFieldProps,
  InputProps as StandardInputProps,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { MaskedInput } from './MaskedInput';
import { muiTheme } from 'styles/muiTheme';
import { MaskedTypes } from './model';

export const FormHelperTextSx = {
  alignItems: 'flex-start',
  display: 'flex',
  color: 'secondary.main',
};

export const FormHelperIconSx = {
  ...muiTheme.typography.caption,
  marginRight: '4px',
  marginTop: '4px',
};

export interface InputProps extends BaseTextFieldProps {
  isSuccess?: boolean;
  isWarning?: boolean;
  isError?: boolean | FieldError;
  Icon?: (props: any) => JSX.Element | null;
  HelperTextIcon?: (props: any) => JSX.Element | null;
  infoUrl?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showPassword?: boolean;
  type?: string;
  InputProps?: Partial<StandardInputProps>;
}

export const Input = forwardRef((props: InputProps, ref) => {
  const {
    type,
    isSuccess,
    isWarning,
    isError,
    Icon,
    helperText,
    HelperTextIcon,
    ...inputValueProps
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const renderHelperText = () => (
    <>
      {helperText && HelperTextIcon && (
        <HelperTextIcon style={FormHelperIconSx} />
      )}
      {helperText}
    </>
  );

  const renderEndAdornment = () => (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  const renderBaseField = (
    customInputValueProps: StandardTextFieldProps = {},
  ) => (
    <TextField
      fullWidth
      size="small"
      variant="outlined"
      FormHelperTextProps={{
        sx: FormHelperTextSx,
      }}
      {...inputValueProps}
      {...customInputValueProps}
      InputProps={{
        ...inputValueProps.InputProps,
        ...customInputValueProps.InputProps,
      }}
      helperText={renderHelperText()}
      inputRef={ref}
    />
  );

  const renderPasswordField = () =>
    renderBaseField({
      type: showPassword ? 'text' : 'password',
      InputProps: {
        endAdornment: renderEndAdornment(),
      },
    });

  return (
    <Box sx={{ width: '100%' }}>
      {type && MaskedTypes[type] ? (
        <MaskedInput
          fullWidth
          size="small"
          variant="outlined"
          FormHelperTextProps={{
            sx: FormHelperTextSx,
          }}
          {...inputValueProps}
          type={MaskedTypes[type]}
          helperText={renderHelperText()}
        />
      ) : type === 'password' ? (
        renderPasswordField()
      ) : (
        renderBaseField()
      )}
    </Box>
  );
});
