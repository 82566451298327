import shadows from 'styles/shadows';

export default {
  cardStyle: {
    padding: '6px',
    border: '5px solid',
    borderColor: 'secondary.12p',
    borderTop: '0.4rem solid #A8D2AF',
    boxShadow: shadows[12],
    witdh: 1,
    ':hover': {
      background: '#f6f6f6',
      borderTop: '0.4rem solid rgba(139, 197, 152, 1)',
    },
  },
  stackStyle: { direction: 'row', spacing: 2, mt: 2, mb: 2 },
  titleStyle: {
    variant: 'h5',
    component: 'div',
    textAlign: 'center',
  },
  descriptionStyle: { mb: 1.5, textAlign: 'center', color: 'text.secondary' },
  actionButtonStyle: {
    variant: 'contained',
    color: 'success',
    size: 'small',
    borderRadius: 2,
    backgroundColor: '#50BD7D',
  },
};
