import shadows from 'styles/shadows';

export default {
  cardStyles: {
    border: '1px solid',
    borderColor: 'secondary.12p',
    borderRadius: '4px',
    boxShadow: shadows[12],
    marginTop: '40px',
    textAlign: 'center',
    maxWidth: '810px',
    marginInline: 'auto',
    padding: '40px 155px 60px 155px',
  },
  contentStyles: {
    paddingTop: '24px',
    paddingBottom: '40px',
  },
};
