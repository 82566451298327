import { muiTheme } from 'styles/muiTheme';

export const accordion = {
  backgroundColor: muiTheme.palette.addition.accordionBackgroundColor,
  borderTop: muiTheme.variants.componentBorder,
  padding: 0,
  width: '100%',
  borderRadius: '4px',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    marginTop: 0,
  },
};

export const accordionHeader = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  paddingRight: 1,
};

export const accordionSummary = {
  border: 0,
  background: 'inherit',
  flexDirection: 'row-reverse',
  padding: 0,
  paddingLeft: 1,
  '&.Mui-expanded': {
    minHeight: '48px',
  },
  '.MuiAccordionSummary-expandIconWrapper': {
    color: 'primary.main',
    transform: 'rotate(-90deg)',
  },
  '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '.MuiAccordionSummary-content': {
    margin: '1px 0',
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    margin: '1px 0',
  },
};

export const accordionDetails = {
  display: 'flex',
  flexDirection: 'column',
  background: 'inherit',
  padding: '0',
  '.MuiAccordion-root.Mui-expanded': {
    marginBottom: '0',
  },
};

export const textEllipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
