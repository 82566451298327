/**
 *
 * Asynchronously loads the component for ConsolidationPage
 *
 */

import { SuspenseSpinner } from 'app/components/SuspenseSpinner';
import { lazyLoad } from 'utils/loadable';

export const ConsolidationPage = lazyLoad(
  () => import('./index'),
  module => module.ConsolidationPage,
  {
    fallback: <SuspenseSpinner type="linear" color="secondary" />,
  },
);
