import { useState } from 'react';
import { Modal } from 'app/components/Modal.new';
import { Box, Card, Grid, Typography } from '@mui/material';
import { BottomRightGroupButtons } from 'app/components/Buttons/BottomRightGroupButtons';
import { useUpdateFileMutation } from '../../services';
import { selectConsolidationContext } from 'app/features/GroupSlice/selectors';
import { useSelector } from 'react-redux';
import { EntitiesFolders } from './components/entities-folders';
import { GroupData } from 'app/features/GroupSlice/types';
import { FileConfig, SelectedFolder } from 'app/pages/vault/models/vault.types';
import * as styles from './style';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  fileConfig: FileConfig;
};

export const ModalMoveFolder = ({ isOpen, onClose, fileConfig }: Props) => {
  const [selectedFolder, setSelectedFolder] = useState<SelectedFolder | null>(
    null,
  );

  const { t } = useTranslation();

  const [updateFile] = useUpdateFileMutation();

  const { all: allGroupsData } = useSelector(selectConsolidationContext);

  const entities = allGroupsData.filter(
    (group: GroupData) => group.groupType !== 'PERSONAL',
  );

  const { file, callback } = fileConfig;

  const handleMoveToFolder = () => {
    /* 
    updateFile({
      fileId: file._id,
      data: {
        groupId: selectedFolder._id,
      },
    }); */
  };

  const handleSelectFolder = folder => {
    if (!folder.children) {
      setSelectedFolder(folder);
    }
  };

  return (
    <Modal
      isOpen={!!isOpen}
      onClose={onClose}
      header=""
      contentSx={styles.modal}
      hideCloseButton
    >
      <Box width="100%">
        <Grid item xs={12} sx={{ padding: '0 20px' }}>
          <Typography variant="h6" mb={3}>
            Move <strong>{file?.fileName}</strong> to folder
          </Typography>
          <Grid item xs={12}>
            <Typography variant="body1" mb={3} mt={3}>
              {selectedFolder
                ? `Selected Folder: ${selectedFolder?.name}`
                : 'Select an available folder'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {entities.map(group => (
              <EntitiesFolders
                key={group._id}
                group={group}
                selectedFolder={selectedFolder}
                onSelectFolder={handleSelectFolder}
              />
            ))}
          </Grid>
        </Grid>
        <Card elevation={4} sx={{ padding: '20px' }}>
          <BottomRightGroupButtons
            onPrimaryBtnClick={onClose}
            primaryBtnText="Cancel"
            secondaryBtnText="Add To Folder"
            onSecondaryBtnClick={handleMoveToFolder}
            secondaryBtnDisabled={!selectedFolder}
          />
        </Card>
      </Box>
    </Modal>
  );
};
