import { muiTheme } from 'styles/muiTheme';

export default {
  boxContainerStyles: {
    border: muiTheme.variants.componentBorder,
    borderRadius: '4px',
    display: 'inline-block',
    padding: '18px',
    fontSize: 14,
  },
  headingStyles: {
    fontSize: 16,
    color: 'text.secondary',
    fontWeight: 700,
    lineHeight: '25.6px',
    letterSpacing: '0.15px',
  },
  disclaimerStyles: {
    fontSize: 8,
  },
  disclaimerBoxStyle: {
    display: 'flex',
  },
};
