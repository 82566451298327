import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'app/components/Modal.new';
import { Box, Card, Grid, Typography } from '@mui/material';
import { BottomRightGroupButtons } from 'app/components/Buttons/BottomRightGroupButtons';
import * as styles from './style';
import { useDeleteFileMutation } from '../../services';
import { FileConfig } from 'app/pages/vault/models/vault.types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  fileConfig: FileConfig;
};

export const ModalDelete = ({ isOpen, onClose, fileConfig }: Props) => {
  const { t } = useTranslation();

  const [deleteFile] = useDeleteFileMutation();

  const isValid = true;

  const { file, callback } = fileConfig;

  const handleDelete = () => {
    deleteFile(file).then(() => {
      callback(file);
      onClose();
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header=""
      contentSx={styles.modalPreview}
      hideCloseButton
    >
      <Box width="100%">
        <Grid item xs={12} sx={{ padding: '0 20px' }}>
          <Typography variant="h5" mb={3}>
            {t('vault.modals.deleteFile.title')}
          </Typography>
          <Grid item xs={12}>
            <Typography variant="body1" mb={3} mt={3}>
              <Trans
                i18nKey={'vault.modals.deleteFile.message'}
                components={{
                  strong: <strong />,
                }}
                values={{
                  file: file?.fileName,
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Card elevation={4} sx={{ padding: '20px' }}>
          <BottomRightGroupButtons
            onPrimaryBtnClick={onClose}
            primaryBtnText={t('vault.modals.deleteFile.buttons.cancel')}
            secondaryBtnText={t('vault.modals.deleteFile.buttons.delete')}
            secondaryBtnStyles={{
              backgroundColor: '#EF5350',
            }}
            onSecondaryBtnClick={handleDelete}
            secondaryBtnDisabled={!isValid}
          />
        </Card>
      </Box>
    </Modal>
  );
};
