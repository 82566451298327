import { GroupData } from 'app/features/GroupSlice/types';
import { getInstance } from './http';

// TODO: We need a validation for these envs
// IMPORTANT: is not safe to have a default value for these envs

const DEFAULT_LOCAL_URL = 'http://localhost:3000/api/v1';
export const OSS_URL = process.env.REACT_APP_UBE_BASE_URL || DEFAULT_LOCAL_URL;
export const UBE_URL = process.env.REACT_APP_UBE_BASE_URL || DEFAULT_LOCAL_URL;
export enum ConnectedAccountProducts {
  INVESTMENT = 'INVESTMENT',
  BANKING = 'BANKING',
}

export const meldUrlBuilder =
  () =>
  async (targetGroup: GroupData, productType: ConnectedAccountProducts) => {
    const instance = getInstance(OSS_URL);
    let products = ['INVESTMENT_TRANSACTIONS', 'INVESTMENT_HOLDINGS'];
    if (productType === ConnectedAccountProducts.BANKING)
      products = ['BALANCES', 'TRANSACTIONS'];
    const result = await instance
      .post('/auth/connected-account', {
        groupId: targetGroup._id,
        products,
      })
      .catch(console.error);
    const baseUrl = `${process.env.REACT_APP_MELD_CONNECT_URL}?connectToken=${result?.data.token}`;

    return baseUrl;
  };
