import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { ConsolidatedGroupData } from 'constants/keyValue';
import { initialState } from '.';

const sliceStripe = (state: RootState) => state?.stripe || initialState;

export const selectGetStripeCustomer = createSelector(
  [sliceStripe],
  state => state.stripeContactDetails,
);

export const selectUpdateCardCustomer = createSelector(
  [sliceStripe],
  state => state.stripeUpdateDetailsUrl,
);
export const selectGetCardDetails = createSelector(
  [sliceStripe],
  state => state.stripeDefaultCardDetails,
);

export const selectGetPaymentHistoryDetails = createSelector(
  [sliceStripe],
  state => state.paymentHistory,
);
