export default {
  boxStyles: {
    border: '1px solid',
    borderColor: 'primary.12p',
    backgroundColor: 'primary.12p', //'secondary.8p',
    borderRadius: '4px',
    minHeight: '500px',
    width: '100%',
    marginTop: '16px',
    marginBottom: '24px',
  },
  topBorderStyles: {
    backgroundColor: 'primary.main',
    borderRadius: '60px',
    height: '6px',
  },
  headerStakeholderStyles: {
    borderBottom: `1px solid`,
    borderColor: 'secondary.30p',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 33px 7px 33px',
    width: 'initial',
  },
  defaultButtonStyles: {
    textTransform: 'uppercase',
    marginLeft: '12px',
    marginBottom: '12px',
    color: 'primary.contrast',
    padding: '8px 22px',
  },
  noStakeholderStyles: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    padding: '14px 30px',
    margin: '0 12px',
    backgroundColor: 'secondary.12p',
    border: `1px solid`,
    borderColor: 'secondary.8p',
    borderRadius: '4px',
    width: 'initial',
  },
  containerSx: {
    padding: '1em 0',
  },
};
