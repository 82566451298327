/**
 *
 * Asynchronously loads the component for AssetRow
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AssetRow = lazyLoad(
  () => import('./index'),
  module => module.AssetRow,
);
