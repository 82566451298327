import { ExpandMore, Apartment } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Grid,
  Typography,
} from '@mui/material';
import { createFolderTreeWithoutFiles } from 'app/pages/vault/utils/files.utils';
import {
  getGroupTypeIcon,
  getGroupType,
} from 'app/pages/vault/utils/guards.utils';
import { FolderAccordion } from '../folders-accordion';
import * as style from './style';
import { useEffect, useState } from 'react';
import { GroupData } from 'app/features/GroupSlice/types';
import {
  Folder,
  FolderTree,
  SelectedFolder,
} from 'app/pages/vault/models/vault.types';

type Props = {
  group: GroupData;
  selectedFolder: SelectedFolder | null;
  onSelectFolder: (folder: Folder) => void;
};

export const EntitiesFolders = ({
  group,
  selectedFolder,
  onSelectFolder,
}: Props) => {
  const [folders, setFolders] = useState<FolderTree | null>(null);

  //TODO: Enable this when the functionality is done on the ticket:
  /*   useEffect(() => {
    if (group) {
      const foldersTree = createFolderTreeWithoutFiles([group]);
      setFolders(foldersTree);
    }
  }, [group]);
 */
  return (
    <div>
      <Accordion sx={style.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            ...style.accordionSummary,
          }}
        >
          <Grid sx={style.accordionHeader}>
            <Grid display="flex" flexDirection="row" alignItems="center">
              <Avatar
                sx={{ backgroundColor: getGroupTypeIcon(group.groupType) }}
              >
                <Apartment />
              </Avatar>
              <Typography sx={{ marginLeft: 1 }} variant="body1">
                {group.groupName} {getGroupType(group.groupType)}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={style.accordionDetails}>
          <div className="files">
            {folders?.children?.map(folder => (
              <FolderAccordion
                key={folder.name}
                folder={folder}
                depth={1}
                selectedFolder={selectedFolder}
                onSelectFolder={onSelectFolder}
              />
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
