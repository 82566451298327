import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Link, Typography } from '@mui/material';
import { capitalize } from '@mui/material/utils';
import { TFunction } from 'i18next';
import { AdminEmail } from 'constants/admin';
import { GppGoodSharp } from '@mui/icons-material';
import { paywallBody, paywallPageBox, paywallTitle } from './style';

export function PaywallPage() {
  const { t } = useTranslation();

  return (
    <Box sx={paywallPageBox}>
      <GppGoodSharp color="primary" sx={{ width: 170, height: 170 }} />
      <Box sx={paywallTitle}>
        <Typography variant="h3">{t('paywall.header.title')}</Typography>
        <Typography variant="subtitle1">
          {t('paywall.header.subtitle')}
        </Typography>
      </Box>
      <Box sx={paywallBody}>
        <Typography variant="body1">
          <Trans
            i18nKey={'paywall.description.contactUs'}
            t={(s => capitalize(t(s))) as TFunction}
            components={{
              redirect: (
                <Link
                  component="button"
                  onClick={() =>
                    window.open(`mailto:${AdminEmail}`, 'hidden-iframe')
                  }
                  color="info.main"
                />
              ),
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
}
