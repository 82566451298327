import { Modal } from 'app/components/Modal';
import { Box, Card, Grid, Typography } from '@mui/material';
import { ControlledInput } from 'app/components/Inputs/ControlledInput';
import { Button } from 'app/components';
import { muiTheme } from 'styles/muiTheme';
import * as styles from './style';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
  onSaveScenario?: (data: any) => void; //TODO: Refactor this type
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const SaveScenarioModal = ({
  isOpen,
  setIsOpen,
  onSaveScenario,
}: Props) => {
  const { t } = useTranslation();

  const useFormInterface = useForm({
    mode: 'onBlur',
  });

  const {
    register,
    watch,
    formState: { errors, isValid },
  } = useFormInterface;

  const scenarioTitle = watch('scenarioTitle');

  const handleSaveScenario = () => {
    useFormInterface.unregister();
    onSaveScenario?.(scenarioTitle);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      contentSx={styles.modalContent}
      closeModalBtnStyle={styles.modalButtonStyle}
    >
      <Grid sx={styles.modalHeader}>
        <Typography variant="h6">
          {t('scenarioModeling.saveScenario.headers.title')}
        </Typography>
      </Grid>
      <Grid sx={styles.modalBodyContainer}>
        <Typography mb={2}>
          {t('scenarioModeling.saveScenario.headers.subtitle')}
        </Typography>
        <Grid item xs={12}>
          <ControlledInput
            watch={watch}
            type="text"
            size="medium"
            register={register}
            errors={errors.scenarioTitle}
            name="scenarioTitle"
            value={scenarioTitle}
            label={t(
              'scenarioModeling.saveScenario.forms.fields.scenarioTitle.label',
            )}
          />
        </Grid>
      </Grid>
      <Card elevation={4} sx={styles.modalFooterButtons}>
        <Button
          variant="text"
          type="button"
          onClick={handleCancel}
          sx={muiTheme.variants.bottomRightButtonPrimary}
        >
          {t('scenarioModeling.saveScenario.buttons.cancel')}
        </Button>
        <Button
          variant="contained"
          type="button"
          onClick={handleSaveScenario}
          disabled={!isValid}
          sx={muiTheme.variants.bottomRightButtonSecondary}
        >
          {t('scenarioModeling.saveScenario.buttons.save')}
        </Button>
      </Card>
    </Modal>
  );
};
