import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.signup || initialState;

export const selectUser = createSelector([selectSlice], state => state);

export const selectIsFetching = createSelector(
  [selectSlice],
  signupState => signupState.isFetching,
);

export const selectIsSuccess = createSelector(
  [selectSlice],
  signupState => signupState.isSuccess,
);

export const selectError = createSelector(
  [selectSlice],
  signupState => signupState.signupError,
);

export const selectSignupResponse = createSelector(
  [selectSlice],
  signupState => signupState.signupResponse,
);

export const selectFormData = createSelector(
  [selectSlice],
  signupState => signupState.formData,
);

export const selectCountries = createSelector(
  [selectSlice],
  signupState => signupState.countries,
);

export const selectCode = createSelector(
  [selectSlice],
  signupState => signupState.verificationCode,
);
export const selectIsRetryingCode = createSelector(
  [selectSlice],
  signupState => signupState.isRetryingCode,
);

export const selectVerificationSuccess = createSelector(
  [selectSlice],
  signupState => signupState.verificationSuccess,
);

export const selectVerificationCodeFails = createSelector(
  [selectSlice],
  signupState => signupState.verificationFails,
);

export const selectSendVerificationCodeFails = createSelector(
  [selectSlice],
  signupState => signupState.sendVerificationFails,
);

export const selectSendVerificationCodeSuccess = createSelector(
  [selectSlice],
  signupState => signupState.sendVerificationSuccess,
);

export const selectVerifyInviteTokenFails = createSelector(
  [selectSlice],
  signupState => signupState.signupError,
);

export const selectVerifyInviteTokenSuccess = createSelector(
  [selectSlice],
  signupState => signupState.isSuccess && Boolean(signupState.inviteTokenData),
);

export const selectInviteTokenValid = createSelector(
  [selectSlice],
  signupState => Boolean(signupState.inviteTokenData?.isValid),
);

export const selectInviteTokenData = createSelector(
  [selectSlice],
  signupState => signupState.inviteTokenData,
);
