import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import * as styles from './styles';

type Props = {
  dataRows?: any; //TODO: Fix the types here
};

export const Content = ({ dataRows }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {dataRows?.map(row => (
        <Grid key={row.label} id="row-item" sx={styles.rowItem}>
          <Typography variant="caption" color="text.secondary">
            {t(row.label)}
          </Typography>
          <Typography color={row.color} fontWeight="700">
            {row.value}
          </Typography>
        </Grid>
      ))}
    </>
  );
};
