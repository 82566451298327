import { takeLatest } from 'redux-saga/effects';
import { toastActions as actions } from '.';

export function showErrorToast(data) {}

export function showSuccessToast(data) {}

export function* toastSaga() {
  yield takeLatest(actions.showErrorToast.type, showErrorToast);
  yield takeLatest(actions.showSuccessToast.type, showSuccessToast);
}
