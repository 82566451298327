import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Container } from '@mui/material';
import style from './styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { MoveBackButton } from 'app/components/Buttons/MoveBackButton/Loadable';
import { getRoutePath } from 'app/routes/routePath';
import { useParams } from 'react-router';

const gettingStartedFaq = [
  {
    question: `What's new`,
    answer: `
    <li><strong>Billing and Account Management:</strong> Easily update your billing details and manage your account, including address, payment methods, and subscription info.</li>
    <li><strong>Sharing Features and Stakeholder Management:</strong> Share your MyFO account with stakeholders and manage shared accounts more effectively.</li>
    <li><strong>Streamlined User Interface:</strong> A single button for adding connected accounts, improved transaction history management, and a more intuitive interface for bulk item creation</li>
    <li><strong>Enhanced Visualization and Navigation:</strong> Fixed top navigation bar for easy scrolling and better graph display for large figures.</li>
    <li><strong>Advisor Tools and Backend Support:</strong> New features for advisors including a dashboard and client management tools, plus backend enhancements for scenario modeling.</li>
  `,
  },
  {
    question: `How do I get started with MyFO?`,
    answer: `To get started with MyFO, simply visit our website and sign up for an account. To create an account, click on the "Sign Up" button usually found at the top-right corner of our website. You will need to provide your email address and create a password. A confirmation link with a verification code will be sent to your email, add the certification code in MyFO to verify your account and get started.`,
  },
  {
    question: `I have successfully created an account. What should I do next?`,
    answer: `Congratulations on setting up your account! The next step is to set up your profile in the MyFO platform by providing necessary personal information. You can do this by navigating to the "Profile Setting" section by clicking your name on the top right corner. You can update your latest personal information such as your contact information, primary email address and country.
    Please click here to see a <a href="https://www.loom.com/share/8aa80aab5f0746a790ef7c6d9a6e9495" target="blank">video</a>.`,
  },
];

const usingMyFoFaq = [
  {
    question: `How do I create entities on MyFO?`,
    answer: `Once you've set up your profile, you can start adding your financial information and managing your wealth. Not all assets are held personally and may be held in a Holding Company, Trust or Family Foundation. If you are an owner of a Holding Company, Trust or Family Foundation, you will want to create these entities before you add assets to them. Go to the 'Entities' tab in the left hand side menu, click on the 'Add New' button under Entities, and fill in the necessary information. 
    Please click here to see a <a href="https://www.loom.com/share/40fc0eb0eaca4bd2b6f665624bf919c9" target="blank">video</a>.`,
  },
  {
    question: `How do I add assets and liabilities to an entity?`,
    answer: `After creating an entity, you can add assets and liabilities to it. Under the Consolidation view, select the relevant entity on the left hand side and then navigate to the 'Assets and Liabilities' section. Click on the 'Add New' button located on the right side, you can choose the particular asset or liability and fill in the necessary details, and save. 
    Please click here to see a <a href="https://www.loom.com/share/31800b42a54843f1aa594d3d313d0bd0" target="blank">video</a>.`,
  },
  {
    question: `Can I add personal assets and liabilities as well?`,
    answer: `Absolutely! You can add personal assets and liabilities in the "Personal" section in MyFO under the Consolidation view. Just navigate to the "Assets and Liabilities'' section and click on the "Add New" button located on the right side. From here, you can choose the particular asset or liability and fill in the necessary details, and save.
    Please click here to see a <a href="https://www.loom.com/share/31800b42a54843f1aa594d3d313d0bd0" target="blank">video</a>.`,
  },
  {
    question: 'How to add a Stakeholder?',
    answer: `A stakeholder is an individual that you can grant access to aspects of your MyFO account. A stakeholder can include a family member, an advisor (such as a tax accountant), co-owner of a business or asset, or another individual who may need access to aspects of your MyFO account.
      To add a new stakeholder to your account, first select the "Stakeholder Map" option in the top banner of our platform. This will lead you to a new screen. Click on the "Add New" button to open the stakeholder details form.
      The form will prompt you to enter the stakeholder's basic information, including their name, associated company, email address, and phone number. Be sure to fill in these details accurately as this information will be used for all future interactions and communications on the platform.
      Next, you'll be asked to grant the stakeholder access to an Entity or an Asset/Liability. If you're sharing an Entity, you'll need to indicate whether the stakeholder is an owner. If they are, you'll be asked to enter the respective ownership percentage. If they're not an owner, you'll need to assign them a role. This could be 'Management', 'Family', or 'Adviser'.
      
      If you're only sharing access to an Asset/Liability, you'll also need to assign a role to the stakeholder. Please note that you can assign multiple roles to the same stakeholder based on their involvement with your entity or assets and liabilities.
    Remember, you can always modify stakeholder details or permissions at a later date, should the need arise. This ensures that your stakeholder map remains flexible and adaptable to your changing needs.`,
  },

  {
    question:
      'Where can I see an overview of all my assets, liabilities, and entities?',
    answer:
      "You can view everything in the 'Consolidated Dashboard'. Selecting “Consolidation” at the top banner and then “Consolidated” on the left hand side will provide you with a comprehensive overview of all your assets, liabilities, and entities, allowing for quick access and better financial management. Selecting “Personal” or one of the entities you have created on the left hand side will show just the assets and liabilities of that specific entity.",
  },
  {
    question: 'How to assign a task?',
    answer: `Adding a task to our platform is a straightforward process designed to keep your work organised and efficient. It is a great way to have stakeholders know what they need to do and when it needs to be done, such as reviewing monthly financial statements. Here's how to do it:
    
    <b>Access the Task Manager:</b> Click on the "Tasks" button located in the top banner of our platform. This will take you to the Task Manager window.
    
    <b>Select the Relevant Entity or Personal Account on the Left Side menu:</b> Choose the particular entity or personal account to which you'd like to assign a task. The selected entity's name should be visible under 'Tasks' on the main screen for your confirmation.

    <b>Add New Task:</b> Now, click on the "Add New" button in the right corner of your screen. This will open a new window or form for you to fill out the task details.

    <b>Fill Out Task Details:</b> Enter the required information for the task. If applicable, you have the option to link the task to an asset or liability.

    <b>Assign to Stakeholder:</b> Select the stakeholder who you want to assign the task to. It's also here where you'll set the priority, status, and due date of the task.

    <b>Add a Description:</b> Provide more details about the task in the description field. The more specific you are, the better your team will understand what needs to be done.

    <b>Create the Task:</b> Finally, after filling out all the necessary fields, click the "Create Task" button. Your task will be created and assigned to the chosen stakeholder.
    
    Remember, our platform is designed to streamline and simplify your workflow, so don't hesitate to create and assign as many tasks as necessary to manage your operations effectively.`,
  },
  {
    question: `I am having trouble navigating through the platform. Where can I get help?`,
    answer: `Please refer to FAQs to know how MyFO works. If you still need assistance, feel free to contact our customer service team at <b>support@myfo.tech</b>, who will be happy to assist you.`,
  },
  {
    question: `Can I sync my financial accounts and investments with MyFO?`,
    answer: `Yes, MyFO offers seamless integration with various financial institutions, allowing you to sync your financial accounts, investments, and transactions within our platform. This provides you with a consolidated view of your financial portfolio, making it easier to track and manage your wealth. These accounts can be added by selecting “Financial Account” or “Bank Account”, searching for your relevant financial institution and entering in the login information of this financial institution. Please note that this syncing may take up to 72 hours and is dependent on the financial institution providing the relevant information to MyFO. In the event your financial institution is not available or is not able to transmit data, we advise you to use our CSV upload feature. Further information regarding this feature can below`,
  },
  {
    question: `How does MyFO handle updates and new features?`,
    answer: `At MyFO, we strive to continuously enhance our platform and introduce new features based on user feedback and industry trends. We regularly release updates and improvements to ensure the best user experience. These updates are seamlessly integrated into the platform, and you'll be notified of any new features or enhancements as they become available.`,
  },
  {
    question: `Is MyFO compatible with different devices and operating systems?`,
    answer: `Yes, MyFO is a browser-based platform which makes it compatible with various operating systems including Windows, MacOS, and Linux. This means that you can use MyFO on different devices such as desktops, laptops, and tablets that run on these operating systems.
    However, it's important to note that as of now, MyFO is not fully optimised for mobile devices. While you can still access and use MyFO on a mobile device via a web browser, the user experience might not be as smooth and efficient as on larger screens. We recommend using MyFO on a desktop or laptop for the best user experience.`,
  },

  {
    question: `Why do my online accounts have limited historical data?`,
    answer: `MyFO is designed and provided by First Democracy Technology Inc. for the purpose of offering users an accessible, convenient, and simple platform to track and analyze their banking data trends. However, please be aware that there are some inherent limitations based on the nature of the information we are able to access from the banking institutions.

    MyFO relies on the data provided by your banking institutions, and in some instances, they may only provide a snapshot of the balance as of today, rather than a full set of historical data. As a result, our system will not initially display historical trends of your bank balances due to these limitations.
    
    However, as MyFO syncs with your banking data daily, it will gradually begin to compile a record of your balances. Over time, this will allow us to provide a graph that offers a comprehensive view of historical trends. This ongoing data collection and analysis is one of the core values of our platform, and it becomes more useful and insightful the longer you use it.
    
    We want to emphasize that the completeness and accuracy of the historical trend representation depends entirely on the continuous and regular usage of the platform and from the data provided by your financial institution. The more you use MyFO, the more detailed and extensive your trend analysis will become. It will allow you to gain valuable insights into your financial patterns and habits over time.
    
    By using MyFO, you acknowledge and agree that First Democracy Technology Inc. cannot be held responsible for the completeness of the initial historical data provided by your banking institutions. We encourage all users to continue using our platform regularly to gradually build a robust historical perspective of their financial trends.
    Our team at MyFO is committed to providing you with the most useful, reliable, and comprehensive personal finance tracking tool possible. `,
  },
];

const securityAndPrivacyFaq = [
  {
    question: `How does MyFO ensure the security and privacy of my data? `,
    answer: `We take data security and privacy seriously at MyFO. We employ secure data storage and advanced encryption technology where all data, both in transit and at rest, is encrypted. We also use industry-standard security measures to protect your sensitive information. Additionally, we follow strict and clear privacy policy that outlines what data we collect, why we collect it, and how we use and protect it. MyFO does not share your data with third parties without your consent.`,
  },
  {
    question: `Is my financial information safe with MyFO? `,
    answer: `We prioritise the security and confidentiality of your financial information. MyFO uses robust security measures, such as encryption, secure data storage, and multi-factor authentication, to safeguard your data. We comply with industry leading standards and regulations to ensure the highest level of data protection.`,
  },
];
const supportFaq = [
  {
    question: `How does MyFO handle customer support? `,
    answer: `MyFO provides dedicated customer support to assist you with any questions or issues you may encounter. Our support team is available through various channels, including email, live chat, and phone support. Our customer support team is committed to providing timely and helpful assistance to ensure a seamless experience on our platform. We understand the importance of addressing your concerns and inquiries promptly. Our standard customer support hours are from Monday to Friday, 9 AM to 5 PM Pacific Standard Time. We strive to respond to all queries as quickly as possible within these hours.`,
  },
];
const financialUnderstandingFaq = [
  {
    question: `Where do the multipliers in the business valuation tool come from?`,
    answer: `MyFO uses data provided by Professor Aswath Damodaran of the NYU Stern School of Business for business valuation calculations. This data, which is publicly available at https://pages.stern.nyu.edu/~adamodar/New_Home_Page/datafile/vebitda.html, is utilised as a baseline for our computations.
    
    Please be aware that while this data is a valuable resource, it is only a guide. The business valuation provided by MyFO should not be considered as an accurate market value or appraisal of your business. Business valuations are complex and depend on a multitude of factors that are unique to each business. We strongly recommend consulting with a qualified professional before making any decisions based on the business valuation provided by our software.

    By using our business valuation tool, you agree to these terms and understand that First Democracy Technology Inc (“MyFO”) cannot be held responsible for any actions taken based on the valuation provided by our software.`,
  },
  {
    question: `What does EBITDA mean?`,
    answer: `EBITDA stands for "Earnings Before Interest, Taxes, Depreciation, and Amortization." It is a measure of a company's operating performance and is calculated by adding back interest, taxes, depreciation, and amortization to net income. EBITDA can provide a clearer view of a company's profitability from its core operations, ignoring the potential distortions caused by tax structures, financing methods, and non-cash items like depreciation and amortization.

    Normalized EBITDA is a further adjustment of the EBITDA figure to eliminate the effects of non-recurring items or events that are not part of the usual business operations. These might include one-off charges or gains, extraordinary costs, or changes in accounting methods. The aim of normalizing EBITDA is to provide a more consistent and comparable measure of a business's regular operating performance.
    
    In business valuations, both EBITDA and Normalized EBITDA are commonly used metrics. They provide a way to compare the operational profitability of different companies, even across different industries, without the influence of factors such as financing structures, taxation, and non-cash accounting items. This helps potential investors or buyers to have a more realistic picture of a business's earning potential, which is a key factor in determining its value. However, they are just one part of a comprehensive business valuation, which should also consider factors like assets, liabilities, market conditions, and growth potential.`,
  },
  {
    question: `What is the Tiger 21 Benchmark?`,
    answer: `"Our software includes a benchmark feature that compares your investment allocation to that of TIGER 21, a renowned global network of ultra-high-net-worth entrepreneurs, investors, and executives. This information is provided to help you make informed decisions and understand how your investment allocation stands in comparison to successful, high-net-worth individuals.

    The data used for this comparison is sourced directly from TIGER 21's reported investment allocation, which is publicly available on their website. By using our software, you acknowledge that this comparison is intended for informational and educational purposes only, and does not constitute investment advice or a suggestion to follow the same allocation strategy.
    
    The performance of an investment allocation can vary significantly from one individual to another, depending on numerous factors including risk tolerance, investment horizon, and individual financial goals. Therefore, any investment decisions should be made based on your unique circumstances and, where appropriate, with the advice of a qualified professional.
    
    For a more comprehensive understanding of TIGER 21 and their investment allocation strategy, we encourage you to visit their official website at https://tiger21.com/. By using this feature, you agree to our use of this data and recognize its purpose as a guideline rather than a definitive investment strategy`,
  },
  {
    question: `What are the differences between Operating Companies, Holding Companies, Trusts and Family Foundations?`,
    answer: `<u>Operating Company</u> 
    An operating company is a business entity that is primarily engaged in carrying out day-to-day operations and generating revenue through its core business activities. It is the main entity that conducts business operations, produces goods or services, and interacts with customers, suppliers, and other stakeholders. Unlike a holding company, which primarily owns and controls other companies, an operating company is focused on the direct operation and management of its own business.
    
    <u>Holding Company</u> 
    A holding company is a type of business entity that primarily exists to own and control other companies or assets, typically through the ownership of their shares or equity. The main purpose of a holding company is to manage and control the strategic direction and financial activities of its subsidiaries. By holding the ownership of multiple companies, a holding company can enjoy benefits such as centralized management, tax planning, and asset protection.
    
    <u>Trust</u>
    A trust is a legal arrangement where one party, known as the settlor, transfers assets to another party, known as the trustee, who holds and manages those assets for the benefit of a third party, known as the beneficiary. Trusts are commonly used for various purposes, including wealth management, estate planning, and asset protection. They provide a structure to ensure the proper management and distribution of assets according to the terms and conditions set out in the trust agreement.
    
    <u>Family Foundation</u>
    A family foundation is a type of charitable organization established and funded by a family or an individual. It is typically created to support philanthropic endeavors and make charitable contributions to causes and organizations aligned with the family's values and goals. Family foundations are governed by a board of directors or trustees, who oversee the foundation's operations and make decisions regarding the distribution of funds. They serve as a means for families to create a lasting philanthropic legacy and have a positive impact on society.

    Please note that these definitions are general explanations, and the specific details and implications of operating companies, holding companies, trusts, and family foundations may vary depending on the jurisdiction and individual circumstances. It is always recommended to consult with legal and financial professionals for personalized advice and guidance.`,
  },
];
export const FaqPage = () => {
  const { groupId } = useParams<{ groupId: string }>();
  return (
    <Container>
      <Box sx={style.headerStyles}>
        <MoveBackButton
          backRoute={getRoutePath({
            target: 'consolidation',
            pathParams: { groupId: groupId || '' },
          })}
        />
        <Typography variant="h3" sx={style.mainheadingStyles}>
          Frequently Asked Questions
        </Typography>
      </Box>
      <Typography variant="h4" sx={style.headingStyles}>
        Getting Started
      </Typography>
      {gettingStartedFaq.map((data, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary
              sx={style.accordianSummaryStyle}
              expandIcon={
                <>
                  <AddIcon
                    sx={style.dropdownIconStyle}
                    className="collapsIconWrapper"
                  />
                  <RemoveIcon
                    sx={style.dropdownIconStyle}
                    className="expandIconWrapper"
                  />
                </>
              }
            >
              <Typography>{data.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: data.answer.replace(/\n/g, '<br />'),
                }}
              ></Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Typography variant="h4" sx={style.headingStyles}>
        Using MyFO
      </Typography>
      {usingMyFoFaq.map((data, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary
              sx={style.accordianSummaryStyle}
              expandIcon={
                <>
                  <AddIcon
                    sx={style.dropdownIconStyle}
                    className="collapsIconWrapper"
                  />
                  <RemoveIcon
                    sx={style.dropdownIconStyle}
                    className="expandIconWrapper"
                  />
                </>
              }
            >
              <Typography>{data.question} </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: data.answer.replace(/\n/g, '<br />'),
                }}
              ></Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}

      <Typography variant="h4" sx={style.headingStyles}>
        Security and Privacy
      </Typography>
      {securityAndPrivacyFaq.map((data, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary
              sx={style.accordianSummaryStyle}
              expandIcon={
                <>
                  <AddIcon
                    sx={style.dropdownIconStyle}
                    className="collapsIconWrapper"
                  />
                  <RemoveIcon
                    sx={style.dropdownIconStyle}
                    className="expandIconWrapper"
                  />
                </>
              }
            >
              <Typography>{data.question} </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: data.answer.replace(/\n/g, '<br />'),
                }}
              ></Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}

      <Typography variant="h4" sx={style.headingStyles}>
        Support
      </Typography>
      {supportFaq.map((data, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary
              sx={style.accordianSummaryStyle}
              expandIcon={
                <>
                  <AddIcon
                    sx={style.dropdownIconStyle}
                    className="collapsIconWrapper"
                  />
                  <RemoveIcon
                    sx={style.dropdownIconStyle}
                    className="expandIconWrapper"
                  />
                </>
              }
            >
              <Typography>{data.question} </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: data.answer.replace(/\n/g, '<br />'),
                }}
              ></Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}

      <Typography variant="h4" sx={style.headingStyles}>
        Financial Understanding
      </Typography>
      {financialUnderstandingFaq.map((data, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary
              sx={style.accordianSummaryStyle}
              expandIcon={
                <>
                  <AddIcon
                    sx={style.dropdownIconStyle}
                    className="collapsIconWrapper"
                  />
                  <RemoveIcon
                    sx={style.dropdownIconStyle}
                    className="expandIconWrapper"
                  />
                </>
              }
            >
              <Typography>{data.question} </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: data.answer.replace(/\n/g, '<br />'),
                }}
              ></Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Container>
  );
};
