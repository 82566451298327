/**
 *
 * Asynchronously loads the component for ViewItemPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ViewItemPage = lazyLoad(
  () => import('./index'),
  module => module.ViewItemPage,
);
