export default {
  bodyStyles: {
    minHeight: '450px',
    overflowY: 'auto',
  },
  boxStyles: {
    width: '100%',
  },
  paginationStyles: {
    '& td': {
      border: 0,
    },
  },
  muiSelectStyles: {
    '> .MuiSelect-select': {
      // next to [5, 10, 15]
      fontSize: '14px',
      marginLeft: '-10px',
      '&:focus': {
        background: 'transparent',
        boxShadow: 'none',
      },
    },
  },
  tablePaginationStyles: {
    '& .MuiTablePagination-displayedRows': {
      fontSize: '12px',
      color: 'text.secondary',
      marginRight: '-10px', // fyi) 1-5 of total
    },
    '& 	.MuiTablePagination-actions > button': {
      padding: '8px 0px', // fyi) < & > buttons
    },
  },
};
