/**
 *
 * InputError
 *
 */
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';

import { InfoOutlined } from '@mui/icons-material';

interface Props {
  message: string;
}

export function InputError(props: Props) {
  const { message } = props;
  const { t } = useTranslation();

  return message ? (
    <Stack
      sx={{
        color: 'error',
        alignItems: 'flex-start',
      }}
      mt={1}
    >
      <InfoOutlined width="12px" height="12px" style={{ marginRight: '4px' }} />
      <Typography variant="subtitle2">{capitalize(t(message))}</Typography>
    </Stack>
  ) : null;
}
