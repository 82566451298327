import { Box, Link, Typography } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from 'react-i18next';
import { muiTheme } from 'styles/muiTheme';
import { capitalize } from '@mui/material/utils';
import style from './style';

export function CheckEmailMessage() {
  const { t } = useTranslation();
  return (
    <Box>
      <Box
        sx={{
          ...muiTheme.variants.boxStyles,
          textAlign: style.textAlignBox,
        }}
      >
        <Box sx={style.mbBox}>
          <Box sx={style.wBox} />
          <MailOutlineIcon sx={muiTheme.variants.iconStyles} />
        </Box>
        <Typography
          variant="h5"
          sx={muiTheme.variants.mbTextPrimary}
          data-test="check-email-message-check-email"
        >
          {capitalize(t('checkEmailMessage.header.checkYourEmail'))}
        </Typography>
        <Typography
          sx={{ ...muiTheme.variants.secondaryLabel, ...style.mbTextSecondary }}
        >
          {capitalize(
            t('checkEmailMessage.helperText.weSentPasswordInstructionsTo'),
          )}
        </Typography>
        <Typography
          sx={{
            ...muiTheme.variants.secondaryLabel,
            ...style.mbTextTertiary,
          }}
        >
          {capitalize(t('checkEmailMessage.helperText.yourEmail'))}
        </Typography>
        <Box>
          <Typography variant="h7">
            {capitalize(t('checkEmailMessage.helperText.didntReceiveEmail'))}
          </Typography>
          <Typography variant="h7">
            {t('or')}
            &nbsp;
            <Link
              sx={muiTheme.variants.primaryLink}
              data-test="check-email-message-resend-instructions"
              href="/uss/forgot-password"
              color="primary"
            >
              {capitalize(
                t('createPasswordForm.helperText.resendResetInstructions'),
              )}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
