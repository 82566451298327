/* eslint-disable */
import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  UseFormRegister,
  Control,
  UseFormWatch,
  UseFormSetValue,
  UseFormUnregister,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { Box, Typography, IconButton, LinearProgress } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Close } from '@mui/icons-material';

import { BaseContext } from 'app/features/AssetLiabilitySlice/types';
import {
  reactDropZoneStyle,
  dropzoneTextContainer,
  fileDescriptionContainer,
  iconContainer,
  VisuallyHiddenInput,
  filesContainer,
  filesIconContainer,
  fileDescription,
} from './style';
import { bytesToSize } from './utils';
import { statusColors } from './constants';

export interface PropsForm {
  register: UseFormRegister<BaseContext>;
  unregister: UseFormUnregister<BaseContext>;
  control: Control<BaseContext>;
  watch: UseFormWatch<BaseContext>;
  setValue: UseFormSetValue<BaseContext>;
  errors: Record<string, any>;
  name: string;
  resetField: (targetField: any) => void;
  onRead?: (data: any) => void;
  fileType?: string;
  disclaimer?: string;
  accept?: any;
}

export const UploadFile: React.FC<PropsForm> = ({
  setValue,
  name,
  register,
  unregister,
  resetField,
  onRead,
  disclaimer,
  fileType = 'CSV',
  accept = '',
}) => {
  const { t } = useTranslation();

  const progress = 100;
  const [status, setStatus] = useState('LOADING');
  const [files, setFiles] = useState<File[] | null>(null);

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setStatus('COMPLETE');
    setFiles(acceptedFiles);

    const reader = new FileReader();
    reader.onload = () => {
      const data = {
        name: file?.name,
        size: file?.size,
        type: file?.type,
        result: reader.result,
        file,
      };
      setValue(name, data);
      onRead?.(data);
    };
    reader.readAsText(file);
  };

  const { fileRejections, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      onDrop,
      accept,
    });

  useEffect(() => {
    register(name);

    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  const onClose = () => {
    resetField(name);
    setFiles(null);
  };

  const style = useMemo(
    () => ({
      ...reactDropZoneStyle,
      ...(isDragActive ? { backgroundColor: '#e8f5e9' } : {}),
    }),
    [isDragActive],
  );

  return (
    <>
      <Box {...getRootProps({ style })}>
        <VisuallyHiddenInput type="file" {...getInputProps()} />
        <Box sx={iconContainer}>
          <UploadFileIcon color="primary" fontSize="large" />
        </Box>

        <Box sx={dropzoneTextContainer}>
          <Typography variant="body1" mr={1}>
            {capitalize(t('uploadFile.description'))}
          </Typography>
          <Typography
            variant="bodyAccent"
            color="primary.main"
            fontWeight="bold"
          >
            {capitalize(t('uploadFile.click'))}
          </Typography>
        </Box>
        <Typography variant="body2" color="secondary.main">
          {disclaimer
            ? disclaimer
            : capitalize(t('uploadFile.files', { fileType }))}
        </Typography>
        <>
          {fileRejections.length > 0 && (
            <Typography
              variant="bodyAccent"
              color="error.main"
              fontWeight="bold"
              mt={2}
            >
              {t('uploadFile.fileNotSupported')}
            </Typography>
          )}
        </>
      </Box>
      {files?.length ? (
        <Box sx={filesContainer}>
          <>
            <Box sx={fileDescriptionContainer}>
              <Box
                sx={{
                  ...filesIconContainer,
                  backgroundColor: statusColors[status].uploadBox,
                }}
              >
                <UploadFileIcon
                  color={statusColors[status].icon}
                  fontSize="medium"
                />
              </Box>
              <Box sx={fileDescription}>
                <Typography variant="body2">{files[0].name}</Typography>
                <Typography
                  variant="body2"
                  color={statusColors[status].text}
                  mb={2}
                >
                  {bytesToSize(files[0].size)} • {status}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  color={statusColors[status].progress}
                  value={progress}
                />
              </Box>
            </Box>

            <IconButton onClick={onClose}>
              <Close color={statusColors[status].icon} />
            </IconButton>
          </>
        </Box>
      ) : null}
    </>
  );
};
