import {
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Content } from './Content';
import * as styles from './styles';

type Props = {
  isSavedScenario?: boolean;
  title: string;
  buttonText: string;
  emptyText: string;
  hasSimulation?: boolean;
  data?: any; //TODO: Fix the types here
  toggleModal?: () => void;
};

export const Simulation = ({
  isSavedScenario,
  data,
  title,
  buttonText,
  emptyText,
  toggleModal,
}: Props) => {
  const hasData = Array.isArray(data) || data?.name;

  return (
    <Grid id="simulator-container" sx={styles.simulatorContainer}>
      <Grid sx={styles.header}>
        <Typography variant="h6" fontWeight="700" color="text.secondary">
          {title}
        </Typography>
        {!isSavedScenario && !hasData ? (
          <Button onClick={toggleModal} sx={styles.button}>
            {buttonText}
          </Button>
        ) : null}
      </Grid>

      <Grid sx={styles.container}>
        <>
          {hasData ? (
            <>
              {Array.isArray(data) ? (
                <>
                  {data.length > 1 ? (
                    <Grid id="container" sx={styles.rowsContainer}>
                      {data?.map(scenario => (
                        <Accordion key={scenario?.name}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography fontWeight="700">
                              {scenario?.name}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Content dataRows={scenario?.rows} />
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Grid>
                  ) : (
                    <>
                      {data.length > 0 ? (
                        <Grid id="container" sx={styles.rowsContainer}>
                          <Grid id="row-item" sx={styles.rowItem}>
                            <Typography fontWeight="700">
                              {data[0]?.name}
                            </Typography>
                          </Grid>
                          <Content dataRows={data[0]?.rows} />
                        </Grid>
                      ) : null}
                    </>
                  )}
                </>
              ) : (
                <Grid id="container" sx={styles.rowsContainer}>
                  <Grid id="row-item" sx={styles.rowItem}>
                    <Typography fontWeight="700">{data?.name}</Typography>
                  </Grid>
                  <Content dataRows={data?.rows} />
                </Grid>
              )}
            </>
          ) : (
            <Typography variant="h6" sx={styles.noDataMessage}>
              {emptyText}
            </Typography>
          )}
        </>
      </Grid>
    </Grid>
  );
};
