import { muiTheme } from 'styles/muiTheme';

export default {
  errorToastStyles: {
    fontSize: 11,
    fontFamily: 'RobotoMedium',
    color: muiTheme.palette.error.contrast,
    background: muiTheme.palette.error.main,
    zIndex: 999,
  },
};
