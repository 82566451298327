import { muiTheme } from 'styles/muiTheme';

export default {
  successToastStyles: {
    fontSize: 11,
    fontFamily: 'RobotoMedium',
    color: muiTheme.palette.success.contrast,
    background: muiTheme.palette.success.main,
    zIndex: 999,
  },
};
