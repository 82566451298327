import { RegisterOptions, ValidationValueMessage } from 'react-hook-form';

export const getRequiredRuleValue = ({
  otherRules,
  disabled,
  defaultValue = true,
}: {
  otherRules?: RegisterOptions;
  disabled?: boolean;
  defaultValue?: boolean;
}): boolean => {
  if (disabled) return false;
  if (!otherRules || otherRules.required === undefined) return defaultValue;
  if (typeof otherRules.required === 'object') {
    return Boolean((otherRules.required as ValidationValueMessage).value);
  } else {
    return Boolean(otherRules.required);
  }
};
