import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { Stack, Box, Container } from '@mui/material';
import { useGroupSlice } from 'app/features/GroupSlice';
import {
  selectGroupTypes,
  selectGroupTypesError,
} from 'app/features/GroupSlice/selectors';
// import { GroupFormData } from 'app/features/GroupSlice/types';
import { Button, ErrorToast, Input, Select } from 'app/components';
import { InfoOutlined } from '@mui/icons-material';

interface Props {
  isFetching: boolean;
  onSubmit: (e) => unknown;
}

export function CreateGroupForm({ isFetching, onSubmit }: Props) {
  const { actions } = useGroupSlice();
  const dispatch = useDispatch();
  const groupTypes = useSelector(selectGroupTypes);
  const groupTypesError = useSelector(selectGroupTypesError);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.getGroupTypes());
  }, [actions, dispatch]);

  useEffect(() => {
    reset({ groupType: 'FAMILY_FOUNDATION' });
  }, [reset, groupTypes]);

  const groupTypesOptions = useMemo(() => {
    return groupTypes
      ? groupTypes.map(item => {
          return { label: item.name, value: item.typeId };
        })
      : [];
  }, [groupTypes]);

  return (
    <>
      <ErrorToast
        isError={!!groupTypesError}
        errorText={groupTypesError ? groupTypesError.message : ''}
      />
      <Container sx={{ p: '3px' }} onSubmit={handleSubmit(onSubmit)}>
        <Stack mx={-2} mb={3}>
          <Box width={1 / 2} px={2}>
            <Input
              id="groupName"
              label="Group Name"
              isError={Boolean(errors.groupName)}
              helperText={
                errors.groupName && capitalize(t('thisFieldIsRequired'))
              }
              HelperTextIcon={InfoOutlined}
              {...register('groupName', { required: true })}
            />
          </Box>
        </Stack>
        <Stack mx={-2} mb={3}>
          <Box width={1 / 2} px={2}>
            <Select
              id="groupType"
              label="Group Type"
              options={groupTypesOptions}
              {...register('groupType', { required: true })}
            >
              {groupTypes &&
                groupTypes?.map((item, index) => (
                  <option key={index} value={item.typeId}>
                    {item.name}
                  </option>
                ))}
            </Select>
          </Box>
        </Stack>
        <Stack mx={-2} mb={3}>
          <Box width={1 / 3} px={2}>
            <Button
              id="submitButton"
              type={'submit'}
              variant="text"
              disabled={isValid}
            >
              {capitalize(t('createGroupForm.button.createGroup'))}
            </Button>
          </Box>
        </Stack>
      </Container>
    </>
  );
}
