import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { HoldingReadingInterface } from '../AssetLiabilitySlice/types';
import { groupSaga } from './saga';
import { GroupCalculations, GroupData, GroupState, SharedItem } from './types';

const emptyGroupCalculations: GroupCalculations = {
  available_cash: '0',
  available_leverage: '0',
  available_liquidity: '0',
  annualReturn: 0,
  totalCommitment: '0',
  remainingCommitment: '0',
  quarterlyReturn: 0,
  total_net_worth: '0',
};

export const initialState: GroupState = {
  groups: [],
  groupIds: [],
  isFetching: false,
  isCreatingGroup: false,
  isSuccess: false,
  isCreateSuccess: false,
  loaded: false,
  serviceError: null,
  currentGroupId: undefined,
  assetsAverages: {
    all: [],
    current: [],
  },
  groupTypes: null,
  groupTypesIsFetching: false,
  groupTypesIsSuccess: false,
  groupTypesError: null,
  groupCalculations: {
    all: emptyGroupCalculations,
    current: emptyGroupCalculations,
  },
  stakeholders: null,
  stakeholdersGroupId: null,
  stakeholdersIsFetching: false,
  stakeholdersIsSuccess: false,
  stakeholdersError: null,
  stakeholderData: null,
  addStakeholderIsFetching: false,
  addStakeholderIsSuccess: false,
  addStakeholderError: null,
  editStakeholderIsFetching: false,
  editStakeholderIsSuccess: false,
  editStakeholderError: null,
  updateStakeholderIsFetching: false,
  updateStakeholderIsSuccess: false,
  updateStakeholderError: null,
  deleteStakeholderIsFetching: false,
  deleteStakeholderIsSuccess: false,
  deleteStakeholderError: null,
  stakeholderAssets: null,
  stakeholderAssetsIsFetching: false,
  stakeholderAssetsIsSuccess: false,
  stakeholderAssetsError: null,
  stakeholderLiabilities: null,
  stakeholderLiabilitiesIsFetching: false,
  stakeholderLiabilitiesIsSuccess: false,
  stakeholderLiabilitiesError: null,
};

const slice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    // Create Group
    createGroup(
      state,
      action: PayloadAction<{
        groupType: string;
        groupName: string;
        groupStakePercentage: number;
      }>,
    ) {
      state.isFetching = true;
      state.isCreatingGroup = true;
      state.isCreateSuccess = false;
      state.formData = action.payload;
      state.serviceError = null;
    },
    createGroupSuccess(state) {
      state.isFetching = false;
      state.isCreateSuccess = true;
      state.isCreatingGroup = false;
      state.serviceError = null;
    },

    // Get Groups
    loadGroupData(state) {
      state.isFetching = true;
      state.isSuccess = false;
      state.serviceError = null;
      state.loaded = false;
    },
    loadGroupDataSuccess(state, action: PayloadAction<GroupData[]>) {
      state.isFetching = false;
      state.isSuccess = true;
      state.loaded = true;
      state.serviceError = null;
      state.groups = action.payload;
    },
    loadGroupDataFailure(state, action: PayloadAction<Error>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.loaded = false;
      state.serviceError = action.payload;
    },
    setGroupAverages(
      state,
      action: PayloadAction<{
        all: HoldingReadingInterface[];
        current: HoldingReadingInterface[];
      }>,
    ) {
      state.assetsAverages = action.payload;
    },
    setGroupCalculations(
      state,
      action: PayloadAction<{
        all: GroupCalculations;
        current: GroupCalculations;
      }>,
    ) {
      state.groupCalculations = action.payload;
    },

    // Edit Group
    editGroup(
      state,
      action: PayloadAction<{
        groupType: string;
        groupName: string;
        groupStakePercentage: number;
        groupAdmin?: string;
      }>,
    ) {
      state.isFetching = true;
      state.isSuccess = false;
      state.formData = action.payload;
      state.serviceError = null;
    },
    setEditGroupSuccess(state) {
      state.isFetching = false;
      state.isSuccess = true;
      state.serviceError = null;
    },

    // Delete Group
    deleteGroup(state, action: PayloadAction<string>) {
      state.isFetching = true;
      state.isSuccess = false;
      state.formData = {
        groupId: action.payload,
        groupStakePercentage: 0,
        groupName: '',
        groupType: '',
      };
      state.serviceError = null;
    },

    setServiceError(state, action: PayloadAction<{ message: string }>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.serviceError = action.payload;
      state.isCreatingGroup = false;
    },
    cleanupGroupOperation(state) {
      state.isFetching = false;
      state.isSuccess = false;
      state.serviceError = null;
      state.formData = undefined;
    },

    // Get Group Types
    getGroupTypes(state) {
      state.groupTypesIsFetching = true;
      state.groupTypesError = null;
    },
    getGroupTypesSuccess(state, action: PayloadAction<any>) {
      state.groupTypesIsFetching = false;
      state.groupTypesIsSuccess = true;
      state.groupTypes = action.payload;
      state.groupTypesError = null;
    },
    getGroupTypesError(state, action: PayloadAction<any>) {
      state.groupTypesIsFetching = false;
      state.groupTypesIsSuccess = false;
      state.groupTypesError = action.payload;
    },

    getStakeholders(state, action: PayloadAction<any>) {
      state.stakeholdersIsFetching = true;
      state.stakeholdersGroupId = action.payload;
    },
    getStakeholdersSuccess(state, action: PayloadAction<any>) {
      state.stakeholdersIsFetching = false;
      state.stakeholdersIsSuccess = true;
      state.stakeholders = action.payload;
    },
    getStakeholdersError(state, action: PayloadAction<any>) {
      state.stakeholdersIsFetching = false;
      state.stakeholdersIsSuccess = false;
      state.stakeholdersError = action.payload;
    },

    addStakeholder(state, action: PayloadAction<any>) {
      state.addStakeholderIsFetching = true;
      state.addStakeholderError = null;
      state.stakeholderData = action.payload;
    },
    addStakeholderSuccess(state) {
      state.addStakeholderIsFetching = false;
      state.addStakeholderIsSuccess = true;
      state.addStakeholderError = null;
    },
    addStakeholderError(state, action: PayloadAction<any>) {
      state.addStakeholderIsFetching = false;
      state.addStakeholderIsSuccess = false;
      state.addStakeholderError = action.payload;
    },
    editStakeholder(state, action: PayloadAction<any>) {
      state.editStakeholderIsFetching = true;
      state.editStakeholderError = null;
      state.stakeholderData = action.payload;
    },
    editStakeholderSuccess(state) {
      state.editStakeholderIsFetching = false;
      state.editStakeholderIsSuccess = true;
      state.editStakeholderError = null;
    },
    editStakeholderError(state, action: PayloadAction<any>) {
      state.editStakeholderIsFetching = false;
      state.editStakeholderIsSuccess = false;
      state.editStakeholderError = action.payload;
    },
    updateStakeholder(state, action: PayloadAction<any>) {
      state.updateStakeholderIsFetching = true;
      state.editStakeholderError = null;
      state.stakeholderData = action.payload;
    },
    updateStatusStakeholder(
      state,
      action: PayloadAction<{ accepted: boolean; sharedItem?: SharedItem }>,
    ) {
      state.updateStakeholderIsFetching = true;
      state.editStakeholderError = null;
    },
    updateStakeholderSuccess(state) {
      state.updateStakeholderIsFetching = false;
      state.updateStakeholderIsSuccess = true;
      state.updateStakeholderError = null;
    },
    updateStakeholderError(state, action: PayloadAction<any>) {
      state.updateStakeholderIsFetching = false;
      state.updateStakeholderIsSuccess = false;
      state.updateStakeholderError = action.payload;
    },
    deleteStakeholder(state, action: PayloadAction<any>) {
      state.deleteStakeholderIsFetching = true;
      state.deleteStakeholderError = null;
      state.stakeholderData = action.payload;
    },
    deleteStakeholderSuccess(state) {
      state.deleteStakeholderIsFetching = false;
      state.deleteStakeholderIsSuccess = true;
      state.deleteStakeholderError = null;
    },
    deleteStakeholderError(state, action: PayloadAction<any>) {
      state.deleteStakeholderIsFetching = false;
      state.deleteStakeholderIsSuccess = false;
      state.deleteStakeholderError = action.payload;
    },
    cleanupCreateStakeholder(state) {
      state.addStakeholderIsFetching = false;
      state.addStakeholderIsSuccess = false;
      state.addStakeholderError = null;
    },
    cleanupCurrentStakeholderActions(state) {
      state.addStakeholderIsFetching = false;
      state.addStakeholderIsSuccess = false;
      state.addStakeholderError = null;

      state.editStakeholderIsFetching = false;
      state.editStakeholderIsSuccess = false;
      state.editStakeholderError = null;

      state.deleteStakeholderIsFetching = false;
      state.deleteStakeholderIsSuccess = false;
      state.deleteStakeholderError = null;

      state.stakeholderData = null;
    },
    setGroupIds(state, action: PayloadAction<GroupState['groupIds']>) {
      state.groupIds = action.payload;
    },
    setGroups(state, action: PayloadAction<GroupState['groups']>) {
      state.groups = action.payload;
      state.isFetching = false;
    },
    setCurrentGroupId(
      state,
      action: PayloadAction<GroupState['currentGroupId']>,
    ) {
      state.currentGroupId = action.payload || state.currentGroupId;
    },
    getStakeholderAssets(state) {
      state.stakeholderAssetsIsFetching = true;
      state.stakeholderAssetsError = null;
    },
    getStakeholderAssetsError(state, action: PayloadAction<any>) {
      state.stakeholderAssetsIsFetching = false;
      state.stakeholderAssetsIsSuccess = false;
      state.stakeholderAssetsError = action.payload;
    },
    getStakeholderAssetsSuccess(state, action: PayloadAction<any>) {
      state.stakeholderAssetsIsFetching = false;
      state.stakeholderAssetsIsSuccess = true;
      state.stakeholderAssetsError = null;
      state.stakeholderAssets = action.payload;
    },
    getStakeholderLiabilities(state) {
      state.stakeholderLiabilitiesIsFetching = true;
      state.stakeholderLiabilitiesError = null;
    },
    getStakeholderLiabilitiesError(state, action: PayloadAction<any>) {
      state.stakeholderLiabilitiesIsFetching = false;
      state.stakeholderLiabilitiesIsSuccess = false;
      state.stakeholderLiabilitiesError = action.payload;
    },
    getStakeholderLiabilitiesSuccess(state, action: PayloadAction<any>) {
      state.stakeholderLiabilitiesIsFetching = false;
      state.stakeholderLiabilitiesIsSuccess = true;
      state.stakeholderLiabilitiesError = null;
      state.stakeholderLiabilities = action.payload;
    },
  },
});

export const { actions: groupActions, reducer } = slice;

export const useGroupSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: groupSaga });
  return { actions: slice.actions };
};
