import { createSlice, current } from '@reduxjs/toolkit';
import {
  extractCalcFromItems,
  filterGroupDataByAssetId,
  formatBuyData,
  formatItemAPI,
  createLiabilityAPI,
  formatSellData,
} from './utils';
import {
  getItemAverages,
  groupItemsByType,
  sortGroupItems,
} from 'utils/assetValues';
import { PaymentMethods } from 'app/pages/scenario-modeling/types';
import { BuyScenario, InitialState, SellScenario } from './types';

const initialState: InitialState = {
  isLoading: false,
  currentScenario: {
    title: '',
    projectHoldings: null,
    assetsList: null,
    liabilitiesList: null,
    groupId: '',
    groupData: null,
    currencyState: null,
    sellScenario: null,
    buyScenario: null,
    benchmarkData: null,
  },
};

export const scenarioModelingSlice = createSlice({
  name: 'scenarioModeling',
  initialState,
  reducers: {
    initScenario: (state, action) => {
      const { groupData, groupId, currencyState } = action.payload;

      const projectHoldings = extractCalcFromItems({
        filteredGroups: groupData ? [groupData] : [],
        currencyState,
      });

      const [assetsList] = sortGroupItems([groupData]);

      const selectedGroupAssets = groupItemsByType(
        groupData?.items.filter(i => i.itemType === 'GROUP_ASSET') || [],
        true,
      );

      const benchmarkData = getItemAverages({
        data: selectedGroupAssets,
        groups: groupData ? [groupData] : [],
      });

      state.currentScenario = {
        ...state.currentScenario,
        groupId,
        groupData,
        projectHoldings,
        assetsList,
        benchmarkData,
        currencyState,
        sellScenario: null,
        buyScenario: null,
      };
    },
    sellScenario: (state, action) => {
      const asset = action.payload.asset;
      /*  ========GET CURRENT STATE ======== */
      const currentState = current(state);
      const currentGroupData = { ...currentState.currentScenario.groupData };
      const currencyState = { ...currentState.currentScenario.currencyState };

      /*  ======== SELL ASSET LOGIC ======== */
      const groupData = filterGroupDataByAssetId(currentGroupData, asset?._id);

      const [assetsList] = sortGroupItems([groupData]);
      // 1.- Add asset here on calculations ...
      const selectedGroupAssets = groupItemsByType(
        groupData?.items.filter(i => i.itemType === 'GROUP_ASSET') || [],
        true,
      );
      const benchmarkData = getItemAverages({
        data: selectedGroupAssets,
        groups: [groupData],
      });

      const sellScenario = [formatSellData(asset)] as SellScenario[];

      /*  ======== CALC ASSETS ======== */
      const projectHoldings = extractCalcFromItems({
        filteredGroups: [groupData],
        currencyState,
        soldAssets: sellScenario,
      });

      state.currentScenario = {
        ...currentState.currentScenario,
        groupData,
        projectHoldings,
        assetsList,
        benchmarkData,
        currencyState,
        sellScenario,
      };
    },
    buyScenario: (state, action) => {
      const { buyAsset, sellAssets } = action.payload;
      /*  ========GET CURRENT STATE ======== */
      const currentState = current(state);
      const currentScenario = { ...currentState.currentScenario };
      const currentGroupData = { ...currentState.currentScenario.groupData };
      const currencyState = { ...currentState.currentScenario.currencyState };
      /*  ======== SELL ASSET LOGIC ======== */
      let sellScenario = [] as SellScenario[];

      /* If there is current scenario push it to the array */
      if (currentScenario.sellScenario) {
        sellScenario = [...currentScenario.sellScenario];
      }

      const remainingAmount = buyAsset?.baseContext?.remainingAmount;
      const paymentMethod = buyAsset?.baseContext?.paymentMethod;
      const formattedAsset = formatItemAPI(buyAsset);
      const formattedLiability = createLiabilityAPI(buyAsset);

      /*  Add the formatted asset */
      let groupData = {
        ...currentGroupData,
        items: [...currentGroupData.items, formattedAsset],
      };

      /*  Add the liability if the the payment method is LOAN */
      if (paymentMethod === PaymentMethods.LOAN) {
        groupData = {
          ...groupData,
          items: [...groupData.items, formattedLiability],
        };
      }

      /* Filtering the sellAssets  */
      for (const asset of sellAssets) {
        const formattedData = formatSellData(asset) as SellScenario;
        sellScenario.push(formattedData);
        groupData = filterGroupDataByAssetId(currentGroupData, asset?._id);
      }

      const [assetsList] = sortGroupItems([groupData]);

      const selectedGroupAssets = groupItemsByType(
        groupData?.items.filter(i => i.itemType === 'GROUP_ASSET') || [],
        true,
      );
      const benchmarkData = getItemAverages({
        data: selectedGroupAssets,
        groups: [groupData],
      });
      /*  Set the new current scenario */

      const buyScenario = formatBuyData(buyAsset) as BuyScenario;

      /*  Calc project Holdings and get the new assets list */
      const projectHoldings = extractCalcFromItems({
        filteredGroups: [groupData],
        currencyState,
        remainingAmount,
        paymentMethod,
        soldAssets: sellScenario,
      });

      state.currentScenario = {
        ...currentState.currentScenario,
        groupData,
        projectHoldings,
        assetsList,
        benchmarkData,
        currencyState,
        buyScenario,
        sellScenario,
      };
    },
  },
});

export const { initScenario, sellScenario, buyScenario } =
  scenarioModelingSlice.actions;

export default scenarioModelingSlice.reducer;
