import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemButton,
} from '@mui/material';
import { Clear, Search } from '@mui/icons-material';
import { SearchResult } from 'app/pages/vault/models/vault.types';
import * as styles from './styles';

type Props = {
  onSearch: (term: string) => void;
  onClick: (item: SearchResult) => void;
  results: SearchResult[];
};

const SearchBar = ({ onSearch, onClick, results }: Props) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [isBoxVisible, setIsBoxVisible] = useState(true);

  const handleSearch = e => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  const clearSearch = () => {
    setSearchTerm('');
    onSearch('');
    setIsBoxVisible(false);
  };

  const handleClick = result => {
    onClick(result);
    setIsBoxVisible(false);
  };

  const handleFocus = () => {
    setIsBoxVisible(true);
  };

  return (
    <FormControl sx={styles.searchInput} variant="outlined">
      <OutlinedInput
        id="outlined-adornment-search"
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        onFocus={handleFocus}
        startAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
        endAdornment={
          searchTerm ? (
            <InputAdornment position="end">
              <IconButton onClick={clearSearch}>
                <Clear />
              </IconButton>
            </InputAdornment>
          ) : null
        }
      />
      {searchTerm && isBoxVisible && (
        <Box sx={styles.searchResults}>
          {results && results.length > 0 ? (
            <List>
              {results.map((result, index) => (
                <ListItem key={index} disablePadding sx={{ height: '36px' }}>
                  <ListItemButton onClick={() => handleClick(result)}>
                    <ListItemText primary={result.item.fileName} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography sx={{ p: 2 }} variant="body2" color="textSecondary">
              {t('vault.labels.noResults', {
                searchTerm,
              })}
            </Typography>
          )}
        </Box>
      )}
    </FormControl>
  );
};

export default SearchBar;
