import { palette } from '../palette';

export const light = {
  ...palette,
  primary: {
    ...palette.primary,
    main: '#09164B',
    dark: '#061133',
    medium: '#08143E',
    light: '#0A1752',
    contrast: '#FFFFFF',
    back: '#2C3C3D',
    '4p': '#09164B0A',
    '8p': '#09164B14',
    '12p': '#09164B1F',
    '30p': '#09164B4D',
    '50p': '#09164B80',
    '160p': '#0E2378',
  },
  secondary: {
    ...palette.secondary,
    main: '#852A28',
    dark: '#5F1E1E',
    light: '#95322E',
    contrast: '#FFFFFF',
    '4p': '#852A280A',
    '8p': '#852A2814',
    '12p': '#852A281F',
    '30p': '#852A284D',
    '50p': '#852A2880',
    '160p': '#D54340',
  },
  info: {
    ...palette.info,
    main: '#FFFFFF',
  },
  other: {
    ...palette.other,
    outlineBorder: '#DBD9E0',
  },
  addition: {
    ...palette.addition,
    entityPersonalWrapperBackgroundColor: '#09164B',
  },
};
