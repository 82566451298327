import { muiTheme } from 'styles/muiTheme';

export default {
  boxContainerStyles: { marginBottom: 4 },
  boxArrowIconStyles: { color: 'primary.main' },
  boxTitleStyles: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingRight: 1,
  },
  classTitleStyles: {
    color: 'text.secondary',
    fontWeight: 'bold',
  },
  valueStyles: {
    color: 'text.primary',
  },
  deleteIcon: {
    marginLeft: '8px!important',
    marginRight: '0px!important',
  },
  boxDetailsStyles: {
    borderTop: muiTheme.variants.componentBorder,
    padding: '0',
  },
  accordionSummaryStyles: muiTheme.variants.accordionSummary,
  accordionStyles: muiTheme.variants.accordion,
  contentLiStyles: {
    display: 'flex',
    padding: 0,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 16px',
  },
  deleteButton: {
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: muiTheme.palette.text.disabled, //#10182061
    boxShadow: 'none',
    color: muiTheme.palette.text.disabled, //#10182061
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
};
