import axios, { AxiosResponse } from 'axios';
import { getMimeType } from 'utils/files';
import { apiSlice } from '../../../features/api/apiSlice';
import { getHeaders } from 'utils/http';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  GetFilesResponse,
  AddFilePayload,
  UpdateFilePayload,
  PayloadResponse,
} from 'app/pages/vault/models/service.types';
import { VaultFile } from 'app/pages/vault/models/vault.types';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getFilesByGroup: builder.query<PayloadResponse<VaultFile[]>, string>({
      query: groupId => ({
        url: `/file/${groupId}`,
        method: 'GET',
        headers: getHeaders(),
      }),
      providesTags: ['Files'],
    }),
    getFilesByGroups: builder.query<GetFilesResponse, string[]>({
      queryFn: async (groupIds, _queryApi, _extraOptions, fetchWithBQ) => {
        try {
          const allFilesResult = await Promise.all(
            groupIds.map(
              async (
                groupId,
              ): Promise<{
                response: PayloadResponse<VaultFile[]>;
                groupId: string;
              }> => {
                const result = await fetchWithBQ({
                  url: `/file/${groupId}`,
                  method: 'GET',
                  headers: getHeaders(),
                });

                if (result.error) {
                  throw result.error;
                }
                return {
                  response: result.data as PayloadResponse<VaultFile[]>,
                  groupId,
                };
              },
            ),
          );

          const resultsObject: GetFilesResponse = allFilesResult.reduce(
            (filesByGroup: GetFilesResponse, { groupId, response }) => {
              const result = {
                ...filesByGroup,
                [groupId]: response,
              };
              return result;
            },
            {},
          );

          return { data: resultsObject };
        } catch (error) {
          return { error: error as FetchBaseQueryError };
        }
      },
      providesTags: ['Files'],
    }),
    getFileById: builder.query<PayloadResponse<VaultFile>, VaultFile>({
      query: file => ({
        url: `/file/${file?.groupId}/${file?._id}`,
        method: 'GET',
        headers: getHeaders(),
      }),
      providesTags: ['Files'],
    }),
    addFile: builder.mutation<PayloadResponse<VaultFile[]>, AddFilePayload>({
      query: data => ({
        url: `/file/${data?.groupId}`,
        method: 'POST',
        body: data.payload,
        headers: getHeaders(),
      }),
      invalidatesTags: ['Files'],
    }),
    updateFile: builder.mutation<PayloadResponse<VaultFile>, UpdateFilePayload>(
      {
        query: data => ({
          url: `/file/${data?.groupId}`,
          method: 'PATCH',
          body: data.payload,
          headers: getHeaders(),
        }),
        invalidatesTags: ['Files'],
      },
    ),
    deleteFile: builder.mutation<
      PayloadResponse<{
        acknowledged: true;
        deletedCount: number;
      }>,
      VaultFile
    >({
      query: file => ({
        url: `/file/${file.groupId}/${file._id}`,
        method: 'DELETE',
        headers: getHeaders(),
      }),
      invalidatesTags: ['Files'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddFileMutation,
  useUpdateFileMutation,
  useDeleteFileMutation,
  useGetFileByIdQuery,
  useGetFilesByGroupQuery,
  useGetFilesByGroupsQuery,
} = extendedApi;

/**
 * The function `putURL` sends a PUT request to a specified URL with a file attached as multipart form
 * data.
 * @param {string} url - The `url` parameter in the `putURL` function is a string representing the
 * endpoint where you want to send the PUT request with the file data.
 * @param {File | Blob} file - The `file` parameter is a `VaultFile` or `Blob` object that you want to upload
 * to the specified URL.
 * @param {string} extension - The `extension` parameter is a string representing the file extension to
 * determine the MIME type.
 * @returns {Promise<AxiosResponse<unknown> | void>} - The function returns a Promise that resolves to an AxiosResponse
 * or void if there is an error.
 */
export async function putURL(
  url: string,
  file: File | Blob,
  extension: string,
): Promise<AxiosResponse<unknown> | void> {
  const mimeType = getMimeType(extension);

  try {
    const response = await axios.put(url, file, {
      headers: {
        'Content-Type': mimeType,
      },
    });
    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.message);
    } else {
      console.error('Unexpected error:', error);
    }
  }
}

/**
 * The function `getURL` is an asynchronous function in TypeScript that fetches data from a specified
 * URL and returns a blob URL.
 * @param {string} url - The `url` parameter in the `getURL` function is a string that represents the
 * URL of the resource you want to fetch using the `fetch` API.
 * @returns {Promise<string>} - The `getURL` function returns a Promise that resolves to a blob URL string.
 */
export async function getURL(url: string): Promise<string> {
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const blob = await response.blob();
  const blobUrl = URL.createObjectURL(blob);
  return blobUrl;
}
