/**
 *
 * Asynchronously loads the component for ModalActivateEntities
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ModalActivateEntities = lazyLoad(
  () => import('./index'),
  module => module.ModalActivateEntities,
);
