import { Grid } from '@mui/material';
import { ColumnCustomProps } from './props';

export function ColumnCustom(props: ColumnCustomProps) {
  const { gridSize, cellSx, element } = props;

  return (
    <Grid item xs={gridSize} sx={cellSx}>
      {element}
    </Grid>
  );
}
