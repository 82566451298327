export default {
  boxContainerStyles: {
    display: 'flex',
    flexWrap: 'wrap' as const, //TODO fix or find the cause of this 0-0
    marginLeft: '10px',
    // justifyContent: 'space-between',
  },
  legendBoxStyles: {
    display: 'flex',
    gap: '11px',
    minWidth: '170px',
    height: '24px',
  },
  legendStyles: {
    width: '15px',
    height: '12px',
  },
};
