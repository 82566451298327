import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import { getInstance } from 'utils/http';
import { UBE_URL } from 'utils/urls';
import { ussActions as actions } from '.';
import { defaultErrorMessage } from 'constants/error';
import { UssState } from './types';

export function* initiatePasswordReset() {
  yield delay(500);
  try {
    const instance = getInstance(UBE_URL);
    const email = yield select<(s: { uss: UssState }) => any>(
      s => s.uss.passwordResetEmail,
    );
    yield call(instance.post, '/task/reset-password', {
      email,
    });
    yield put(actions.initiatePasswordResetSuccess());
  } catch (error: Error | any) {
    yield put(
      actions.initiatePasswordResetError({
        message:
          error.message && error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* continuePasswordReset() {
  yield delay(500);
  try {
    const instance = getInstance(UBE_URL);
    const payload = yield select<(s: { uss: UssState }) => any>(
      s => s.uss.passwordResetPayload,
    );
    yield call(instance.patch, `/task/reset-password`, payload);
    yield put(actions.continuePasswordResetSuccess());
  } catch (error: Error | any) {
    yield put(
      actions.continuePasswordResetError({
        message:
          error.message && error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* systemTasksSaga() {
  yield takeLatest(actions.initiatePasswordReset.type, initiatePasswordReset);
  yield takeLatest(actions.continuePasswordReset.type, continuePasswordReset);
}
