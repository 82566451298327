import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import { getInstance } from 'utils/http';
import { OSS_URL } from 'utils/urls';
import { userTasksActions as actions } from '.';
import { toastActions } from '../ToastSlice';
import { defaultErrorMessage } from 'constants/error';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserTask } from './types';
import { t } from 'i18next';
import capitalize from '@mui/material/utils/capitalize';

export function* getAllUserTasks() {
  try {
    const instance = getInstance(OSS_URL);
    const response = yield call(instance.get, '/user-task');
    yield put(actions.getUserTasksSuccess(response.data.payload));
  } catch (error: Error | any) {
    yield put(
      actions.getUserTasksError({
        message:
          error.message && error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* getUserTasksByType() {
  yield delay(500);
  try {
    const instance = getInstance(OSS_URL);
    const typeFilter = yield select(state => state.userTasks.typeFilter);
    const response = yield call(
      instance.get,
      `/user-task?taskType=${typeFilter}`,
    );
    yield put(actions.getUserTasksSuccess(response.data.payload));
  } catch (error: Error | any) {
    yield put(
      actions.getUserTasksError({
        message:
          error.message && error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* createNewUserTask() {
  yield delay(500);
  try {
    const instance = getInstance(OSS_URL);
    const newTaskPayload = yield select(
      state => state.userTasks.newTaskPayload,
    );

    const response = yield call(instance.post, '/user-task', newTaskPayload);

    yield put(actions.newUserTasksSuccess(response.data.payload));
    yield put(actions.getAllUserTasks());
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: capitalize(t('task.manager.toast.create')),
      }),
    );
  } catch (error: Error | any) {
    yield put(
      actions.newUserTasksError({
        message:
          error.message && error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* updateUserTask({
  payload,
}: PayloadAction<{ task: UserTask; message?: string }>) {
  try {
    const instance = getInstance(OSS_URL);

    const updateTaskPayload = yield select(
      state => state.userTasks.updateUserTask,
    );
    const response = yield call(instance.put, `/user-task`, updateTaskPayload);
    yield put(actions.updateTasksSuccess(response.data.payload));
    yield put(actions.getAllUserTasks());
    yield put(
      toastActions.showSuccessToast({
        show: payload.message !== '',
        message: payload.message ?? capitalize(t('task.manager.toast.update')),
      }),
    );
  } catch (error: Error | any) {
    const errorMessage =
      error.message && error.message.length > 0
        ? error.message
        : defaultErrorMessage;
    yield put(
      toastActions.showErrorToast({
        show: true,
        message: errorMessage,
      }),
    );
    yield put(
      actions.updateTasksError({
        message: errorMessage,
      }),
    );
  }
}

export function* deleteUserTask() {
  yield delay(500);
  try {
    const instance = getInstance(OSS_URL);
    const { _id } = yield select(state => state.userTasks.deleteTaskPayload);
    const response = yield call(instance.delete, `/user-task/${_id}`);

    yield put(actions.deleteTasksSuccess(response.data.payload));
    yield put(actions.getAllUserTasks());
    yield put(
      toastActions.showSuccessToast({
        show: true,
        message: capitalize(t('task.manager.toast.delete')),
      }),
    );
  } catch (error: Error | any) {
    yield put(
      actions.deleteTasksError({
        message:
          error.message && error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      }),
    );
  }
}

export function* userTasksSaga() {
  yield takeLatest(actions.getAllUserTasks.type, getAllUserTasks);
  yield takeLatest(actions.getUserTasksByType.type, getUserTasksByType);
  yield takeLatest(actions.createNewUserTask.type, createNewUserTask);
  yield takeLatest(actions.updateUserTask.type, updateUserTask);
  yield takeLatest(actions.deleteUserTask.type, deleteUserTask);
}
