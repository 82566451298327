/**
 *
 * Asynchronously loads the component for ContinueAuth
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ContinueAuth = lazyLoad(
  () => import('./index'),
  module => module.ContinueAuth,
);
