import { defaultErrorMessage } from 'constants/error';
import { Dispatch, SetStateAction } from 'react';
import { getInstance } from 'utils/http';
import { UBE_URL } from 'utils/urls';
import { UserStateProps, OauthLoginData } from './types';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import moment from 'moment';

const UaaInstance = getInstance(UBE_URL, false);
const SUaaInstance = getInstance(UBE_URL, false, false);
//TODO remove tpm and SUaaInstance
const TpmInstance = getInstance(UBE_URL);

interface DecodedToken extends JwtPayload {
  exp: number;
}

//TODO do we want to use providers or redux?
export const useIsTokenValid = (
  userState: UserStateProps,
  setUserState: Dispatch<SetStateAction<UserStateProps>>,
): boolean => {
  try {
    const currentToken = userState.userToken || sessionStorage.getItem('token');
    if (!currentToken) return false;
    const currentTime = moment().unix();
    const decodedToken: DecodedToken = jwtDecode(currentToken);
    if (decodedToken.exp > currentTime) return true;
    else return false;
  } catch (error: any) {
    return false;
  }
};
export const useSignin = async (
  oauthLoginData: OauthLoginData,
  userState: UserStateProps,
  setUserState: Dispatch<SetStateAction<UserStateProps>>,
) => {
  setUserState({
    ...userState,
    isFetching: true,
    signinError: null,
  });
  try {
    if (!oauthLoginData.oauthToken) {
      throw new Error('Invalid Data');
    }
    const loginResponse: any = await UaaInstance.post(
      '/auth/login',
      {},
      {
        headers: {
          Authorization: 'Bearer ' + oauthLoginData.oauthToken,
        },
      },
    ).catch(({ response } = {}) => response || {});

    const { data: response, status } = loginResponse;

    if (response?.payload?.error) {
      throw new Error(response.payload.error);
    }
    if (status >= 400) {
      throw new Error(response);
    }
    if (!response?.payload) {
      throw new Error(defaultErrorMessage);
    }

    const userToken = response.payload.token;
    sessionStorage.setItem('token', userToken);
    sessionStorage.setItem('seenNewContent', 'false');

    const { data: userResponse } = await UaaInstance.get('/user');
    const user = userResponse.payload;

    localStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('token', userToken);
    sessionStorage.setItem('demographicInfo', JSON.stringify({}));

    setUserState({
      ...userState,
      isSuccess: true,
      user,
      isFetching: false,
      userToken,
      signinError: null,
    });
  } catch (error: any) {
    setUserState({
      ...userState,
      isFetching: false,
      isSuccess: false,
      signinError: {
        name: 'error',
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      },
    });
  }
};

export const useSignoff = (
  userState: UserStateProps,
  setUserState: Dispatch<SetStateAction<UserStateProps>>,
) => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('supertoken');
  sessionStorage.removeItem('userImpersonated');
  sessionStorage.removeItem('demographicInfo');
  localStorage.removeItem('user');
  // localStorage.removeItem('currencyState');
  setUserState({
    ...userState,
    isFetching: false,
    isSuccess: false,
    user: null,
    userToken: null,
    signinError: null,
  });
};

export const useClearSigninState = (
  userState: UserStateProps,
  setUserState: Dispatch<SetStateAction<UserStateProps>>,
) => {
  setUserState({
    ...userState,
    isFetching: false,
    isSuccess: false,
    signinError: null,
    formData: undefined,
  });
};

export const useClearEditState = (
  userState: UserStateProps,
  setUserState: Dispatch<SetStateAction<UserStateProps>>,
) => {
  setUserState({
    ...userState,
    isEditingUser: false,
    editUserError: null,
    editUserSuccess: false,
  });
};

export const useSetUser = (
  user: UserStateProps['user'],
  userState: UserStateProps,
  setUserState: Dispatch<SetStateAction<UserStateProps>>,
) => {
  localStorage.setItem('user', JSON.stringify(user));
  setUserState({
    ...userState,
    user,
    isSuccess: false,
  });
};

export const useGetUser = async (
  userState: UserStateProps,
  setUserState: Dispatch<SetStateAction<UserStateProps>>,
) => {
  setUserState({
    ...userState,
    isFetching: true,
    getUserError: null,
    isSuccess: false,
  });
  try {
    const { data: response } = await SUaaInstance.get('/user');
    if (response === void 0 || response === null) {
      throw new Error('not found user');
    }
    if (!response.payload) {
      throw new Error(defaultErrorMessage);
    }
    localStorage.setItem('user', JSON.stringify(response.payload));
    setUserState({
      ...userState,
      isFetching: false,
      user: response.payload,
      getUserError: null,
      isSuccess: false,
    });
  } catch (error: any) {
    setUserState({
      ...userState,
      isFetching: false,
      getUserError: {
        name: 'error',
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      },
      isSuccess: false,
    });
  }
};

export const useEditUser = async (
  updatedData: any,
  userState: UserStateProps,
  setUserState: Dispatch<SetStateAction<UserStateProps>>,
) => {
  setUserState({
    ...userState,
    isEditingUser: true,
    editUserError: null,
    editUserSuccess: false,
  });
  try {
    await SUaaInstance.patch('/user', updatedData);
    const { data: response } = await UaaInstance.get('/user');
    localStorage.setItem('user', JSON.stringify(response.payload));
    setUserState({
      ...userState,
      editUserSuccess: true,
      isEditingUser: false,
      user: response.payload,
      editUserError: null,
    });
  } catch (error: any) {
    setUserState({
      ...userState,
      editUserError: {
        name: 'error',
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      },
      isEditingUser: false,
      editUserSuccess: false,
    });
  }
};

export const useGetUserByEmail = async (
  userEmail: string,
  userState: UserStateProps,
  setUserState: Dispatch<SetStateAction<UserStateProps>>,
) => {
  setUserState({
    ...userState,
    isFetching: true,
    userEmail,
  });
  try {
    const { data: response } = await UaaInstance.get(
      `${UBE_URL}/user/email/${encodeURIComponent(userEmail)}`,
    );
    if (response === void 0 || response === null) {
      throw new Error('an error ocurred getting user');
    }
    localStorage.setItem('user', JSON.stringify(response.payload));
    setUserState({
      ...userState,
      isFetching: false,
      user: response.payload,
    });
  } catch (error: any) {
    setUserState({
      ...userState,
      isFetching: false,
      userEmailError: {
        name: 'error',
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      },
    });
  }
};

export const useValidateToken = async (
  token: any,
  userState: UserStateProps,
  setUserState: Dispatch<SetStateAction<UserStateProps>>,
) => {
  setUserState({
    ...userState,
    isFetching: true,
    isSuccess: false,
  });
  try {
    await TpmInstance.patch(`/task/validate-invitation/`, {
      taskId: sessionStorage.getItem('invitationTaskId'),
      token,
    });
    setUserState({
      ...userState,
      isFetching: false,
      validateTokenIsSuccess: true,
    });
  } catch (error: any) {
    setUserState({
      ...userState,
      isFetching: false,
      validateTokenError: {
        name: 'error',
        message:
          error.message || error.message.length > 0
            ? error.message
            : defaultErrorMessage,
      },
    });
  }
};

export const useRemindLater = (
  userState: UserStateProps,
  setUserState: Dispatch<SetStateAction<UserStateProps>>,
) => {
  const remindLaterDate = moment(new Date()).add(10, 'd').toDate().valueOf();
  localStorage.setItem('remindLaterDate', remindLaterDate.toString());
  setUserState({
    ...userState,
    remindLaterDate,
  });
};
