import Fuse from 'fuse.js';
import { Entity, FlattenEntityItem, SearchResult } from '../models/vault.types';

/**
 * Flattens a hierarchical structure of entities into a flat array.
 * Each entity in the structure can have nested children, which are also flattened.
 *
 * @param {Entity[]} data - The hierarchical array of entities to be flattened.
 * @returns {FlattenEntityItem[]} A flat array of entities with their nested children included.
 */
const flattenFiles = (data: Entity[]): FlattenEntityItem[] => {
  const result: FlattenEntityItem[] = [];

  /**
   * Recursively flattens the children of a given entity.
   *
   * @param {FlattenEntityItem[]} children - The children to flatten.
   * @returns {FlattenEntityItem[]} A flat array of the children.
   */
  const flattenChildren = (
    children: FlattenEntityItem[],
  ): FlattenEntityItem[] => {
    return children
      .map(child => {
        if (child.children && child.children.length > 0) {
          const folder: FlattenEntityItem = { ...child, type: 'folder' };
          result.push(folder);
          return flattenChildren(child.children); // Recursively flatten children
        } else {
          const file: FlattenEntityItem = { ...child, type: 'file' };
          result.push(file);
          return [file]; // Return as array to match return type
        }
      })
      .flat();
  };

  data.forEach(item => {
    if (item.group) {
      const groupObject: FlattenEntityItem = { ...item.group, type: 'group' };
      result.push(groupObject);
    }

    if (item.files && item.files.children) {
      flattenChildren(item.files.children as FlattenEntityItem[]); // Ensure it's cast to the correct type
    }
  });

  return result;
};
/**
 * Searches through a list of entities for a given search term.
 * The entities are first flattened, and then searched using Fuse.js.
 *
 * @param {Entity[]} entities - The list of entities to search through.
 * @param {string} searchTerm - The search term to match against the entities.
 * @returns {SearchResult[]} An array of search results, including the matched entities and their scores.
 */
export const searchEntities = (
  entities: Entity[],
  searchTerm: string,
): SearchResult[] => {
  const options = {
    keys: ['fileName', 'extension', 'contentType'],
    includeScore: true,
    threshold: 0.3,
    isCaseSensitive: false,
  };
  const flatten = flattenFiles(entities);
  const fuse = new Fuse(flatten, options);
  const results = fuse.search(searchTerm) as SearchResult[];
  return results;
};
