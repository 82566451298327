import { muiTheme } from 'styles/muiTheme';

export default {
  buttonSx: {
    backgroundColor: 'unset',
    fontSize: 11,
    color: muiTheme.palette.secondary.light,
    cursor: 'pointer',
    minWidth: 'unset',
    padding: '6px 8px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },

  shortcutsBoxSx: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  tooltipSx: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
  },
};
