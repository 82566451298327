import { Grid, Typography, Button } from '@mui/material';
import { Download } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as styles from './style';

export const NoPreview = ({ fileName, url }) => {
  const { t } = useTranslation();

  const downloadFile = async () => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Grid sx={styles.container}>
      <Typography variant="h6" fontWeight="bold" mb={2}>
        {t('vault.modals.previewFile.messages.noPreview')}
      </Typography>
      <Button
        sx={styles.button}
        onClick={downloadFile}
        startIcon={<Download />}
      >
        {t('vault.modals.previewFile.buttons.download')}
      </Button>
    </Grid>
  );
};
