import {
  Box,
  CircularProgress,
  Container,
  LinearProgress,
} from '@mui/material';

interface Props {
  type: 'circular' | 'linear';
  color?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  // text: string;
}

export function SuspenseSpinner(props: Props) {
  return (
    <Box m="auto">
      <Container>
        {props.type === 'circular' ? (
          <CircularProgress color={props.color || 'primary'} />
        ) : (
          <LinearProgress color={props.color || 'primary'} />
        )}
      </Container>
    </Box>
  );
}
