import moment from 'moment';
import { allCountries } from 'country-telephone-data';

export const formatPhoneNumber = (phoneNumber: string, countryCode = '1') => {
  const country = allCountries.find(c => c.dialCode === countryCode);
  if (!country || !country.format) {
    return phoneNumber;
  }

  const format = country.format;
  const formattedNumber = [countryCode, phoneNumber]
    .join('')
    .replace(/[^\d]/g, '');
  let formattedPhoneNumber = '';
  let nextDigitIndex = 0;

  for (const char of format) {
    if (char === '.' && nextDigitIndex < formattedNumber.length) {
      formattedPhoneNumber += formattedNumber[nextDigitIndex];
      nextDigitIndex++;
    } else if (char !== '.') {
      formattedPhoneNumber += char;
    }
  }

  return formattedPhoneNumber;
};

export const formatDate = (date: string) =>
  moment(date).calendar(null, {
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    nextDay: '[Tomorrow]',
    nextWeek: '[This] dddd',
    sameElse: 'MMMM DD, YYYY',
  });
