import { Dispatch, SetStateAction } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { capitalize } from '@mui/material/utils';
import { AssetClassesKeysToValues } from 'constants/keyValue';
import { Trans, useTranslation } from 'react-i18next';
import { Tooltip } from 'app/components/Tooltip/Loadable';
import style from './style';
import { TFunction } from 'i18next';

interface CalculationBarProps {
  calculationBarValues: Array<{
    className: string;
    value: number;
  }>;
  classKvDict?: Record<
    string,
    { label: string; color: string; benchmarkLabel?: string }
  >;
  setHoveredBar: Dispatch<SetStateAction<string>>;
}

export function CalculationBar({
  calculationBarValues,
  setHoveredBar,
  classKvDict = AssetClassesKeysToValues,
}: CalculationBarProps) {
  const { t } = useTranslation();

  return (
    <Stack direction="row">
      {calculationBarValues.map((item, index) => {
        const classKv = classKvDict[item.className];
        return (
          <Box
            key={index}
            height="100%"
            width={`${item.value}%`}
            onMouseOver={() => setHoveredBar(item.className)}
            onMouseLeave={() => setHoveredBar('')}
            sx={style.calculationbarStyles}
          >
            <Tooltip
              title={
                <>
                  <Typography variant="tooltip">
                    <Trans
                      i18nKey={'consolidation.tooltip.benchmarkPercentage'}
                      t={(s => capitalize(t(s))) as TFunction}
                      components={{
                        bold: <strong />,
                      }}
                      values={{
                        className: classKv?.benchmarkLabel || classKv?.label,
                        percentage: item.value,
                      }}
                    />
                  </Typography>
                </>
              }
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -60],
                    },
                  },
                ],
              }}
            >
              <Box
                height={24}
                sx={{
                  marginY: '24px',
                  backgroundColor: classKv?.color,
                  borderLeft: index === 0 ? 'unset' : '1px solid white',
                  borderRadius:
                    index === 0
                      ? calculationBarValues.length - 1 === 0
                        ? '12px'
                        : '12px 0 0 12px'
                      : index === calculationBarValues.length - 1
                        ? '0 12px 12px 0'
                        : 'unset',
                }}
              />
            </Tooltip>
          </Box>
        );
      })}
    </Stack>
  );
}
