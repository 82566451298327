import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from 'providers/UserProvider';
import { MembershipStatus } from 'providers/UserProvider/types';

import { PaywallPage } from './components/PaywallPage';

type Props = {
  children: JSX.Element;
  locationOverride?: string;
};

export function RequireAuth({ children, locationOverride }: Props) {
  const { userToken, useIsTokenValid } = useUser();
  const location = useLocation();
  const target = locationOverride ? locationOverride : location.pathname;
  return useIsTokenValid() ? (
    children
  ) : (
    <Navigate to={`/uss/signin`} state={{ from: target }} replace />
  );
}

export function RequireVerification({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const { user, getUser } = useUser();

  React.useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Navigate to={`/uss/signin`} state={{ from: location }} replace />;
  }

  if (
    !user.account ||
    !user.account.membership ||
    user.account?.membership !== MembershipStatus.ACTIVE
  ) {
    return <PaywallPage />;
  } else {
    if (user.verified) {
      return children;
    }

    return (
      <Navigate
        to={`/uss/verification?redirect=${location.pathname}`}
        state={{ from: location }}
        replace
      />
    );
  }
}
