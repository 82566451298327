import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { systemTasksSaga } from './saga';
import { PasswordResetPayload, UssState } from './types';

export const initialState: UssState = {
  isFetching: false,
  passwordResetEmail: null,
  passwordResetPayload: null,
  initiatePasswordResetSuccess: false,
  continuePasswordResetSuccess: false,
  initiatePasswordResetError: null,
  continuePasswordResetError: null,
};

const slice = createSlice({
  name: 'uss',
  initialState,
  reducers: {
    initiatePasswordReset(state: UssState, action: PayloadAction<string>) {
      state.passwordResetEmail = action.payload;
      state.isFetching = true;
      state.initiatePasswordResetSuccess = false;
      state.initiatePasswordResetError = null;
    },
    initiatePasswordResetSuccess(state: UssState) {
      state.isFetching = false;
      state.initiatePasswordResetSuccess = true;
      state.initiatePasswordResetError = null;
    },
    initiatePasswordResetError(
      state: UssState,
      action: PayloadAction<{ message: string }>,
    ) {
      state.isFetching = false;
      state.initiatePasswordResetSuccess = false;
      state.initiatePasswordResetError = action.payload;
    },
    continuePasswordReset(
      state: UssState,
      action: PayloadAction<PasswordResetPayload>,
    ) {
      state.passwordResetPayload = action.payload;
      state.isFetching = true;
      state.continuePasswordResetSuccess = false;
      state.continuePasswordResetError = null;
    },
    continuePasswordResetSuccess(state: UssState) {
      state.isFetching = false;
      state.continuePasswordResetSuccess = true;
      state.continuePasswordResetError = null;
    },
    continuePasswordResetError(
      state: UssState,
      action: PayloadAction<{ message: string }>,
    ) {
      state.isFetching = false;
      state.continuePasswordResetSuccess = false;
      state.continuePasswordResetError = action.payload;
    },
  },
});

export const { actions: ussActions, reducer } = slice;

export const useUssSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: systemTasksSaga });
  return { actions: slice.actions };
};
