/**
 *
 * Asynchronously loads the component for AddStakeholderPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AddStakeholderPage = lazyLoad(
  () => import('./index'),
  module => module.AddStakeholderPage,
);
