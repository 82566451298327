import { muiTheme } from 'styles/muiTheme';

export default {
  closeModalBtnStyle: {},
  headerTypography: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid',
    borderBottomColor: 'secondary.30p',
    pb: 1.75,
  },
  content: {
    top: '25%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -25%)',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 10%)',
    padding: '1.25rem 1.75rem 1.75rem',
  },
  overlay: {
    backgroundColor: muiTheme.palette.other.backdropOverlay,
    zIndex: 999,
  },
};
