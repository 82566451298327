/**
 *
 * Asynchronously loads the component for ModalDeleteStakeHolder
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ModalDeleteClient = lazyLoad(
  () => import('./index'),
  module => module.ModalDeleteClient,
);
