export default {
  boxContainerStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '24px 0px !important',
  },
  primaryButtonStyles: {
    borderColor: 'primary.main',
    color: 'primary.main',
    border: '1px solid',
  },
  accountInfo: {
    marginTop: '40px !important',
  },
  linkChangePassword: {
    color: 'primary.main',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '16px',
  },
};
