import { CssBaseline, Link, Box, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { muiTheme } from 'styles/muiTheme';
import { NavBar } from '../NavBar/Loadable';
import 'beautiful-react-diagrams/styles.css';
import style from './style';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { useUser } from 'providers/UserProvider';
import { useNavigate } from 'react-router-dom';
import { getRoutePath } from 'app/routes/routePath';
// import useFontFaceObserver from 'use-font-face-observer';

export const AppLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // const isFontListLoaded = useFontFaceObserver([{ family: `RobotoMedium` }]);
  // console.log('isFontListLoaded', isFontListLoaded);
  // TODO - add a loading...  for the fontface observer
  const navigate = useNavigate();
  const { user } = useUser();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <NavBar />
      <Box sx={style.childrenStyle}>{children}</Box>
      <Box sx={style.ribbonBoxStyle}>
        <Typography sx={style.ribbonTextStyle}>
          <Link
            component="button"
            onClick={() =>
              navigate(getRoutePath({ target: 'docsTermsOfService' }))
            }
            color="info.main"
          >
            {t('bannerText.termsOfService')}.
          </Link>
        </Typography>
      </Box>
    </ThemeProvider>
  );
};
