export default {
  titleStyle: {
    paddingBottom: '24px',
    marginBottom: '12px',
    borderBottom: '1px solid',
    borderColor: 'secondary.main',
  },
  headingStyle: {
    marginBottom: '12px',
    color: 'text.secondary',
  },
};
