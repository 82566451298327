/**
 *
 * Asynchronously loads the component for ListClients
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ListClients = lazyLoad(
  () => import('./index'),
  module => module.ListClients,
);
