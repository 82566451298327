import { muiTheme } from 'styles/muiTheme';

export const accordion = {
  ...muiTheme.variants.accordion,
  width: '100%',
  borderRadius: '4px',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    marginTop: 0,
  },
  boxShadow: 'none',
};

export const accordionHeader = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: 1,
  height: '72px',
};

export const accordionSummary = {
  border: 0,
  background: 'inherit',
  flexDirection: 'row-reverse',

  '&.Mui-expanded': {
    minHeight: '48px',
  },
  '.MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
  },
  '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '.MuiAccordionSummary-content': {
    margin: '1px 0',
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    margin: '1px 0',
  },
};

export const accordionDetails = {
  display: 'flex',
  flexDirection: 'column',
  background: 'inherit',
  padding: '0',
  '.MuiAccordion-root.Mui-expanded': {
    marginBottom: '0',
  },
};
