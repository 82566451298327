import { Modal } from 'app/components/Modal.new';
import { Box, Card, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { BottomRightGroupButtons } from 'app/components/Buttons/BottomRightGroupButtons';
import { ControlledInput } from 'app/components/Inputs/ControlledInput';
import * as styles from './style';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const ModalLinkCreate = ({ isOpen, onClose }: Props) => {
  const {
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
  });

  const { t } = useTranslation();

  return (
    <Modal
      isOpen={!!isOpen}
      onClose={onClose}
      header=""
      contentSx={styles.modalPreview}
      hideCloseButton
    >
      <Box width="100%">
        <Grid item xs={12} sx={{ padding: '0 20px' }}>
          <Typography variant="h5" mb={3} mt={3}>
            {t('vault.modals.linkCreate.title')}
          </Typography>
          <Grid item xs={12}>
            <ControlledInput
              watch={watch}
              type="text"
              size="medium"
              register={register}
              errors={errors.name}
              name="name"
              label="Link"
              innerLabel={false}
            />
          </Grid>
        </Grid>
        <Card elevation={4} sx={{ padding: '20px' }}>
          <BottomRightGroupButtons
            onPrimaryBtnClick={onClose}
            primaryBtnText={t('vault.modals.linkCreate.buttons.cancel')}
            secondaryBtnText={t('vault.modals.linkCreate.buttons.addLink')}
            secondaryBtnDisabled={!isValid}
          />
        </Card>
      </Box>
    </Modal>
  );
};
