import { muiTheme } from 'styles/muiTheme';

export default {
  associateButtonStyles: {
    fontSize: '13px',
    minWidth: '134px',
    marginRight: '5px',
    height: '36px',
    justifyContent: 'center',
  },

  backgroundInherit: {
    background: 'inherit',
    display: 'flex',
    alignItems: 'center',
  },

  boxContainerStyles: { marginBottom: 4 },

  boxTitleStyles: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingRight: 1,
  },

  accordionSummaryStyles: {
    ...muiTheme.variants.accordionSummary,
    padding: '13px !important',
  },

  accordionDetailsStyles: {
    borderTop: muiTheme.variants.componentBorder,
  },

  accordionStyles: muiTheme.variants.accordion,

  contentLiStyles: {
    display: 'flex',
    padding: 0,
  },

  buttonStyles: {
    background: 'rgba(0, 0, 0, 0.12)', //not in use
    borderRadius: '8px',
    display: 'flex',
    columnGap: '8px',
    ml: '17px',
    borderColor: '#8DCB9B',
    border: '1px solid',
  },
};
