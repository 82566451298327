/**
 * CurrencyValue
 * A compoment that takes a numberic currency value in a specific
 * currency and then converts it to another.
 * This component works inconjunctin with the CurrencySelector component.
 */

import { memo } from 'react';
import {
  CurrencyStateProps,
  ValidCurrencies,
} from 'providers/CurrencyProvider/types';
import getLocaleFromCurrencyCode from './getLocaleFromCurrencyCode';
import { Typography, TypographyProps } from '@mui/material';

interface Props extends TypographyProps {
  currency: ValidCurrencies;
  value?: number | string;
  currencyState: CurrencyStateProps;
}

export const formatCurrencyToLocale = (
  base: ValidCurrencies,
  value: number,
  fractionDigits = 2,
) => {
  const locale = getLocaleFromCurrencyCode(base);
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: base,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  return formatter.format(value);
};

export const currencyConversion = (
  currencyState: CurrencyStateProps,
  currency: ValidCurrencies,
  value: number | string | undefined,
): number => {
  const castValue = Number(value);

  let adjustedValue: number;

  if (currencyState.rates[currency]) {
    adjustedValue = Number(castValue / Number(currencyState.rates[currency]));
  } else {
    adjustedValue = castValue;
  }

  return adjustedValue;
};

export const localCurrencyConversion = (
  currencyState: CurrencyStateProps,
  value: number | string | undefined,
  currency: ValidCurrencies = 'USD',
) => {
  const adjustedValue = currencyConversion(currencyState, currency, value);
  return formatCurrencyToLocale(currencyState.base, adjustedValue);
};

export const CurrencyValue = memo(
  ({ currency, value, currencyState, ...typographyProps }: Props) => {
    if (typeof value === 'string' || value === undefined) {
      return <Typography {...typographyProps}>{value}</Typography>;
    }

    const adjustedValue = currencyConversion(currencyState, currency, value);
    const formattedValue = formatCurrencyToLocale(
      currencyState.base,
      adjustedValue,
    );

    return <Typography {...typographyProps}>{formattedValue}</Typography>;
  },
);
