import { muiTheme } from 'styles/muiTheme';

export const simulatorContainer = {
  flex: 1,
  border: '1px solid secondary.12p',
  borderTop: '6px solid secondary.main',
  borderRadius: 2,
  backgroundColor: '#f0efed',
  height: '380px',
};
export const header = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: 2,
  borderBottom: '1px solid secondary.main',
};
export const button = {
  backgroundColor: 'secondary.main',
  color: 'primary.contrast',
  '&:hover': {
    backgroundColor: 'secondary.main',
  },
};

export const container = {
  display: 'flex',
  flex: 1,
  padding: 2,
  width: '100%',
  height: '300px',
  alignItems: 'start',
  overflow: 'auto',
};

export const rowsContainer = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: 'primary.contrast',
  border: muiTheme.variants.modalBorder,
  borderRadius: '5px',
};

export const rowItem = {
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: muiTheme.variants.modalBorder,
  padding: 1,
};

export const noDataMessage = {
  flex: 1,
  alignSelf: 'center',
  marginBottom: 0,
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontWeight: '700',
  color: 'secondary.main',
};
