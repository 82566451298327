export const paywallPageBox = {
  minWidth: '100%',
  mt: 15,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

export const paywallBody = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

export const paywallTitle = {
  ...paywallBody,
  mb: '1rem',
  mt: '4.5rem',
};
