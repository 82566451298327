import style from './style';

const financeConfidenceStyle = {
  ...style,
  stackContainerStyles: {
    ...style.stackContainerStyles,
    bgcolor: 'primary.main',
  },
  menuButtonStyles: {
    ...style.menuButtonStyles,
    '&.MuiButton-text': {
      ...style.menuButtonStyles['&.MuiButton-text'],
      color: 'primary.contrast',
    },
    '&.MuiButton-outlined': {
      ...style.menuButtonStyles['&.MuiButton-outlined'],
      borderColor: 'primary.contrast',
      color: 'primary.contrast',
    },
  },
  profileButtonStyles: {
    ...style.profileButtonStyles,
    color: 'primary.contrast',
  },
  avatarStyles: { ...style.avatarStyles, bgcolor: 'secondary.main' },
};

export default financeConfidenceStyle;
