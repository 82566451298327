import { Grid, SxProps } from '@mui/material';
import style from './style';

interface Props {
  id: React.Key;
  cardStyles?: SxProps;
  children: React.ReactNode[] | any;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export function TableListCard(props: Props) {
  const { id, children, cardStyles, onClick } = props;

  return (
    <Grid
      onClick={onClick}
      container
      key={id}
      sx={{ ...style.cardStyles, ...cardStyles }}
      spacing={1}
    >
      {children}
    </Grid>
  );
}
