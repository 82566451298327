import { muiTheme } from 'styles/muiTheme';

export const components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        backgroundColor: '#F8FBFC',
        margin: 0,
        fontSize: 12,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: 14,
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: theme.palette.primary['30p'],
        },
      }),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        fontSize: 12,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: 12,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        marginBottom: 8,
        color: '#40464D',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        height: '1.5rem',
      },
    },
  },
};
