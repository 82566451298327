import { useToastSlice } from 'app/features/ToastSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import style from './style';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
interface Props {
  isError: boolean;
  errorText?: string;
}

export function ErrorToast({ isError, errorText }: Props) {
  const { actions } = useToastSlice();
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(actions.hideSuccessToast());
  };
  useEffect(() => {
    if (isError) {
      toast.error(errorText, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose,
        style: style.errorToastStyles,
        icon: ({ theme, type }) => (
          <ErrorOutlineOutlinedIcon sx={{ color: 'error.contrast' }} />
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, errorText]);

  return <ToastContainer />;
}
