/**
 *
 * Asynchronously loads the component for SharedWithMeButton
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SharedWithMeButton = lazyLoad(
  () => import('./index'),
  module => module.SharedWithMeButton,
);
