import React from 'react';
import {
  AreaChart as RechartsChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {
  ChartData,
  areaChartStyle,
  areaColor,
  linearGradientStyle,
  chartSize,
  areaStrokeColor,
} from '../style';
import moment from 'moment';

interface ChartProps {
  data: ChartData[];
  isDashboard?: boolean;
}

export const dateFormatter = date => {
  return moment(date).format('MM/DD/YYYY');
};

const CustomizedAxisTick = () => <></>;

export const AreaChart = ({ data, isDashboard }: ChartProps) => {
  const chartData = data.map(report => ({
    ...report,
    date: moment(report.date, 'MM/DD/YYYY').valueOf(),
  }));
  return (
    <ResponsiveContainer {...chartSize}>
      {isDashboard ? (
        <RechartsChart {...areaChartStyle} data={chartData}>
          <defs>
            <linearGradient {...linearGradientStyle}>
              <stop offset="5%" stopColor={areaColor} stopOpacity={0.9} />
              <stop offset="95%" stopColor={areaColor} stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <Area
            fill="url(#colorPv)"
            dataKey="value"
            type="monotone"
            stroke={areaColor}
            fillOpacity={1}
          />
        </RechartsChart>
      ) : (
        <RechartsChart {...areaChartStyle} data={chartData}>
          <defs>
            <linearGradient {...linearGradientStyle}>
              <stop offset="5%" stopColor={areaStrokeColor} stopOpacity={0.8} />
              <stop offset="95%" stopColor={areaStrokeColor} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            hide={isDashboard}
            tick={<CustomizedAxisTick />}
            tickFormatter={dateFormatter}
          />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            labelFormatter={dateFormatter}
            labelStyle={{ color: '#000000' }}
          />
          <Area
            fill="url(#colorPv)"
            dataKey="value"
            type="monotone"
            stroke={areaStrokeColor}
            fillOpacity={1}
          />
          <Area
            fill="url(#colorPv)"
            dataKey="cost"
            stroke="#82ca9d"
            type="monotone"
            fillOpacity={1}
          />
          <Area
            fill="url(#colorPv)"
            dataKey="totalValue"
            stroke="red"
            type="monotone"
            fillOpacity={1}
          />
          <Area
            fill="url(#colorPv)"
            dataKey="purchasePrice"
            stroke="blue"
            type="monotone"
            fillOpacity={1}
          />
        </RechartsChart>
      )}
    </ResponsiveContainer>
  );
};
