/**
 *
 * Asynchronously loads the component for BottomRightGroupButtons
 *
 */

import { lazyLoad } from 'utils/loadable';

export const BottomRightGroupButtons = lazyLoad(
  () => import('./index'),
  module => module.BottomRightGroupButtons,
);
