import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Grid, Card, Typography } from '@mui/material';
import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
} from '@mui/icons-material';
import {
  selectAssetsAverages,
  selectConsolidationCalculations,
  selectConsolidationContext,
} from 'app/features/GroupSlice/selectors';
import { Totals } from './components/Totals/Loadable';
import { Benchmarks } from './components/Benchmarks/Loadable';
import { SavedScenarios } from './components/SavedScenarios';
import { Scenario } from './components/Scenario';
import { Header } from './components/Header';
import { SellAssetModal } from 'app/pages/scenario-modeling/modals/SellAssetModal/Loadable';
import { BuyAssetModal } from 'app/pages/scenario-modeling/modals/BuyAssetModal/Loadable';
import tigerData from '../../../jsonData/tigerReading.json';
import { muiTheme } from 'styles/muiTheme';
import { SaveScenarioModal } from 'app/pages/scenario-modeling/modals/SaveScenarioModal';
import { ToastContainer, toast } from 'react-toastify';

import { useCurrencyState } from 'providers/CurrencyProvider';
import * as styles from './styles';
//RTK
import {
  initScenario,
  sellScenario,
  buyScenario,
} from 'app/pages/scenario-modeling/features/modeling-slice';
import {
  useAddScenarioMutation,
  useGetScenariosQuery,
} from 'app/features/api/apiSlice';

export const ScenarioModeling = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openSellAssetModal, setOpenSellAssetModal] = useState(false);
  const [openBuyAssetModal, setOpenBuyAssetModal] = useState(false);
  const [saveScenarioModal, setSaveScenarioModal] = useState(false);

  const { groupId } = useParams<{ groupId: string }>();

  const [addScenario, result] = useAddScenarioMutation();

  const { data: scenariosData } = useGetScenariosQuery(groupId);
  const currencyState = useCurrencyState();

  const { currentScenario } = useSelector(
    (state: any) => state.scenerarioModeling,
  );

  const { current: groupData } = useSelector(selectConsolidationContext);

  const currentGroupCalculations = useSelector(selectConsolidationCalculations);
  const currentProjectHoldings = {
    ...currentGroupCalculations['current'],
    available_assets: '$0.00',
    available_liability: '$0.00',
    tax_payable: '$0.00',
  };
  const groupAssetAverages = useSelector(selectAssetsAverages);

  const isEnabled = groupId && groupId !== 'consolidated' && currentScenario;
  const isEnabledScenario = groupId && groupId !== 'consolidated' && groupData;

  useEffect(() => {
    if (isEnabledScenario) {
      dispatch(
        initScenario({
          currencyState,
          groupId,
          groupData,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, groupData]);

  const handleOpenSellAssetModal = () => {
    setOpenSellAssetModal(true);
  };

  const handleOpenBuyAssetModal = () => {
    setOpenBuyAssetModal(true);
  };

  const handleOpenSaveScenarioModal = () => {
    setSaveScenarioModal(true);
  };

  const handleClearScenario = () => {
    dispatch(
      initScenario({
        currencyState,
        groupId,
        groupData,
      }),
    );
  };

  const handleSellScenario = asset => {
    dispatch(
      sellScenario({
        asset,
      }),
    );
  };

  const handleBuyScenario = ({ buyAsset, sellAssets }) => {
    dispatch(
      buyScenario({
        buyAsset,
        sellAssets,
      }),
    );
  };

  const handleSaveScenario = title => {
    const data = {
      payload: {
        ...currentScenario,
        title,
      },
    };

    addScenario(data);

    setSaveScenarioModal(false);

    //TODO: Refactor this to use RTK Query
    toast.success('Scenario Saved', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        fontSize: 11,
        fontFamily: 'RobotoMedium',
        color: muiTheme.palette.success.contrast,
        background: muiTheme.palette.success.main,
        zIndex: 999,
      },
      icon: () => (
        <CheckCircleOutlineOutlined sx={{ color: 'success.contrast' }} />
      ),
    });
    handleClearScenario();
  };

  return (
    <>
      <Helmet>
        <title> {t('scenarioModeling.home.headers.scenarioModeling')}</title>
        <meta name="description" content="MyFO Consolidation page" />
      </Helmet>

      {isEnabled ? (
        <Card sx={{ ...muiTheme.variants.userHomeContentStyles, padding: 0 }}>
          <Grid padding={2}>
            <Header selectedGroup={currentScenario?.groupData} />
            <Typography variant="h6" fontWeight="700" mb={2}>
              {t('scenarioModeling.home.headers.buyOrSell')}
            </Typography>
          </Grid>
          <Scenario
            onSaveScenario={handleOpenSaveScenarioModal}
            onClearScenario={handleClearScenario}
            scenario={currentScenario}
            toogleSellModal={handleOpenSellAssetModal}
            toogleBuyModal={handleOpenBuyAssetModal}
          />

          <Grid padding={2}>
            <Typography variant="h6" fontWeight="700" mb={2}>
              {t('scenarioModeling.home.headers.consolidatedAndHoldings')}
            </Typography>
            <Totals values={currentProjectHoldings} />

            {currentScenario?.benchmarkData && (
              <Benchmarks
                holdings={groupAssetAverages['current']}
                benchmarkData={tigerData.tigerReadings}
              />
            )}
            <SavedScenarios scenarios={scenariosData} />
          </Grid>
        </Card>
      ) : (
        <Card sx={styles.emptyScenarioStyles}>
          <Grid sx={styles.alert}>
            <ErrorOutlineOutlined sx={{ marginRight: 2 }} />
            <Typography variant="caption">
              {t('scenarioModeling.home.alerts.disclaimer')}
            </Typography>
          </Grid>
          <Grid sx={styles.emptyScenarioBanner}>
            <Typography variant="h6" fontWeight="bold">
              {t('scenarioModeling.home.messages.noEntitySelected')}
            </Typography>
          </Grid>
        </Card>
      )}

      <SellAssetModal
        assetsList={currentScenario?.assetsList}
        onRunSimulation={handleSellScenario}
        isOpen={openSellAssetModal}
        setIsOpen={setOpenSellAssetModal}
      />
      <BuyAssetModal
        groupId={currentScenario?.groupId}
        assetsList={currentScenario?.assetsList}
        onRunSimulation={handleBuyScenario}
        isOpen={openBuyAssetModal}
        setIsOpen={setOpenBuyAssetModal}
      />
      <SaveScenarioModal
        onSaveScenario={handleSaveScenario}
        isOpen={saveScenarioModal}
        setIsOpen={setSaveScenarioModal}
      />
      {/*   ==== Toast Container:  Refactor this to use RTK Query ==== */}
      <ToastContainer />
    </>
  );
};
