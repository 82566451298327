import { ContactSummary } from 'app/features/AddressBookSlice/types';
import { GroupData } from 'app/features/GroupSlice/types';

export enum ItemType {
  file = 'file',
  folder = 'folder',
}

export type VaultFile = {
  _id?: string;
  version?: string;
  isArchived?: boolean;
  isDeleted?: boolean;
  isVerified?: boolean;
  isCompressed?: boolean;
  isSigned?: boolean;
  isEncrypted?: boolean;
  extension: string;
  fileName: string;
  filePath: string;
  lastModified: string;
  contentLength: number;
  contentType: string;
  key: string;
  createdOn: string;
  createdBy?: string;
  groupId?: string;
  size?: number;
  itemType?: string;
  getUrl?: string;
  previewUrl?: string;
  isHighlighted?: boolean;
  isIncluded?: boolean;
  putUrl?: string;
  type?: ItemType.file;
  owner?: string;
};

export type Folder = {
  id?: string;
  groupId: string;
  name: string;
  itemType: string;
  children?: Folder[] | VaultFile[];
  size?: number;
  isIncluded?: boolean;
  isHighlighted?: boolean;
  lastModified: string;
  type?: ItemType.folder;
  owner?: string;
};

export type LocalFile = {
  _id: string;
  extension: string;
  fileName: string;
  filePath: string;
  lastModified: string;
  size: number;
  createdBy: string;
  groupId: string;
  previewUrl: string;
};

export type FileConfig = {
  file: VaultFile;
  callback: (file: VaultFile | null) => void;
};

export type FileUploadConfig = {
  folder: Folder | null;
  callback: (file: VaultFile | null) => void;
};

export type SelectedFolder = {
  id: string;
  groupId: string;
  name: string;
  itemType: string;
};

/* ========= Files Tree =========== */

export type FolderTree = {
  children: Folder[];
};

/* ========= useEntitiesData: hook ======== */

export type Entity = {
  groupId: string;
  group: GroupData;
  files: {
    children: Folder[];
    isIncluded?: boolean;
  };
  stakeholders: ContactSummary[];
  isIncluded?: boolean;
};

export type FileOperation = {
  type: 'ADD' | 'DELETE' | 'UPDATE';
  file: VaultFile | LocalFile;
  message: string;
};

/* ========= Search Results ======== */

export type SearchResult = {
  item: VaultFile;
  refIndex: number;
  score: number;
};

export type FlattenEntityItem = {
  type: 'group' | 'folder' | 'file';
  children?: FlattenEntityItem[];
  [key: string]: unknown;
};
