import { muiTheme } from 'styles/muiTheme';

export default {
  closeModalBtnStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    p: 0,
    m: 0,
  },
  content: {
    top: '25%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -25%)',
    border: 'unset',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 10%)',
    padding: '24px',
    paddingTop: '28px',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: muiTheme.palette.other.backdropOverlay,
    zIndex: 999,
    overflow: 'hidden',
  },
};
