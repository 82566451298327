/**
 *
 * Asynchronously loads the component for AssetsLiabilitiesPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ConnectedAccountsPage = lazyLoad(
  () => import('.'),
  module => module.ConnectedAccountsPage,
);
