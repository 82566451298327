import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { Stack, Typography } from '@mui/material';
import style from './style';
import { ControlledInputPhone } from 'app/components/Inputs/ControlledInputPhone';
import { UseFormReturn } from 'react-hook-form';
import { ControlledInput } from 'app/components/Inputs/ControlledInput';

interface UserInformationstripeFormProps {
  useFormInstance: UseFormReturn<any>;
}
export function UserInformationstripeForm({
  useFormInstance,
}: UserInformationstripeFormProps) {
  const { t } = useTranslation();
  const {
    watch,
    register,
    formState: { errors },
  } = useFormInstance;
  return (
    <Stack spacing={2} sx={style.boxContainerStyles}>
      <Typography variant="h6" sx={style.heading}>
        {capitalize(t('stripeForm.placeholders.header'))}
      </Typography>
      <Stack direction="row" spacing={2}>
        <ControlledInput
          watch={watch}
          type="text"
          register={register}
          errors={errors.firstName}
          name="firstName"
          label={capitalize(t('signUp.placeholders.firstName'))}
          size="medium"
        />
        <ControlledInput
          watch={watch}
          type="text"
          register={register}
          errors={errors.lastName}
          name="lastName"
          label={capitalize(t('signUp.placeholders.lastName'))}
          size="medium"
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <ControlledInput
          watch={watch}
          type="text"
          register={register}
          errors={errors.country}
          name="country"
          label={capitalize(t('stripeForm.placeholders.country'))}
          size="medium"
        />
        <ControlledInput
          watch={watch}
          type="text"
          register={register}
          errors={errors.state}
          name="state"
          label={capitalize(t('stripeForm.placeholders.state'))}
          size="medium"
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <ControlledInput
          watch={watch}
          type="text"
          register={register}
          errors={errors.city}
          name="city"
          label={capitalize(t('stripeForm.placeholders.city'))}
          size="medium"
        />
        <ControlledInput
          watch={watch}
          type="text"
          register={register}
          errors={errors.postalCode}
          name="postalCode"
          label={capitalize(t('stripeForm.placeholders.pincode'))}
          size="medium"
        />
      </Stack>
      <ControlledInputPhone
        useFormInstance={useFormInstance}
        size="medium"
        otherRules={{
          required: false,
        }}
      />
      <Stack direction="row" spacing={2}>
        <ControlledInput
          watch={watch}
          type="text"
          register={register}
          errors={errors.addressline1}
          name="line1"
          label={capitalize(t('stripeForm.placeholders.addressline1'))}
          size="medium"
          otherRules={{
            required: false,
          }}
        />
        <ControlledInput
          watch={watch}
          type="text"
          register={register}
          errors={errors.addressline2}
          name="line2"
          label={capitalize(t('stripeForm.placeholders.addressline2'))}
          size="medium"
          otherRules={{
            required: false,
          }}
        />
      </Stack>
    </Stack>
  );
}
