import { Box, Typography } from '@mui/material';

import { ReactComponent as EmailVerification } from 'app/assets/icons/email-verification.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsFetching } from './features/signup-slice/selectors';
import React from 'react';
import { useUser } from 'providers/UserProvider';
import { muiTheme } from 'styles/muiTheme';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { getRoutePath } from 'app/routes/routePath';
import style from './style';
import { BottomRightGroupButtons } from 'app/components/Buttons/BottomRightGroupButtons/Loadable';

import { useAuth0 } from '@auth0/auth0-react';

export const VerificationPage = () => {
  const navigate = useNavigate();
  const {
    userToken,
    user,
    editUser,
    isEditingUser,
    editUserSuccess,
    clearEditState,
  } = useUser();
  const { user: authUser } = useAuth0();
  const isFetching = useSelector(selectIsFetching);
  const { t } = useTranslation();

  const redirectTo = React.useCallback(() => {
    if (userToken && authUser?.email_verified && user?.verified) {
      const params = new URLSearchParams(window.location.search);
      navigate(params.get('redirect') || getRoutePath({ target: 'userHome' }));
    }
  }, [authUser?.email_verified, navigate, user?.verified, userToken]);

  React.useEffect(() => {
    if (isEditingUser || editUserSuccess) return;
    if (!user || !authUser) return;
    if (authUser.email_verified && !user.verified) {
      editUser({
        ...user,
        verified: true,
      });
      return;
    }
    if (authUser.email_verified && user.verified) {
      redirectTo();
    }
  }, [
    authUser,
    editUser,
    editUserSuccess,
    isEditingUser,
    navigate,
    redirectTo,
    user,
  ]);

  React.useEffect(() => {
    if (!isEditingUser && editUserSuccess) {
      clearEditState();
      redirectTo();
    }
  }, [isEditingUser, editUserSuccess, redirectTo, clearEditState]);

  const handleVerification = () => {
    // TODO refresh auth0 token insted of whole page
    window.location.reload();
  };

  return (
    <Box sx={muiTheme.variants.boxVerificationStyles}>
      <Box sx={style.emailVerificationIconStyles}>
        <EmailVerification width="100px" />
      </Box>
      <Box mb={6}>
        <Typography
          variant="h4"
          sx={style.headerTextStyles}
          data-test="verification-email-header"
          textAlign="center"
        >
          {capitalize(t('verification.heading.verification'))}
        </Typography>
        <Typography
          sx={style.bodyTextStyles}
          data-test="verification-email-detais"
          textAlign="center"
          variant="body1"
          color="text.secondary"
        >
          {capitalize(t('verification.labels.how2code'))}
        </Typography>
      </Box>
      {isFetching ? (
        <Typography
          id="isLoading"
          variant="caption"
          color="text.secondary"
          sx={style.loadingTextStyles}
        >
          {capitalize(t('loading...'))}
        </Typography>
      ) : (
        <BottomRightGroupButtons
          secondaryBtnText={capitalize(t('verification.buttons.verifyMe'))}
          buttonGroupStyles="center"
          secondaryFullWidth={true}
          onSecondaryBtnClick={handleVerification}
        />
      )}
    </Box>
  );
};
