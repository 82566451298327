export const Container = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#f7f7f6',
  padding: '26px',
};

export const SimulatorsContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 2,
  marginBottom: '20px',
};

export const ProjectHoldings = { display: 'block', marginBottom: '24px' };

export const ButtonsContainer = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '20px',
};

export const ClearButton = {
  backgroundColor: 'transparent',
  border: 'none',
  boxShadow: 'none',
  color: '#10182061',
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
};

export const SaveButton = {
  backgroundColor: 'black',
  border: 'none',
  boxShadow: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: 'black',
    boxShadow: 'none',
  },
};
