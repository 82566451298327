import shadows from 'styles/shadows';

export default {
  cardStyles: {
    padding: '16px',
    border: '1px solid',
    borderColor: 'secondary.12p',
    boxShadow: shadows[12],
    maxWidth: '50%',
  },
  gridStyles: { alignItems: 'center', margin: '16px 0px' },
};
