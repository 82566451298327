import React, { PropsWithChildren } from 'react';
import ReactModal from 'react-modal';
import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import style from './style';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  hideCloseButton?: boolean;
  closeModalBtnStyleProps?: any;
  closeModalBtnStyle?: any;
  contentSx?: SxProps;
  header: React.ReactNode | string;
  headerSx?: SxProps;
  footer?: React.ReactNode | string;
}

/**
 * #### Usage
 * Wrapper for creating a modal with ReactModal
 *
 * ```tsx
 * import { Modal } from 'app/components/Modal.new';
 *
 * <Modal
 *    isOpen={isOpenModal}
 *    onClose={() => setIsOpenModal(false)}
 *    header={"title"}
 *    footer={"footer" || <>footer</> || undefined}
 *  >
 *    <>body</>
 *  </Modal>
 * ```
 */
export function Modal({
  children,
  isOpen,
  onClose,
  closeModalBtnStyle,
  closeModalBtnStyleProps,
  contentSx,
  headerSx,
  hideCloseButton,
  header,
  footer,
}: Props & PropsWithChildren<{}>) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={{
        content: { ...style.content, ...contentSx },
        overlay: style.overlay,
      }}
    >
      <Box sx={{ ...style.header, ...headerSx }}>
        <Box sx={{ maxWidth: '95%' }}>
          {typeof header === 'string' ? (
            <Typography
              color="text.primary"
              variant="h6"
              sx={style.headerTypography}
            >
              {header}
            </Typography>
          ) : (
            header
          )}
        </Box>
        <Box sx={{ ...style.closeModalBtnStyle, closeModalBtnStyle }}>
          {onClose && !hideCloseButton && (
            <IconButton
              onClick={onClose}
              disableRipple
              sx={{
                p: 0,
                color: 'secondary.light',
                ...closeModalBtnStyleProps,
              }}
            >
              <Close />
            </IconButton>
          )}
        </Box>
      </Box>
      {children}
      {footer && <Box>{footer}</Box>}
    </ReactModal>
  );
}
