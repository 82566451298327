export default {
  headingStyles: {
    paddingTop: '24px',
    paddingBottom: '40px',
    fontWeight: '400px',
    color: 'text.primary',
    textAlign: 'center',
  },
  accordianSummaryStyle: {
    '.Mui-expanded > .collapsIconWrapper': {
      display: 'none',
    },
    '.expandIconWrapper': {
      display: 'none',
    },
    '.Mui-expanded > .expandIconWrapper': {
      display: 'block',
    },
  },
  dropdownIconStyle: {
    color: 'primary.main',
  },
  headerStyles: {
    display: 'flex',
  },
  mainheadingStyles: {
    paddingTop: '24px',
    paddingBottom: '40px',
    fontWeight: '400px',
    color: 'text.primary',
    textAlign: 'center',
    flex: 1,
  },
};
