import { put, takeLatest, call } from 'redux-saga/effects';
import { getInstance } from 'utils/http';
import { OSS_URL } from 'utils/urls';
import { stripeActions as actions } from '.';
import { defaultErrorMessage } from 'constants/error';
import { NotificationType, StripeUserBasicInfo } from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import { toastActions } from '../ToastSlice';

export function* getStripeAccountDetails(
  action: PayloadAction<StripeUserBasicInfo>,
) {
  try {
    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.get,
      `/user/billing/details/${action.payload.customerId}`,
    );
    yield put(actions.getStripeAccountDetailsSuccess(response.data.customer));
  } catch (error: Error | any) {
    yield put(
      actions.getStripeAccountDetailsError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* getStripePaymentDetails(
  action: PayloadAction<StripeUserBasicInfo>,
) {
  try {
    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.get,
      `/user/billing/history/${action.payload.customerId}`,
    );
    yield put(actions.getStripePaymentDetailsSuccess(response.data.details));
  } catch (error: Error | any) {
    yield put(
      actions.getStripePaymentDetailsError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* getStripeCardDetails(
  action: PayloadAction<StripeUserBasicInfo>,
) {
  try {
    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.get,
      `/user/billing/payment/${action.payload.paymentMethodId}`,
    );
    yield put(actions.getStripeCardDetailsSuccess(response.data.details));
  } catch (error: Error | any) {
    yield put(
      actions.getStripeCardDetailsError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* updateStripeCardDetails(
  action: PayloadAction<StripeUserBasicInfo>,
) {
  try {
    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.post,
      'user/billing/card-session',
      action.payload,
    );
    yield put(actions.updateStripeCardDetailsSuccess(response.data));
  } catch (error: Error | any) {
    yield put(
      actions.updateStripeCardDetailsError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* updateStripeAccountDetails(
  action: PayloadAction<StripeUserBasicInfo>,
) {
  try {
    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.patch,
      'user/billing/address',
      action.payload,
    );
    yield put(actions.updateStripeAccountDetailsSuccess(response.data));
  } catch (error: Error | any) {
    yield put(
      actions.updateStripeAccountDetailsError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* handleStripeError(action: PayloadAction<NotificationType>) {
  yield put(
    toastActions.showErrorToast({
      show: true,
      message: action.payload.message || defaultErrorMessage,
    }),
  );
}

export function* handleStripeSuccess(action: PayloadAction<NotificationType>) {
  yield put(
    toastActions.showSuccessToast({
      show: true,
      message: action.payload.message,
    }),
  );
}

export function* stripeSaga() {
  yield takeLatest(
    actions.getStripeAccountDetails.type,
    getStripeAccountDetails,
  );
  yield takeLatest(
    actions.getStripeAccountDetailsSuccess.type,
    handleStripeSuccess,
  );
  yield takeLatest(
    actions.getStripeAccountDetailsError.type,
    handleStripeError,
  );

  yield takeLatest(
    actions.getStripePaymentDetails.type,
    getStripePaymentDetails,
  );
  yield takeLatest(
    actions.getStripePaymentDetailsSuccess.type,
    handleStripeSuccess,
  );
  yield takeLatest(
    actions.getStripePaymentDetailsError.type,
    handleStripeError,
  );

  yield takeLatest(actions.getStripeCardDetails.type, getStripeCardDetails);
  yield takeLatest(
    actions.getStripeCardDetailsSuccess.type,
    handleStripeSuccess,
  );
  yield takeLatest(actions.getStripeCardDetailsError.type, handleStripeError);
  yield takeLatest(
    actions.updateStripeCardDetails.type,
    updateStripeCardDetails,
  );
  yield takeLatest(
    actions.updateStripeCardDetailsSuccess.type,
    handleStripeSuccess,
  );
  yield takeLatest(
    actions.updateStripeCardDetailsError.type,
    handleStripeError,
  );
  yield takeLatest(
    actions.updateStripeAccountDetails.type,
    updateStripeAccountDetails,
  );
  yield takeLatest(
    actions.updateStripeAccountDetailsSuccess.type,
    handleStripeSuccess,
  );
  yield takeLatest(
    actions.updateStripeAccountDetailsError.type,
    handleStripeError,
  );
}
