import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormRegister,
  UseFormWatch,
  RegisterOptions,
} from 'react-hook-form';
import { InputLabel, Typography } from '@mui/material';
import { capitalize } from '@mui/material/utils';
import { useTranslation } from 'react-i18next';
import { InfoOutlined } from '@mui/icons-material';
import { Input, InputProps } from '../Input';
import { muiTheme } from 'styles/muiTheme';

export interface ControlledInputProps extends InputProps {
  name: string;
  label: string;
  innerLabel?: boolean;
  otherRules?: RegisterOptions;
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  errors?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  registerOptions?: RegisterOptions;
}

export const ControlledInput = (props: ControlledInputProps) => {
  const { t } = useTranslation();
  const {
    name,
    label,
    disabled,
    innerLabel = true,
    otherRules,
    register,
    watch,
    errors,
    placeholder,
    registerOptions,
    ...inputValueProps
  } = props;

  const value = watch(name, inputValueProps.InputProps?.value || '');
  const hasError = !disabled && Boolean(errors?.message);
  const errorMessage =
    hasError &&
    typeof errors?.message === 'string' &&
    capitalize(errors?.message);

  const validationRules = {
    required: {
      value: !disabled,
      message: capitalize(t('thisFieldIsRequired')),
    },
    ...(disabled ? {} : otherRules || {}),
    ...(registerOptions || {}),
  } as RegisterOptions; // casting because merging otherRules and registerOptions make typescript crazy

  return (
    <>
      {!innerLabel && (
        <InputLabel htmlFor={name} sx={muiTheme.variants.inputLabel}>
          <Typography variant="body1" color="text.primary">
            {label}
          </Typography>
        </InputLabel>
      )}
      <Input
        {...inputValueProps}
        value={value}
        id={name}
        label={innerLabel ? label : undefined}
        placeholder={placeholder || label}
        isError={hasError}
        error={hasError}
        helperText={errorMessage}
        HelperTextIcon={InfoOutlined}
        disabled={disabled}
        {...register(name, validationRules)}
      />
    </>
  );
};
