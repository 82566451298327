/**
 *
 * Asynchronously loads the component for SharedWithMePage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SharedWithMePage = lazyLoad(
  () => import('./index'),
  module => module.SharedWithMePage,
);
