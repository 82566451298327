/**
 *
 * Asynchronously loads the component for RemoveItemModal
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RemoveItemModal = lazyLoad(
  () => import('./index'),
  module => module.RemoveItemModal,
);
