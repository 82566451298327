import React, { useState } from 'react';
import { BaseTextFieldProps, TextField } from '@mui/material';
import { getMaskedValue, getUnmaskedValue } from 'utils/masks';
import { MaskedTypes } from './model';

export interface InputProps extends BaseTextFieldProps {
  infoUrl?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showPassword?: boolean;
  type?: keyof typeof MaskedTypes;
}

export const MaskedInput = (props: InputProps) => {
  const {
    type,
    id,
    name,
    onChange,
    onBlur,
    value: inputValue,
    defaultValue,
    ...inputValueProps
  } = props;
  const [maskedValue, setMaskedValue] = useState<string>('');
  const [unmaskedValue, setUnmaskedValue] = useState<string>('');

  const onChangeHandler = ev => {
    const value = getDisplayValue(ev.target.value);
    setMaskedValue(value);
  };

  const onBlurHandler = ev => {
    const value = getDisplayValue(ev.target.value);
    setMaskedValue(value);
    const realValue = getRealValue(value);
    setUnmaskedValue(realValue);
    onBlur &&
      onBlur({
        ...ev,
        target: {
          name,
          value: realValue,
        },
      });
  };

  const getRealValue = React.useCallback(
    (value?: string): string => getUnmaskedValue(value, type),
    [type],
  );

  const getDisplayValue = React.useCallback(
    (value?: string): string => getMaskedValue(value, type),
    [type],
  );

  React.useEffect(() => {
    const realValue = getRealValue(maskedValue);
    if (realValue === unmaskedValue || !name || !onChange) return;
    onChange({
      target: { name, value: realValue },
    } as React.ChangeEvent<HTMLInputElement>);
    setUnmaskedValue(realValue);
  }, [getRealValue, maskedValue, onChange, name, unmaskedValue]);

  React.useEffect(() => {
    const value = getDisplayValue(inputValue?.toString());
    setMaskedValue(value);
    setUnmaskedValue(getRealValue(value));
  }, [getDisplayValue, getRealValue, inputValue]);

  return (
    <>
      <TextField
        {...inputValueProps}
        value={maskedValue}
        id={`${id}-masked`}
        name={`${name}-masked`}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
      />
      <TextField
        {...inputValueProps}
        sx={{ ...inputValueProps.sx, display: 'none' }}
        id={id}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={unmaskedValue}
      />
    </>
  );
};
