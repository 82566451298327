export const container = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
};

export const fileContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  width: '100%',
  height: '100%',
};

export const modalPreview = {
  backgroundColor: 'transparent',
  border: 'none',
  padding: '0 20px',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
