import * as icons from '../assets';
import {
  VaultFile,
  Folder,
  ItemType,
} from 'app/pages/vault/models/vault.types';

/**
 * The function `getExtensionType` determines the type of file based on its extension.
 * @param {string} extension - The `extension` parameter in the `getExtensionType` function is a string
 * representing the file extension of a file. The function checks the extension and returns a string
 * indicating the type of file based on the extension.
 * @returns The function `getExtensionType` returns a string indicating the type of file based on the
 * provided extension. The possible return values are:
 * - 'image' for extensions 'png', 'jpeg', or 'jpg'
 * - 'pdf' for extension 'pdf'
 * - 'video' for extensions 'mp4' or 'webm'
 * - 'unsupported' for other extensions.
 */
export const getExtensionType = (extension: string): string => {
  switch (extension) {
    case 'png':
    case 'jpeg':
    case 'jpg':
    case 'webp':
      return 'image';
    case 'pdf':
      return 'pdf';
    case 'mp4':
    case 'webm':
      return 'video';
    default:
      return 'unsupported';
  }
};

/**
 * The function getFileIconByExtension returns an icon based on the file extension provided.
 * @returns The function `getFileIconByExtension` returns an icon based on the provided file extension.
 * If the extension matches one of the cases in the switch statement, it returns the corresponding
 * icon. If the extension does not match one of the cases, it returns the icon for unknown file types.
 */
export const getFileIconByExtension = extension => {
  switch (extension) {
    case 'gif':
      return icons.gif;
    case 'jpg':
      return icons.jpg;
    case 'mp4':
      return icons.mp4;
    case 'pdf':
      return icons.pdf;
    case 'png':
      return icons.png;
    case 'txt':
      return icons.txt;
    case 'zip':
      return icons.zip;
    case 'rar':
      return icons.rar;
    case 'pptx':
      return icons.pptx;
    case 'ppt':
      return icons.pptx;
    case 'xlsx':
      return icons.xlsx;
    case 'xls':
      return icons.xlsx;
    case 'doc':
      return icons.doc;
    case 'docx':
      return icons.doc;
    case 'webm':
      return icons.webm;
    case 'webp':
      return icons.webp;
    case 'link':
      return icons.link;
    default:
      return icons[extension] || icons.unknown;
  }
};

/**
 * The function `getGroupType` takes a group type as input and returns a corresponding type of
 * organization.
 * @returns The function `getGroupType` takes a `groupType` as input and returns a corresponding group
 * type. If the `groupType` is 'COMPANY', it returns 'Holding'. If the `groupType` is 'TRUST', it
 * returns 'Trust'. If the `groupType` is 'FAMILY_FOUNDATION', it returns 'Foundation'. If the
 * `groupType` does
 */
export const getGroupType = groupType => {
  switch (groupType) {
    case 'COMPANY':
      return 'Holding';
    case 'TRUST':
      return 'Trust';
    case 'FAMILY_FOUNDATION':
      return 'Foundation';
    default:
      return '';
  }
};

/**
 * The function `getGroupTypeBackground` returns a color code based on the input group type, with
 * default color 'Holding' if no match is found.
 * @returns If the `groupType` parameter matches 'COMPANY', '#e1f5fe' will be returned. If it matches
 * 'TRUST', '#ece7f6' will be returned. If it matches 'FAMILY_FOUNDATION', '#ffeac9' will be returned.
 * If none of these cases match, 'Holding' will be returned.
 */
export const getGroupTypeBackground = groupType => {
  switch (groupType) {
    case 'COMPANY':
      return 'static.companyBackground';
    case 'TRUST':
      return 'static.trustBackground';
    case 'FAMILY_FOUNDATION':
      return 'static.foundationBackground';
    default:
      return 'static.personalBackground';
  }
};

/**
 * The function `getGroupTypeIcon` returns a color code based on the input group type.
 * @returns The function `getGroupTypeIcon` returns a color code based on the `groupType` parameter. If
 * the `groupType` is 'COMPANY', it returns '#81d4fa'. If the `groupType` is 'TRUST', it returns
 * '#b39ddb'. If the `groupType` is 'FAMILY_FOUNDATION', it returns '#ffcc81'. If none
 */
export const getGroupTypeIcon = groupType => {
  switch (groupType) {
    case 'COMPANY':
      return 'static.companyIconColor';
    case 'TRUST':
      return 'static.trustIconColor';
    case 'FAMILY_FOUNDATION':
      return 'static.foundationIconColor';
    default:
      return 'static.personalIconColor';
  }
};

// Type guard to check if the item is a Folder
export const isFolder = (item: Folder | VaultFile): item is Folder => {
  return item.type === ItemType.folder;
};
