import { Box, SxProps, Typography } from '@mui/material';
import { AssetClassesKeysToValues } from 'constants/keyValue';
import style from './style';
import { muiTheme } from 'styles/muiTheme';

interface CalculationLegendProps {
  hoveredBar: string;
  availableClasses?: string[];
  containerSx?: SxProps;
  classKvDict?: Record<
    string,
    { label: string; color: string; benchmarkLabel?: string }
  >;
}

export function CalculationLegend({
  hoveredBar,
  availableClasses,
  classKvDict = AssetClassesKeysToValues,
  containerSx,
}: CalculationLegendProps) {
  return (
    <Box sx={{ ...style.boxContainerStyles, ...containerSx }}>
      {Object.keys(classKvDict)
        .filter(key => !availableClasses || availableClasses.includes(key))
        .map(className => {
          const isHover = className === hoveredBar;
          return (
            <Box sx={style.legendBoxStyles} key={className}>
              <Box
                sx={{
                  ...style.legendStyles,
                  backgroundColor: classKvDict[className].color,
                  boxShadow: isHover
                    ? muiTheme.variants.tooltipBoxShadow
                    : 'none',
                }}
              />
              <Typography
                variant="tooltip"
                sx={{
                  color:
                    !!hoveredBar && !isHover
                      ? muiTheme.palette.other.backdropOverlay
                      : muiTheme.palette.text.primary,
                  fontWeight: isHover ? 700 : 300,
                }}
              >
                {classKvDict[className].benchmarkLabel ||
                  classKvDict[className].label}
              </Typography>
            </Box>
          );
        })}
    </Box>
  );
}
