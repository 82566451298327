export default {
  chip: {
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'text.primary',
  },
  chipSelected: {
    backgroundColor: 'primary.main',
  },
};
