import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModalFilePreview } from './components/modal-file-preview';
import { EntityAccordion } from './components/entity-accordion';
import { selectConsolidationContext } from 'app/features/GroupSlice/selectors';
import { useUser } from 'providers/UserProvider';
import { ModalFileUpload } from './components/modal-file-upload';
import { ModalLinkCreate } from './components/modal-link-create';
import { ModalDelete } from './components/modal-delete';
import { ModalRename } from './components/modal-rename';
import { ModalMoveFolder } from './components/modal-move-folder';
import SearchBar from './components/search-bar';
import useEntitiesData from './hooks/useEntitiesData';
import { searchEntities } from 'app/pages/vault/utils/search.utils';
import { muiTheme } from 'styles/muiTheme';
import {
  SearchResult,
  Entity,
  FileOperation,
  VaultFile,
  LocalFile,
  Folder,
  FileConfig,
  FileUploadConfig,
} from './models/vault.types';

export const VaultPage = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [searchResults, setSearchResults] = useState<SearchResult[] | []>([]);
  const [searchedItem, setSearchedItem] = useState<SearchResult>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [fileUploadConfig, setFileUploadConfig] =
    useState<FileUploadConfig | null>(null);
  const [newFileModal, setNewFileModal] = useState(false);
  const [newLinkModal, setNewLinkModal] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [moveFolderModal, setMoveFolderModal] = useState(false);
  const [previewFile, setPreviewFile] = useState<VaultFile>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [fileConfig, setFileConfig] = useState<FileConfig>();
  const [fileOperation, setFileOperation] = useState<FileOperation>();

  const { all: groups } = useSelector(selectConsolidationContext);
  const entities = useEntitiesData(groups, searchedItem, fileOperation);

  useEffect(() => {
    if (searchTerm) {
      const results = searchEntities(entities, searchTerm);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [entities, searchTerm]);

  const handlePreviewFile = useCallback((file: VaultFile) => {
    setIsOpenModal(true);
    setPreviewFile(file);
  }, []);

  const handleCreateLink = useCallback(() => {
    setNewLinkModal(true);
  }, []);

  const handleSearch = useCallback((term: string) => {
    setSearchTerm(term);
  }, []);

  const handleSearchClick = useCallback((item: SearchResult) => {
    setSearchedItem(item);
  }, []);

  const handleAddNewFile = useCallback(
    (folder: Folder) => {
      const callback = (file: LocalFile) => {
        setFileOperation({
          type: 'ADD',
          file,
          message: t('vault.alerts.fileAdded'),
        });
      };

      const config = {
        folder,
        callback,
      } as FileUploadConfig;

      setFileUploadConfig(config);
      setNewFileModal(true);
    },
    [t],
  );

  const handleDeleteFile = useCallback(
    (file: VaultFile) => {
      const callback = (file: VaultFile) => {
        setFileOperation({
          type: 'DELETE',
          file,
          message: t('vault.alerts.fileDeleted'),
        });
      };

      const config = {
        file,
        callback,
      } as FileConfig;

      setFileConfig(config);
      setDeleteModal(true);
    },
    [t],
  );

  const handleRenameFile = useCallback(
    (file: VaultFile) => {
      const callback = (file: VaultFile) => {
        setFileOperation({
          type: 'UPDATE',
          file,
          message: t('vault.alerts.fileRename'),
        });
      };

      const config = {
        file,
        callback,
      } as FileConfig;

      setFileConfig(config);
      setRenameModal(true);
    },
    [t],
  );

  const handleMoveToFolder = useCallback(
    (file: VaultFile) => {
      const callback = (file: VaultFile) => {
        setFileOperation({
          type: 'UPDATE',
          file,
          message: t('vault.alerts.fileMoved'),
        });
      };

      const config = {
        file,
        callback,
      } as FileConfig;

      setFileConfig(config);
      setMoveFolderModal(true);
    },
    [t],
  );

  return (
    <>
      <Card sx={muiTheme.variants.userHomeContentStyles}>
        <Grid>
          <Typography variant="h6" fontWeight="700" mb={2}>
            {`${user?.firstName} ${user?.lastName}'s Entities`}
          </Typography>
        </Grid>
        <Grid sx={{ mb: 4 }}>
          <SearchBar
            results={searchResults}
            onSearch={handleSearch}
            onClick={handleSearchClick}
          />
        </Grid>
        <Grid>
          {entities.map((entity: Entity) => (
            <EntityAccordion
              key={entity.group._id}
              entity={entity}
              onPreviewFile={handlePreviewFile}
              onAddNewFile={handleAddNewFile}
              onAddNewLink={handleCreateLink}
              onRename={handleRenameFile}
              onDelete={handleDeleteFile}
              onMoveToFolder={handleMoveToFolder}
            />
          ))}
        </Grid>
      </Card>
      {previewFile && (
        <ModalFilePreview
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          file={previewFile}
        />
      )}

      <ModalFileUpload
        isOpen={newFileModal}
        fileUploadConfig={fileUploadConfig}
        onClose={() => setNewFileModal(false)}
      />
      <ModalLinkCreate
        isOpen={newLinkModal}
        onClose={() => setNewLinkModal(false)}
      />
      {fileConfig && (
        <ModalRename
          fileConfig={fileConfig}
          isOpen={renameModal}
          onClose={() => setRenameModal(false)}
        />
      )}
      {fileConfig && (
        <ModalDelete
          fileConfig={fileConfig}
          isOpen={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
      )}
      {fileConfig && (
        <ModalMoveFolder
          fileConfig={fileConfig}
          isOpen={moveFolderModal}
          onClose={() => setMoveFolderModal(false)}
        />
      )}
    </>
  );
};
