import * as React from 'react';
import { UseFormReturn, ValidationRule } from 'react-hook-form';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import {
  Autocomplete,
  BaseTextFieldProps,
  Box,
  FormGroup,
  Grid,
} from '@mui/material';
import { capitalize } from '@mui/material/utils';
import { useTranslation } from 'react-i18next';
import { ControlledInput } from '../ControlledInput';
import { Input } from '../Input';
import { Country, countries } from '../ControlledInputCountry';
import { InfoOutlined, WidthFull } from '@mui/icons-material';
import { getRequiredRuleValue } from '../utils';

export interface PhoneInputProps extends BaseTextFieldProps {
  disabled?: boolean;
  innerLabel?: boolean;
  type?: string;
  otherRules?: Record<string, ValidationRule>;
  errors?: any;
  useFormInstance: UseFormReturn<any>;
}

type otherStylesProps = {
  countrySelectStyles?: {
    style: { [key: string]: any };
    label?: string;
  };
  size?: 'small' | 'medium';
};

const uniqueDialCodes = countries.reduce((countryList, country) => {
  if (countryList.find(({ dialCode }) => dialCode === country.dialCode))
    return countryList;
  countryList.push(country);
  return countryList;
}, [] as Country[]);

export const ControlledInputPhone = ({
  useFormInstance,
  disableInput,
  otherRules,
  ...props
}: PhoneInputProps & { otherStyles?: otherStylesProps } & {
  disableInput?: boolean;
}) => {
  const { t } = useTranslation();
  const defaultValue = {
    name: '',
    dialCode: '',
    priority: 0,
    iso2: '',
    format: '',
  };
  const [country, setCountry] = React.useState<Country>(defaultValue);
  const {
    watch,
    register,
    setValue,
    getFieldState,
    formState: { errors },
  } = useFormInstance;
  const countryCode = watch('countryCode');
  const phoneNumber = watch('phoneNumber');
  const { isDirty } = getFieldState('countryCode');
  const hasError = !disableInput && Boolean(errors.countryCode);
  const isRequired = getRequiredRuleValue({
    otherRules,
    disabled: disableInput,
  });
  const errorMessage =
    hasError && typeof errors.countryCode?.message === 'string'
      ? capitalize(errors.countryCode.message)
      : isRequired && capitalize(t('thisFieldIsRequired'));

  register('countryCode', {
    required: {
      value: !disableInput,
      message: capitalize(t('thisFieldIsRequired')),
    },
    ...otherRules,
  });

  const phoneNumberRules = {
    required: capitalize(t('thisFieldIsRequired')),
    pattern: {
      value: /^\+?[\d\s().-]{10,15}$/,
      message: capitalize(t('mustBeValidPhoneNumber')),
    },
    minLength: {
      value: 10,
      message: capitalize(t('mustBeValidPhoneNumber')),
    },
    maxLength: {
      value: 15,
      message: capitalize(t('mustBeValidPhoneNumber')),
    },
    ...otherRules,
  };

  const countryOnChange = (_, country) => {
    const countryValue = country || defaultValue;
    setCountry(countryValue);
    setValue('countryCode', countryValue.dialCode, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  React.useEffect(() => {
    if (!countryCode || isDirty) return;
    const selectedCountry = countries.find(
      ({ dialCode }) => dialCode === countryCode,
    );
    if (!selectedCountry) return;
    setCountry(selectedCountry);
  }, [countryCode, isDirty]);

  const formatPhoneNumberInput = (
    phoneNumber: string,
    format: string,
    countryCode: string,
  ) => {
    const digitsOnly = phoneNumber.replace(/\D/g, ''); // Remove all non-digit characters
    const countryCodeLength = countryCode.length;
    const placeholderCount =
      (format.match(/\./g) || []).length - countryCodeLength;
    const digitCount = digitsOnly.length;
    const formatWithoutCountryCode = format.slice(countryCodeLength + 1);

    // If there are more digits than placeholders or less than 7 digits, return the digits only
    if (digitCount > placeholderCount || digitCount < 7) {
      return digitsOnly;
    }

    let formattedPhoneNumber = '';
    let digitIndex = 0;

    for (const char of formatWithoutCountryCode) {
      if (char === '.') {
        if (digitsOnly[digitIndex]) {
          formattedPhoneNumber += digitsOnly[digitIndex];
        }
        digitIndex++;
      } else {
        if (digitIndex === 0) {
          if (char === ' ' || char === '-') {
            continue;
          }
        }
        formattedPhoneNumber += char;
      }
    }

    // Append remaining digits if any
    if (digitIndex < digitsOnly.length) {
      formattedPhoneNumber += digitsOnly.slice(digitIndex);
    }

    return formattedPhoneNumber;
  };
  React.useEffect(() => {
    if (!phoneNumber || !country.format) return;

    const formattedNumber = formatPhoneNumberInput(
      phoneNumber,
      country.format,
      countryCode,
    );

    setValue('phoneNumber', formattedNumber, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [phoneNumber, country.format, country.dialCode, setValue, countryCode]);

  return (
    <FormGroup>
      <Grid direction="row" columnSpacing={2} container>
        <Grid item>
          <Autocomplete
            sx={{ minWidth: '10rem' }}
            id="countryCodeSelect"
            onChange={countryOnChange}
            options={uniqueDialCodes}
            autoHighlight
            getOptionLabel={option => option.dialCode && `+${option.dialCode}`}
            value={country}
            disabled={disableInput}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                +{option.dialCode} ({getUnicodeFlagIcon(option.iso2)})
              </Box>
            )}
            renderInput={params => (
              <Input
                {...params}
                size={props.size}
                name="countryCodeSelect"
                label={capitalize(t('countryCode'))}
                isError={hasError}
                error={hasError}
                HelperTextIcon={InfoOutlined}
                helperText={errorMessage}
              />
            )}
          />
        </Grid>
        <Grid item sx={{ flex: '1 1 auto' }}>
          <ControlledInput
            value={phoneNumber}
            watch={watch}
            type="text"
            register={register}
            errors={errors.phoneNumber}
            name="phoneNumber"
            label="Phone Number"
            disabled={disableInput}
            placeholder={capitalize(t('stakeholder.phoneField'))}
            size={props.size}
            otherRules={disableInput ? {} : phoneNumberRules}
          />
        </Grid>
      </Grid>
    </FormGroup>
  );
};
