export default {
  contentLiStyles: {
    display: 'flex',
  },
  accordionSummaryStyles: {
    border: 0,
    background: 'inherit',
    justifyContent: 'space-between',
    '&.Mui-expanded': {
      minHeight: '48px',
    },
    '.MuiAccordionSummary-expandIconWrapper': {
      color: 'primary.main', //'primary.main-secondary'
    },
    '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '.MuiAccordionSummary-content': {
      margin: '1px 0',
    },
    '.MuiAccordionSummary-content.Mui-expanded': {
      margin: '1px 0',
    },
  },
  accordionStyles: {
    borderTop: '6px solid',
    borderTopColor: 'primary.main',
    width: '100%',
    borderRadius: '4px',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      marginTop: 0,
    },
  },
  accordionDetailsStyles: {
    background: 'inherit',
    '.MuiAccordion-root.Mui-expanded': {
      marginBottom: '0',
    },
  },
};
