const typography = {
  h1: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '96px',
    lineHeight: '112px',
    letterSpacing: '-1.5px',
  },
  h2: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '60px',
    lineHeight: '72px',
    letterspacing: '-0.5px',
  },
  h3: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '56px',
    '@media(max-width: 480px)': {
      fontSize: '24px',
      textAlign: 'center',
    },
  },
  h4: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '34px',
    lineHeight: '42px',
    letterSpacing: '0.25px',
  },
  h5: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32px',
    '@media(max-width: 480px)': {
      fontSize: '16px',
    },
  },
  h6: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.15px',
  },
  h7: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '26px',
    letterSpacing: '0.15px',
  },
  subtitle1: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.15px',
  },
  subtitle2: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normalize',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.1px',
  },
  body1: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    '@media(max-width: 480px)': {
      fontSize: '16px',
    },
  },
  bodySecondary: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'secondary.main',
  },
  bodySmallSecondary: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.17px',
    color: 'secondary.main',
  },
  bodyAccent: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'primary.main',
  },
  body2: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.17px',
  },
  buttonLarge: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
  },
  buttonMedium: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
  },
  menuItemDense: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.17px',
  },
  buttonSmall: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '22px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
  },
  caption: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
  },
  overlineFont: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '32px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  avatarLetter: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '20px',
    letterSpacing: '0.14px',
  },
  inputLabel: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.15px',
  },
  helperText: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
  },
  inputText: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  tooltip: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '14px',
  },
  subtitle3: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.15px',
  },
  listSubHeader: {
    fontFamily: 'RobotoRegular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '48px',
    letterSpacing: '0.1px',
    align: 'center',
  },
};

export type TypographysType = typeof typography;

export default typography;
