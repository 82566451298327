/**
 *
 * Asynchronously loads the component for ManageUserAccessPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ManageUserAccessPage = lazyLoad(
  () => import('./index'),
  module => module.ManageUserAccessPage,
);
