import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';

import { useUser } from 'providers/UserProvider';
import { getRoutePath } from 'app/routes/routePath';
import { useAuth0 } from '@auth0/auth0-react';
import { useSignupSlice } from './features/signup-slice';
import { useDispatch } from 'react-redux';

export const UssSignIn = () => {
  const { actions } = useSignupSlice();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { clearSigninState, isFetching, isSuccess } = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const target = location.state
      ? (location.state as { from: string }).from
      : getRoutePath({ target: 'userHome' });

    if (isAuthenticated && isSuccess && !isFetching) {
      navigate(target);
    }
  }, [
    isAuthenticated,
    navigate,
    clearSigninState,
    location,
    isSuccess,
    isFetching,
  ]);

  useEffect(() => {
    if (!isAuthenticated && !isFetching) {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, isFetching, navigate]);

  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{capitalize(t('signIn.helmet.signin'))}</title>
        <meta name="description" content="MyFO application sign-in" />
      </Helmet>
    </>
  );
};
