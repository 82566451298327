import React from 'react';
import { useUser } from 'providers/UserProvider';
import { Typography, Grid, Card, CardContent, Box, Table } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { Helmet } from 'react-helmet-async';
import style from './style';
import moment from 'moment';
import { UserInformationstripeForm } from 'app/components/Forms/UserInformationStripeForm';
import { BottomRightGroupButtons } from 'app/components/Buttons/BottomRightGroupButtons';
import { muiTheme } from 'styles/muiTheme';
import { Modal } from 'app/components';
import { useForm } from 'react-hook-form';
import { useStripeSlice } from 'app/features/StripeSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  StripePaymentHistory,
  StripeUserBasicInfo,
} from 'app/features/StripeSlice/types';
import {
  selectGetCardDetails,
  selectGetPaymentHistoryDetails,
  selectGetStripeCustomer,
  selectUpdateCardCustomer,
} from 'app/features/StripeSlice/selectors';
import { CustomTablePagination } from 'app/components/CustomTablePagination';
import { StripePaymentHistoryTable } from 'app/components/StripePaymentHistoryTable';

export function PaymentTab() {
  const { user, isFetching } = useUser();
  const dispatch = useDispatch();
  const { actions } = useStripeSlice();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const getContactSuccess = useSelector(selectGetStripeCustomer);
  const getCardSuccess = useSelector(selectGetCardDetails);
  const getCardUpdateUrl = useSelector(selectUpdateCardCustomer);
  const paymentHistory = useSelector(selectGetPaymentHistoryDetails);
  const [tablePage, setTablePage] = React.useState(0);
  const [tableItemsPerPage, setTableItemsPerPage] = React.useState(5);
  const [contactDetails, setContactDetails] =
    React.useState<StripeUserBasicInfo>();
  const [paymentHistoryDetails, setPaymentHistoryDetails] =
    React.useState<StripePaymentHistory[]>();

  const useFormInstance = useForm({
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    formState: { isValid },
    reset,
  } = useFormInstance;
  const displayName =
    (contactDetails && contactDetails?.name) || contactDetails?.email;
  const displayAddress =
    contactDetails && contactDetails?.address && contactDetails.address.line1
      ? `${contactDetails.address.line1} ${contactDetails.address.line2} ${contactDetails.address.city} ${contactDetails.address.state} ${contactDetails.address.country} ${contactDetails.address.postalCode} `
      : capitalize(t('paymentTab.address.noAddress'));

  React.useEffect(() => {
    const startSlice = tablePage * tableItemsPerPage;
    const endSlice = startSlice + tableItemsPerPage;
    const filteredStakeholderList = paymentHistory.slice(startSlice, endSlice);
    setPaymentHistoryDetails(filteredStakeholderList);
  }, [paymentHistory, tableItemsPerPage, tablePage, user?._id]);
  React.useEffect(() => {
    const customerId = user?.account?.customerId;
    const paymentMethodId = user?.account?.paymentMethod;

    if (customerId) {
      dispatch(actions.getStripeAccountDetails({ customerId }));
      dispatch(actions.getStripePaymentDetails({ customerId }));
    }

    if (paymentMethodId && !getCardSuccess) {
      dispatch(actions.getStripeCardDetails({ paymentMethodId }));
    }

    if (customerId && !getCardUpdateUrl) {
      dispatch(actions.updateStripeCardDetails({ customerId }));
    }

    if (customerId && !openModal) {
      dispatch(actions.getStripeAccountDetails({ customerId }));
    }
  }, [
    actions,
    dispatch,
    getCardSuccess,
    getCardUpdateUrl,
    user?.account?.customerId,
    openModal,
    user?.account?.paymentMethod,
  ]);

  React.useEffect(() => {
    if (getContactSuccess) {
      setContactDetails(getContactSuccess);
      reset({
        firstName: getContactSuccess?.name?.split(' ')[0],
        lastName: getContactSuccess?.name?.split(' ')[1],
        phoneNumber: getContactSuccess.phone,
        countryCode: getContactSuccess.countryCode,
        state: getContactSuccess?.address?.state,
        city: getContactSuccess?.address?.city,
        postalCode: getContactSuccess?.address?.postalCode,
        line1: getContactSuccess?.address?.line1,
        line2: getContactSuccess?.address?.line2,
        country: getContactSuccess?.address?.country,
      });
    }
  }, [getContactSuccess, paymentHistory, reset]);

  const onSubmit = ({ ...values }) => {
    const payload = {
      ...values,
      phone: values['phoneNumber'],
      customerId: user?.account?.customerId,
    };
    dispatch(actions.updateStripeAccountDetails(payload));
    if (!getContactSuccess) {
      const customerId = user?.account?.customerId;
      dispatch(actions.getStripeAccountDetails({ customerId }));
    }
    setOpenModal(false);
  };

  const handleSetOpenModal = () => {
    setOpenModal(false);
  };

  const handleOpenModel = () => {
    setOpenModal(true);
  };

  const handleCardChange = () => {
    if (getCardUpdateUrl) window.open(getCardUpdateUrl?.Location, '_blank');
  };

  const paymentCard = `${capitalize(
    getCardSuccess?.card?.brand ?? '',
  )} ${capitalize(t('paymentTab.paymentdetails.starMarked'))} ${
    getCardSuccess?.card?.last4
  }, ${getCardSuccess?.card?.exp_month}/${getCardSuccess?.card?.exp_year}`;

  let loadingMessage = t('loading.header');
  if (isFetching) {
    loadingMessage = t('loading.body2');
  } else if (!user) {
    loadingMessage = t('loading.error', { message: 'User data not found' });
  }

  return (
    <>
      <Helmet>
        <title>{capitalize(t('profile.helmet.profilePage'))}</title>
        <meta name="description" content="MyFO application user profile" />
      </Helmet>
      <Modal
        contentSx={style.modalstyles}
        isOpen={openModal}
        onClose={handleSetOpenModal}
      >
        <Box my={2} component="form" onSubmit={handleSubmit(onSubmit)}>
          <UserInformationstripeForm useFormInstance={useFormInstance} />
          <Box sx={muiTheme.variants.boxBottomButtonsDefault}>
            <BottomRightGroupButtons
              onPrimaryBtnClick={handleSetOpenModal}
              primaryBtnText={capitalize(t('cancelButton'))}
              secondaryBtnText={capitalize(t('payment.buttons.changeAddress'))}
              secondaryBtnDisabled={!isValid}
            />
          </Box>
        </Box>
      </Modal>
      <Grid spacing={1}>
        <Grid item md={true}>
          {(isFetching || !user) && (
            <div id="isLoading">{capitalize(loadingMessage)}</div>
          )}
          {!isFetching && user && (
            <Box sx={{ display: 'flex' }}>
              <Grid item xs={6}>
                <Card sx={style.cardStyles}>
                  <Typography variant="h6" sx={style.heading}>
                    {capitalize(t('paymentTab.address.header'))}
                  </Typography>
                  <CardContent>
                    <Grid container sx={style.gridStyles}>
                      <Grid item xs={3}>
                        <Typography variant="body1" color="text.secondary">
                          {capitalize(t('paymentTab.name.text'))}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">{displayName}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container sx={style.gridStyles}>
                      <Grid item xs={3}>
                        <Typography variant="body1" color="text.secondary">
                          {capitalize(t('paymentTab.address.text'))}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          {displayAddress}
                        </Typography>
                      </Grid>
                    </Grid>
                    <BottomRightGroupButtons
                      buttonGroupStyles="left"
                      secondaryBtnText={capitalize(
                        t('payment.buttons.changeAddress'),
                      )}
                      onSecondaryBtnClick={handleOpenModel}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card sx={style.cardStyles}>
                  <Typography variant="h6" sx={style.heading}>
                    {capitalize(t('paymentTab.payment.header'))}
                  </Typography>
                  <CardContent>
                    <Grid container sx={style.gridStyles}>
                      <Grid item xs={8}>
                        {!getCardSuccess ? (
                          <Typography variant="body1">
                            {capitalize(t('paymentTab.paymentdetails.nocard'))}
                          </Typography>
                        ) : (
                          <Typography variant="body1">
                            {`${capitalize(getCardSuccess.card?.brand)}
                            ${capitalize(
                              t('paymentTab.paymentdetails.starMarked'),
                            )}
                              ${getCardSuccess.card?.last4},
                              ${getCardSuccess.card?.exp_month}/
                              ${getCardSuccess.card?.exp_year}`}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>

                    <Typography variant="body1">
                      {capitalize(t('paymentTab.paymentdetails.text'))}
                    </Typography>

                    <Typography variant="body1">
                      {capitalize(t('paymentTab.paymentdetails.subtext'))}
                      {moment(user?.account?.expiresAt).format('MMM DD,YYYY')}
                    </Typography>
                    <BottomRightGroupButtons
                      buttonGroupStyles="left"
                      secondaryBtnText={capitalize(
                        t('payment.buttons.changePaymentMethod'),
                      )}
                      onSecondaryBtnClick={handleCardChange}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid sx={style.containerSx}>
        <Typography variant="h5">
          {t('paymentHistory.table.heading')}
        </Typography>

        {paymentHistoryDetails?.length !== 0 ? (
          <CustomTablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={10}
            onPageChange={setTablePage}
            onRowsPerPageChange={setTableItemsPerPage}
            labelRowsPerPage={capitalize(t('taskManager.labels.itemsPerPage'))}
          >
            <StripePaymentHistoryTable list={paymentHistoryDetails} />
          </CustomTablePagination>
        ) : (
          <Box sx={style.containerSx}>
            <Typography variant="body1" color="secondary.main">
              {t('paymentHistory.table.noInvoices')}
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  );
}
