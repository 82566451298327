import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Card, Divider, Stack, Typography } from '@mui/material';
import { ImpersonateUserConnection } from 'app/features/AddressBookSlice/types';
import { CustomTablePagination } from 'app/components/CustomTablePagination';
import { capitalize } from '@mui/material/utils';
import style from './style';
import { Button, Modal } from 'app/components';
import {
  selectContactImpersonatedList,
  selectloadImpersonatedClient,
} from 'app/features/AddressBookSlice/selectors';
import { useAddressBookSlice } from 'app/features/AddressBookSlice';
import { useUser } from 'providers/UserProvider';
import { useUserTasksSlice } from 'app/features/UserTasksSlice';
import { useGroupSlice } from 'app/features/GroupSlice';
import { AdvisorTable } from 'app/components/Advisors/AdvisorTable';
import typographys from 'styles/typographys';
import { ReactComponent as AlertCircleIcon } from 'app/assets/icons/alert-circle.svg';
import { AddClient } from 'app/components/Advisors/AddClient';
import { getRoutePath } from 'app/routes/routePath';
import { ModalDeleteClient } from 'app/components/Modal/ModalDeleteClient/Loadable';
import { muiTheme } from 'styles/muiTheme';

export function AdvisorPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useUser();
  const { state } = useLocation();
  const { actions } = useAddressBookSlice();
  const { actions: userTaskActions } = useUserTasksSlice();
  const { actions: groupActions } = useGroupSlice();
  const clients = useSelector(selectContactImpersonatedList);
  const loadImpersonateUser = useSelector(selectloadImpersonatedClient);
  const [tablePage, setTablePage] = useState(0);
  const [tableItemsPerPage, setTableItemsPerPage] = useState(5);
  const [clientList, setClientList] = useState<ImpersonateUserConnection[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addClientModal, setClientModal] = useState(false);
  const [viewClient, setViewClient] = useState(false);
  const [goToConsolidation, setGoToConsolidation] = useState(false);
  const [currentClient, setcurrentClient] =
    useState<ImpersonateUserConnection>();
  const [deleteClient, setdeleteClient] = useState<ImpersonateUserConnection>();
  React.useEffect(() => {
    const startSlice = tablePage * tableItemsPerPage;
    const endSlice = startSlice + tableItemsPerPage;
    const filteredStakeholderList = clients.slice(startSlice, endSlice);
    setClientList(filteredStakeholderList);
  }, [clients, tableItemsPerPage, tablePage, user?._id]);

  const goToAddClient = () => {
    setcurrentClient(undefined);
    setClientModal(true);
  };
  const goToConsolidationView =
    (client: ImpersonateUserConnection) => async () => {
      const payload = { userId: client?.connectionManager };
      dispatch(actions.loadImpersonatedClient());
      dispatch(actions.getImpersonateToken(payload));
      dispatch(actions.setImpersonatedUser(client));
      setGoToConsolidation(true);
    };
  const goToEditUserInformation = (client: ImpersonateUserConnection) => () => {
    setcurrentClient(client);
    setClientModal(true);
    setViewClient(false);
    dispatch(actions.getContactImpersonatedSummaryList());
  };

  const goToViewUserInformation = (client: ImpersonateUserConnection) => () => {
    setcurrentClient(client);
    setClientModal(true);
    setViewClient(true);
    dispatch(actions.getContactImpersonatedSummaryList());
  };

  // handle modal
  const deleteOnClick = (client: ImpersonateUserConnection) => () => {
    setdeleteClient(client);
    setModalIsOpen(true);
  };

  const handleRemoveClient = async () => {
    if (deleteClient) {
      const payload = {
        clientId: deleteClient._id,
      };
      dispatch(actions.deleteImpersonateConnection(payload));
    }
    dispatch(actions.getContactImpersonatedSummaryListCleanUp());
    setModalIsOpen(false);
  };

  React.useEffect(() => {
    setdeleteClient(undefined);
    setModalIsOpen(false);
    dispatch(userTaskActions.cleanUpTasks());
    dispatch(groupActions.cleanupGroupOperation());
    if (loadImpersonateUser && goToConsolidation) {
      dispatch(actions.getContactSummaryListCleanUp());
      sessionStorage.setItem('userImpersonated', 'true');
      navigate(
        getRoutePath({
          target: 'consolidation',
          pathParams: { groupId: '' },
        }),
      );
    }
    if (state?.openModel) {
      state.openModel = false;
      setClientModal(true);
    }
  }, [
    actions,
    dispatch,
    groupActions,
    loadImpersonateUser,
    navigate,
    addClientModal,
    goToConsolidation,
    userTaskActions,
    state?.openModel,
    state,
  ]);
  // End handle modal

  React.useEffect(() => {
    dispatch(actions.getContactImpersonatedSummaryList());
  }, [actions, dispatch, addClientModal]);

  return (
    <Card sx={style.cardContainerStyles}>
      <ModalDeleteClient
        isOpenModal={modalIsOpen}
        deleteClient={deleteClient}
        onCancel={() => setModalIsOpen(false)}
        onDelete={handleRemoveClient}
      />
      <Modal
        contentSx={style.modelStyles}
        isOpen={addClientModal}
        onClose={() => setClientModal(false)}
      >
        <AddClient
          viewClient={viewClient}
          client={currentClient}
          onClose={() => setClientModal(false)}
        ></AddClient>
      </Modal>
      <Stack direction="row" justifyContent="space-between" gap={4}>
        <Box sx={style.headerContainerStyles}>
          <Typography variant="h4">{t('advisors.title')}</Typography>
        </Box>
        {clientList?.length !== 0 && (
          <Button
            label={capitalize(t('addNew'))}
            variant="contained"
            onClick={goToAddClient}
            sx={{
              ...style.defaultButtonStyles,
              ...typographys.buttonLarge,
            }}
          ></Button>
        )}
      </Stack>
      {clients?.length !== 0 ? (
        <CustomTablePagination
          rowsPerPageOptions={[5, 10, 15]}
          count={clients?.length}
          onPageChange={setTablePage}
          onRowsPerPageChange={setTableItemsPerPage}
          labelRowsPerPage={capitalize(t('taskManager.labels.itemsPerPage'))}
        >
          <AdvisorTable
            clientList={clientList}
            viewOnClick={goToViewUserInformation}
            viewConsolidation={goToConsolidationView}
            editOnClick={goToEditUserInformation}
            deleteOnClick={deleteOnClick}
          />
        </CustomTablePagination>
      ) : (
        <Box sx={style.containerSx}>
          <Box sx={style.noClientStyles}>
            <AlertCircleIcon
              width={15}
              height={15}
              stroke={muiTheme.palette.secondary.main}
            />
            <Typography variant="body1" color="secondary.main">
              {t('advisors.noClients')}
            </Typography>
            <Button
              label={capitalize(t('advisors.getStarted'))}
              variant="contained"
              onClick={goToAddClient}
              sx={{
                ...style.defaultButtonStyles,
                ...typographys.buttonLarge,
              }}
            ></Button>
          </Box>
          <Divider sx={style.divider}></Divider>
          <Typography sx={style.bottomText} variant="body1">
            {t('advisors.text')}
          </Typography>
        </Box>
      )}
    </Card>
  );
}
