/**
 *
 * Asynchronously loads the component for TaskManagerPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const TaskManagerPage = lazyLoad(
  () => import('./index'),
  module => module.TaskManagerPage,
);
