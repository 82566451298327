/**
 *
 * Asynchronously loads the component for DashboardPage
 *
 */

import { SuspenseSpinner } from 'app/components/SuspenseSpinner';
import { lazyLoad } from 'utils/loadable';

export const DashboardPage = lazyLoad(
  () => import('./index'),
  module => module.DashboardPage,
  {
    fallback: <SuspenseSpinner type="linear" color="secondary" />,
  },
);
