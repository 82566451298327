import { createContext, ReactElement, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CurrencyStateProps, CurrencyUpdaterProps } from './types';
import { useChangeBaseCurrency } from './updaters';

const defaultCurrencyState: CurrencyStateProps = {
  base: 'USD',
  isFetchingBaseCurrency: false,
  // temporary solution for cached rates
  rates: {
    USD: 1,
    AED: 3.6725,
    AFN: 87.6247,
    ALL: 114.3626,
    AMD: 456.5248,
    ANG: 1.79,
    AOA: 406.88,
    ARS: 117.23,
    AUD: 1.4469,
    AWG: 1.79,
    AZN: 1.6974,
    BAM: 1.8805,
    BBD: 2,
    BDT: 85.988,
    BGN: 1.8806,
    BHD: 0.376,
    BIF: 2019.2024,
    BMD: 1,
    BND: 1.3911,
    BOB: 6.8624,
    BRL: 5.124,
    BSD: 1,
    BTN: 77.5373,
    BWP: 12.3647,
    BYN: 2.7738,
    BZD: 2,
    CAD: 1.2948,
    CDF: 1991.3235,
    CHF: 1.0019,
    CLP: 866.2442,
    CNY: 6.8035,
    COP: 4117.4503,
    CRC: 671.5907,
    CUP: 24,
    CVE: 106.018,
    CZK: 23.7707,
    DJF: 177.721,
    DKK: 7.173,
    DOP: 55.0463,
    DZD: 146.0886,
    EGP: 18.3762,
    ERN: 15,
    ETB: 51.5754,
    EUR: 0.9615,
    FJD: 2.2067,
    FKP: 0.8187,
    FOK: 7.173,
    GBP: 0.8187,
    GEL: 3.0127,
    GGP: 0.8187,
    GHS: 7.9248,
    GIP: 0.8187,
    GMD: 54.0639,
    GNF: 8844.8501,
    GTQ: 7.6502,
    GYD: 209.0487,
    HKD: 7.8515,
    HNL: 24.5565,
    HRK: 7.2443,
    HTG: 110.5544,
    HUF: 369.916,
    IDR: 14584.0825,
    ILS: 3.4094,
    IMP: 0.8187,
    INR: 77.5388,
    IQD: 1458.5835,
    IRR: 41937.414,
    ISK: 134.6278,
    JEP: 0.8187,
    JMD: 155.0456,
    JOD: 0.709,
    JPY: 129.3388,
    KES: 116.3203,
    KGS: 83.096,
    KHR: 4053.5518,
    KID: 1.4468,
    KMF: 473.019,
    KRW: 1281.5884,
    KWD: 0.2996,
    KYD: 0.8333,
    KZT: 433.4679,
    LAK: 14141.4132,
    LBP: 1507.5,
    LKR: 357.3263,
    LRD: 151.5173,
    LSL: 16.1357,
    LYD: 4.794,
    MAD: 9.9623,
    MDL: 18.962,
    MGA: 3996.8216,
    MKD: 59.3003,
    MMK: 1835.0546,
    MNT: 3112.1921,
    MOP: 8.0871,
    MRU: 36.4285,
    MUR: 42.7704,
    MVR: 15.4099,
    MWK: 819.4098,
    MXN: 20.1454,
    MYR: 4.3868,
    MZN: 64.6774,
    NAD: 16.1357,
    NGN: 414.8779,
    NIO: 35.8355,
    NOK: 9.7969,
    NPR: 124.0596,
    NZD: 1.5973,
    OMR: 0.3845,
    PAB: 1,
    PEN: 3.7717,
    PGK: 3.5227,
    PHP: 52.4293,
    PKR: 191.4094,
    PLN: 4.4906,
    PYG: 6734.3029,
    QAR: 3.64,
    RON: 4.7396,
    RSD: 112.8633,
    RUB: 64.3639,
    RWF: 1052.2527,
    SAR: 3.75,
    SBD: 7.9731,
    SCR: 13.4681,
    SDG: 451.6006,
    SEK: 10.085,
    SGD: 1.3911,
    SHP: 0.8187,
    SLL: 12671.705,
    SOS: 578.0192,
    SRD: 20.8507,
    SSP: 430.1374,
    STN: 23.5564,
    SYP: 2478.5063,
    SZL: 16.1357,
    THB: 34.801,
    TJS: 12.4814,
    TMT: 3.499,
    TND: 2.8444,
    TOP: 2.3255,
    TRY: 15.503,
    TTD: 6.7651,
    TVD: 1.4468,
    TWD: 29.7324,
    TZS: 2323.3485,
    UAH: 29.9286,
    UGX: 3609.3754,
    UYU: 41.6862,
    UZS: 11143.4949,
    VES: 4.6914,
    VND: 23091.9863,
    VUV: 116.5989,
    WST: 2.6634,
    XAF: 630.692,
    XCD: 2.7,
    XDR: 0.7502,
    XOF: 630.692,
    XPF: 114.7358,
    YER: 250.2658,
    ZAR: 16.1358,
    ZMW: 17.0073,
    ZWL: 246.2416,
  },
  timeLastFetched: null,
  changeBaseCurrencyError: null,
};

const defaultUpdaters: CurrencyUpdaterProps = {
  changeBaseCurrency: () => {},
};

const selectedCurrency = localStorage.getItem('currencyState');
const selectedCurrencyState = selectedCurrency
  ? JSON.parse(selectedCurrency)
  : defaultCurrencyState;

export const CurrencyContext = createContext<
  CurrencyStateProps & CurrencyUpdaterProps
>({
  ...selectedCurrencyState,
  ...defaultUpdaters,
});

export const CurrencyProvider = ({ children }): ReactElement => {
  const [currencyState, setCurrencyState] = useState<CurrencyStateProps>(
    selectedCurrencyState,
  );
  const dispatch = useDispatch();

  const wrapFunc = (func, isDispatch = false) =>
    function (...args) {
      // @ts-ignore
      const context = this;
      const changedArgs = [...args];
      changedArgs.push(currencyState, setCurrencyState);
      if (isDispatch) {
        changedArgs.push(dispatch);
      }
      func.apply(context, changedArgs);
    };

  return (
    <CurrencyContext.Provider
      value={{
        ...currencyState,
        changeBaseCurrency: wrapFunc(useChangeBaseCurrency, true),
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export function useCurrency(): CurrencyStateProps & CurrencyUpdaterProps {
  const context = useContext(CurrencyContext);
  if (context === undefined) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
}

export function useCurrencyState(): CurrencyStateProps {
  const { changeBaseCurrency, ...currencyState } = useCurrency();
  return currencyState;
}
