import moment from 'moment';
import {
  Item,
  SupportedItemTypes,
} from '../app/features/AssetLiabilitySlice/types';
import { GroupCalculations, GroupData } from '../app/features/GroupSlice/types';
import {
  currencyConversion,
  formatCurrencyToLocale,
} from '../app/components/CurrencyValue';
import { CurrencyStateProps } from 'providers/CurrencyProvider/types';
import { cashClasses, liquidityClasses } from 'constants/asset-liability';
import {
  calcPercent,
  getPositionsValue,
  getPositionsValueWithCash,
} from './assetValues';
import { AssetConst } from 'constants/keyValue';

export type ItemWithBaseContext = Item & {
  baseContext: { value: number; assetClass: string };
};

type ExtractCalcFromItemsProps = {
  currencyState: CurrencyStateProps;
  filteredGroups: GroupData[];
};

export const getGroupItemValue =
  (groupPercentage: string | number, isConsolidated: boolean) =>
  (value: string | number) =>
    isConsolidated
      ? calcPercent(Number(value) || 0, Number(groupPercentage))
      : Number(value) || 0;

const getReturnRecordIndex = (
  recordHistory: Record<string, unknown>[],
): { quaterlyRecord: number; annualRecord: number } => {
  const historyLenght = recordHistory.length;
  if (!historyLenght) return { quaterlyRecord: -1, annualRecord: -1 };
  let quaterlyRecord: number | undefined;
  let annualRecord: number | undefined;
  const annualDate = moment().startOf('day').subtract(1, 'year');
  const quaterlyDate = moment().startOf('day').subtract(90, 'days');
  const lastRecordDate = moment(
    recordHistory[historyLenght - 1].date as string,
    'MM/DD/YYYY',
  ).startOf('day');
  if (annualRecord === undefined && lastRecordDate.isBefore(annualDate)) {
    annualRecord = historyLenght - 1;
  }
  if (quaterlyRecord === undefined && lastRecordDate.isBefore(quaterlyDate)) {
    quaterlyRecord = historyLenght - 1;
  }
  recordHistory.some((record, index) => {
    const recordDate = moment(record.date as string, 'MM/DD/YYYY').startOf(
      'day',
    );
    if (annualRecord === undefined && recordDate.isAfter(annualDate)) {
      annualRecord = index - 1;
    }
    if (quaterlyRecord === undefined && recordDate.isAfter(quaterlyDate)) {
      quaterlyRecord = index - 1;
    }
    if (annualRecord !== undefined && quaterlyRecord !== undefined) {
      return true;
    }
    return false;
  });
  if (annualRecord === undefined) {
    annualRecord = -1;
  }
  if (quaterlyRecord === undefined) {
    quaterlyRecord = -1;
  }
  return { quaterlyRecord, annualRecord };
};

// TODO: Review this function as it is adding a bunch of processing time to the calculation
/**
 * The function `extractCalcFromItems` calculates various financial metrics based on the provided data,
 * such as asset value, liability value, leverage value, available liquidity, annual return, quarterly
 * return, available cash, total commitment, and remaining commitment.
 * @param {ExtractCalcFromItemsProps}  - - `currencyState`: The state of the currency, which includes
 * the base currency and exchange rates.
 * @returns an object of type `GroupCalculations`. The properties of this object include:
 * - `total_net_worth`: The total net worth calculated based on the asset and liability values.
 * - `available_liquidity`: The available liquidity calculated based on the asset values.
 * - `available_leverage`: The available leverage calculated based on the asset values and leverage
 * percentage.
 * - `totalCommit
 */
//TODO: If we change the implementation here:
//Please also update the corresponding validations on: components/report/utils/tables.utils.ts
//Please also note that this function is being copied over on: features/modeling-slice/utils/index.ts
export const extractCalcFromItems = ({
  currencyState,
  filteredGroups,
}: ExtractCalcFromItemsProps): GroupCalculations => {
  let assetValue = 0;
  let assetValueLastYear = 0;
  let assetValueQuarterly = 0;
  let liabilityValue = 0;
  let liabilityValueLastYear = 0;
  let liabilityValueQuarterly = 0;
  let leverageValue = 0;
  let availableLiquidity = 0;
  let annualReturn = 0;
  let quarterlyReturn = 0;
  let availableCash = 0;
  let totalCommitment = 0;
  let remainingCommitment = 0;
  filteredGroups.forEach((group: GroupData) => {
    const groupPercentage = Number(group.groupStakePercentage);
    const getGroupItemPercentValue = getGroupItemValue(
      groupPercentage,
      filteredGroups.length > 1,
    );
    group?.items.forEach((item: Item) => {
      const itemContext = item.baseContext;
      // TODO: Review this logic, may be the culprit. Memoize maybe ?
      const convertedAssetValue = getGroupItemPercentValue(
        item.baseContext.renderValue,
      );
      const convertedTotalCommitment = getGroupItemPercentValue(
        itemContext?.renderCapitalCommitment,
      );
      const convertedRemainingCommitment = getGroupItemPercentValue(
        itemContext?.renderRemainingCommitment,
      );
      const itemValueHistory = (item.baseContext.valueHistory || []) as Record<
        string,
        unknown
      >[];
      const { quaterlyRecord, annualRecord } =
        getReturnRecordIndex(itemValueHistory);
      const convertedQAssetValue =
        quaterlyRecord !== -1
          ? getGroupItemPercentValue(
              currencyConversion(
                currencyState,
                item.baseContext.currency,
                itemValueHistory[quaterlyRecord].value as string,
              ),
            )
          : convertedAssetValue;
      const convertedAAssetValue =
        annualRecord !== -1
          ? getGroupItemPercentValue(
              currencyConversion(
                currencyState,
                item.baseContext.currency,
                itemValueHistory[annualRecord].value as string,
              ),
            )
          : convertedAssetValue;
      if (
        item.itemType === SupportedItemTypes.GROUP_ASSET &&
        itemContext.type !== AssetConst.PUBLIC_EQUITY
      ) {
        assetValue += convertedAssetValue;
        assetValueLastYear += convertedAAssetValue;
        assetValueQuarterly += convertedQAssetValue;

        if (itemContext.isLeverageable) {
          leverageValue += calcPercent(
            convertedAssetValue,
            Number(itemContext.leveragePercentage),
            false,
          );
        }
        if (itemContext.type && cashClasses.includes(itemContext.type)) {
          availableCash += convertedAssetValue;
        }
        if (
          itemContext.type &&
          liquidityClasses.includes(itemContext.type) &&
          item.createdBy === 'system'
        ) {
          availableLiquidity += convertedAssetValue;
        }
        if (itemContext.totalCommitment) {
          totalCommitment += convertedTotalCommitment;
        }
        if (itemContext.remainingCommitment) {
          remainingCommitment += convertedRemainingCommitment;
        }
      }
      if (
        itemContext.type === AssetConst.PUBLIC_EQUITY &&
        itemContext?.positions &&
        itemContext?.positions.length
      ) {
        const totalValue = getGroupItemPercentValue(
          getPositionsValue(itemContext?.positions),
        );

        assetValue += totalValue;
        availableLiquidity += totalValue;

        const totalCashValue = getGroupItemPercentValue(
          getPositionsValueWithCash(itemContext?.positions),
        );

        availableCash += totalCashValue;
      } else if (itemContext.type === AssetConst.PUBLIC_EQUITY) {
        assetValue += convertedAssetValue;
        availableLiquidity += convertedAssetValue;
      }

      if (item.itemType === SupportedItemTypes.GROUP_LIABILITY) {
        liabilityValue += convertedAssetValue;
        liabilityValueLastYear += convertedAAssetValue;
        liabilityValueQuarterly += convertedQAssetValue;
      }
    });
  });

  const totalNetWorthLastYear = assetValueLastYear - liabilityValueLastYear;
  const totalNetWorthQuarterlyYear =
    assetValueQuarterly - liabilityValueQuarterly;
  const totalNetWorthCurrentYear = assetValue - liabilityValue;
  annualReturn =
    totalNetWorthLastYear > 0
      ? (totalNetWorthCurrentYear - totalNetWorthLastYear) /
        totalNetWorthLastYear
      : 0;
  quarterlyReturn =
    totalNetWorthQuarterlyYear > 0
      ? (totalNetWorthCurrentYear - totalNetWorthQuarterlyYear) /
        totalNetWorthQuarterlyYear
      : 0;

  return {
    total_net_worth: formatCurrencyToLocale(
      currencyState.base,
      totalNetWorthCurrentYear,
    ),
    available_liquidity: formatCurrencyToLocale(
      currencyState.base,
      availableLiquidity,
    ),
    available_leverage: formatCurrencyToLocale(
      currencyState.base,
      leverageValue,
    ),
    totalCommitment: formatCurrencyToLocale(
      currencyState.base,
      totalCommitment,
    ),
    remainingCommitment: formatCurrencyToLocale(
      currencyState.base,
      remainingCommitment,
    ),
    annualReturn: annualReturn,
    quarterlyReturn: quarterlyReturn,
    available_cash: formatCurrencyToLocale(currencyState.base, availableCash),
  };
};
