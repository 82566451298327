import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useSearchParams } from 'react-router-dom';

import {
  CheckEmailMessage,
  CreatePasswordForm,
  ResetPasswordForm,
} from './components';

import { ErrorToast, SuccessToast } from 'app/components';
import {
  selectUssIsFetching,
  selectUssInitiatePasswordResetError,
  selectUssIsInitiatePasswordResetSuccess,
  selectUssContinuePasswordResetError,
  selectUssIsContinuePasswordResetSuccess,
} from './features/uss-slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useUssSlice } from './features/uss-slice';

import { capitalize } from '@mui/material/utils';
import { useTranslation } from 'react-i18next';

export const ForgotPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const taskId = searchParams.get('taskId');

  const { actions } = useUssSlice();
  const dispatch = useDispatch();
  const initiateSuccess = useSelector(selectUssIsInitiatePasswordResetSuccess);
  const continueSuccess = useSelector(selectUssIsContinuePasswordResetSuccess);
  const ussIsFetching = useSelector(selectUssIsFetching);

  const initiateError = useSelector(selectUssInitiatePasswordResetError);
  const continueError = useSelector(selectUssContinuePasswordResetError);

  const onPasswordResetSubmit = data => {
    dispatch(actions.initiatePasswordReset(data.email));
  };

  const onCreatePasswordSubmit = data => {
    if (token && taskId) {
      dispatch(
        actions.continuePasswordReset({
          taskId,
          token,
          password: data.password,
          confirm: data.confirm,
        }),
      );
    }
  };

  const { t } = useTranslation();

  function ForgotPassword(): React.ReactElement {
    return initiateSuccess ? (
      <>
        <Helmet>
          <title>{capitalize(t('forgetPassword.title.checkEmail'))}</title>
          <meta name="description" content="MyFO application check-email" />
        </Helmet>
        <CheckEmailMessage />
      </>
    ) : (
      <>
        <Helmet>
          <title>{capitalize(t('forgetPassword.title.resetPassword'))}</title>
          <meta name="description" content="MyFO application reset-password" />
        </Helmet>
        <ErrorToast
          isError={Boolean(initiateError)}
          errorText={initiateError?.message}
        />
        <SuccessToast
          isSuccess={initiateSuccess}
          successText={'Success! Check your email for further instructions.'}
        />
        <ResetPasswordForm onSubmit={onPasswordResetSubmit} />
      </>
    );
  }

  return (
    <>
      {ussIsFetching ? (
        <>{capitalize(t('loadingState'))}</>
      ) : continueSuccess ? (
        <Navigate to="/uss/signin" />
      ) : token && taskId ? (
        <>
          <Helmet>
            <title>
              {capitalize(t('forgetPassword.title.changePassword'))}
            </title>
            <meta
              name="description"
              content="MyFO application change-password"
            />
          </Helmet>
          <ErrorToast
            isError={Boolean(continueError)}
            errorText={continueError?.message}
          />
          <SuccessToast
            isSuccess={continueSuccess}
            successText="Password changed successfully"
          />
          <CreatePasswordForm onSubmit={onCreatePasswordSubmit} />
        </>
      ) : (
        <ForgotPassword />
      )}
    </>
  );
};
