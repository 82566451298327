import axios, { AxiosInstance } from 'axios';

/**
 * Creates an axios instancd if it does not exist.
 * If it exists, return the current created instance
 * @returns {AxiosInstance} Axios instance
 */
export function getInstance(
  baseURL: string,
  unauthAction = true,
  userImpersonated = true,
): AxiosInstance {
  // if (!instance) {
  const instance = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
    baseURL,
  });

  instance.interceptors.request.use(config => {
    const token =
      sessionStorage.userImpersonated === 'true' && userImpersonated
        ? sessionStorage.supertoken
        : sessionStorage.token;
    // TODO: Add token expiry validation here
    if (token && !config.headers!.Authorization) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  instance.interceptors.response.use(
    r => r,
    err => {
      const signinPath = '/uss/signin';
      if (
        err.response.status === 401 &&
        unauthAction &&
        signinPath !== window.location.pathname
      ) {
        window.location.replace(signinPath);
        return;
      }
      return Promise.reject(err);
    },
  );
  return instance;
}

/**
 * The function `getHeaders` retrieves the necessary headers for making API requests, including the
 * authorization token based on whether the user is impersonated or not.
 * @returns The `getHeaders` function returns an object with two properties:
 * 1. 'Content-Type': 'application/json'
 * 2. Authorization: `Bearer `
 */
export const getHeaders = () => {
  const isUserImpersonated =
    sessionStorage.getItem('userImpersonated') === 'true';
  const token = isUserImpersonated
    ? sessionStorage.getItem('supertoken')
    : sessionStorage.getItem('token');

  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};
