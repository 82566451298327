import { useEffect, useMemo, useState, useCallback } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { capitalize } from '@mui/material/utils';
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Grid,
  TextField,
} from '@mui/material';
import { KeyboardArrowDown, Search } from '@mui/icons-material';

import MyfoLogo from 'app/assets/images/myfo-logo-white.png';
import FinanceConfidenceLogo from 'app/assets/images/finance-confidence-logo.svg';
import { useUser, useVerified } from 'providers/UserProvider';
import { selectGroups } from 'app/features/GroupSlice/selectors';
import { getRoutePath } from 'app/routes/routePath';
import config from 'utils/config';
import style from './style';
import financeConfidenceStyle from './financeConfidenceStyle';

export const NavBar = props => {
  const { userToken, user, signOff } = useUser();
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const isVerified = useVerified();
  const { groupId } = useParams();
  const { t } = useTranslation();
  const groups = useSelector(selectGroups);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fullName, setFullName] = useState<string>('');
  const [avatarName, setAvatarName] = useState<string>('');
  const open = Boolean(anchorEl);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    watch,
    reset,
  } = useForm({
    mode: 'onBlur',
  });

  type logoDictionaryType = {
    description: string;
    source: string;
    styleFile: typeof style;
  };

  const logoDictionary = {
    default: {
      description: 'MyFO logo',
      source: MyfoLogo,
      styleFile: style,
    },
    financialconfidence: {
      description: 'Finance Confidence logo',
      source: FinanceConfidenceLogo,
      styleFile: financeConfidenceStyle,
    },
  };

  const [siteHostname] = window.location.hostname.split('.');
  const logoTheme = logoDictionary[siteHostname];
  const logo: logoDictionaryType = logoTheme || logoDictionary.default;
  const selectedAlt = logo.description;
  const selectedSource = logo.source;
  const selectedStyle = logo.styleFile;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOff = useCallback(() => {
    signOff();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, [logout, signOff]);

  const handleClickNavbarOption = (path: string) => {
    if (
      path ===
        getRoutePath({ target: 'stakeholder', pathParams: { groupId: '' } }) &&
      groups?.length === 0
    ) {
      return window.alert(t('navbar.openStakeholderAlert'));
    }
    return navigate(path);
  };

  useEffect(() => {
    if (user) {
      setFullName(`${user?.firstName} ${user?.lastName}`);
      setAvatarName(
        `${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName
          ?.charAt(0)
          .toUpperCase()}`,
      );
    }
  }, [user]);

  const navbarOptions = useMemo(() => {
    const menuItems = [
      // {
      //   text: 'Dashboard',
      //   path: '/user-home/dashboard',
      // },
      {
        text: 'Consolidation',
        path: getRoutePath({
          target: 'consolidation',
          pathParams: { groupId: groupId || '' },
        }),
      },
      {
        text: 'Stakeholder Map',
        path: getRoutePath({
          target: 'stakeholder',
          pathParams: { groupId: groupId || '' },
        }),
      },
      {
        text: 'Vault',
        path: getRoutePath({
          target: 'vault',
          pathParams: { groupId: groupId || '' },
        }),
      },
      {
        text: 'Scenario Modeling',
        path: getRoutePath({
          target: 'scenario-modeling',
          pathParams: { groupId: groupId || '' },
        }),
      },
      {
        text: 'Tasks',
        path: getRoutePath({
          target: 'tasks',
          pathParams: { groupId: groupId || '' },
        }),
      },
    ];
    // if (groupsCount > 1) {
    //   menuItems.push({
    //     text: 'Entities',
    //     path: '/user-home/entities',
    //   });
    // }
    return menuItems;
  }, [groupId]);

  const menuOptions = useMemo(
    () => [
      {
        label: t('navbar.dropdown.profile'),
        action: () => navigate(getRoutePath({ target: 'settings' })),
      },
      {
        label: t('navbar.dropdown.faq'),
        action: () => navigate(getRoutePath({ target: 'faq' })),
      },
      {
        label: t('navbar.dropdown.connectedAccounts'),
        action: () => navigate(getRoutePath({ target: 'accounts' })),
      },
      {
        label: t('navbar.dropdown.logout'),
        action: () => handleSignOff(),
        styles: { color: 'error' },
      },
    ],
    [navigate, t, handleSignOff],
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={selectedStyle.stackContainerStyles}
    >
      <Box sx={style.imgBoxStyles}>
        <Link to="/">
          <img alt={selectedAlt} src={selectedSource} />
        </Link>
      </Box>
      {!logoTheme ? null : (
        /*<Grid item xs={6}>
          <TextField
            name="search"
            placeholder={capitalize(t('navbar.input.search'))}
            sx={style.searchQueryStyles}
            onChange={() => {}}
            value=""
            InputProps={{
              startAdornment: <Search sx={style.searchIconStyles} />,
            }}
          />
        </Grid>*/
        <Stack direction="row" gap={2}>
          {userToken &&
            isVerified &&
            navbarOptions.map(item => (
              <Button
                variant={
                  location.pathname.includes(item.path) ? 'outlined' : 'text'
                }
                key={item.text}
                onClick={() => handleClickNavbarOption(item.path)}
                sx={selectedStyle.menuButtonStyles}
              >
                {item.text}
              </Button>
            ))}
        </Stack>
      )}
      <Stack justifyContent="flex-end">
        {userToken ? (
          <div>
            <Stack direction="row" gap={1} alignItems="center">
              <Button
                id="profile-button"
                aria-controls={open ? 'profile-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
                sx={selectedStyle.profileButtonStyles}
                endIcon={<KeyboardArrowDown />}
              >
                {fullName}
              </Button>
              <Avatar sx={selectedStyle.avatarStyles}>
                <Typography
                  variant="caption"
                  color={!logoTheme ? 'primary.main' : ''}
                >
                  {avatarName}
                </Typography>
              </Avatar>
            </Stack>
            <Menu
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              style={style.menuStyles}
              onClick={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {menuOptions.map(menuOption => (
                <MenuItem key={menuOption.label} onClick={menuOption.action}>
                  {menuOption.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        ) : (
          <Button
            href={config.REACT_APP_CONTACT_US_URL}
            variant="outlined"
            color="primary"
            data-test="navBar-button-contactUs"
            sx={style.contactusButtonStyles}
          >
            <Typography variant="buttonMedium">
              {capitalize(t('navbar.button.contactUs'))}
            </Typography>
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
