import React from 'react';
import { useUser } from 'providers/UserProvider';
import { Typography, Grid, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { Helmet } from 'react-helmet-async';
import style from './style';
import moment from 'moment';

export function SubscriptionTab() {
  const { user, isFetching } = useUser();

  const { t } = useTranslation();

  let loadingMessage = t('loading.header');
  if (isFetching) {
    loadingMessage = t('loading.body2');
  } else if (!user) {
    loadingMessage = t('loading.error', {
      message: t('loading.userNotFoundError'),
    });
  }

  return (
    <>
      <Helmet>
        <title>{capitalize(t('profile.helmet.profilePage'))}</title>
        <meta name="description" content="MyFO application Subscription Tab" />
      </Helmet>
      <Grid spacing={1}>
        <Grid item md={true}>
          {(isFetching || !user) && (
            <div id="isLoading">{capitalize(loadingMessage)}</div>
          )}
          {!isFetching && user && (
            <Card sx={style.cardStyles}>
              <CardContent>
                <Grid container sx={style.gridStyles}>
                  <Grid item xs={5}>
                    <Typography variant="body1" color="text.secondary">
                      {capitalize(t('subcriptionTab.licensespurchased.header'))}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    {/* @todo replace it with a value from advisor flow for no of licenses purchases */}
                    <Typography variant="body1">1</Typography>
                  </Grid>
                </Grid>
                <Grid container sx={style.gridStyles}>
                  <Grid item xs={5}>
                    <Typography variant="body1" color="text.secondary">
                      {capitalize(t('subcriptionTab.licensesallocated.header'))}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    {/* @todo replace it with a dynamic value from api for licences allocated to users who are added as clients under advisors */}
                    <Typography variant="body1">1</Typography>
                  </Grid>
                </Grid>
                <Grid container sx={style.gridStyles}>
                  <Grid item xs={5}>
                    <Typography variant="body1" color="text.secondary">
                      {capitalize(t('subcriptionTab.expirationdate.header'))}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1">
                      {moment(user?.account?.expiresAt).format('MMM DD,YYYY')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={style.gridStyles}>
                  <Grid item xs={5}>
                    <Typography variant="body1" color="text.secondary">
                      {capitalize(t('subcriptionTab.billingperiod.header'))}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body1">
                      {capitalize(t('subcriptionTab.billingperiod.text'))}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
}
