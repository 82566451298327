import { muiTheme } from 'styles/muiTheme';

export const LabelComponentBaseSx = {
  labelBoxSx: {
    marginBottom: '2.75em',
    background: muiTheme.palette.primary.contrast,
    borderRadius: '4px',
    minWidth: '165px',
  },
  textFontSx: {
    ...muiTheme.typography.body2,
    color: 'text.primary',
    alignSelf: 'center',
  },
  titleFontSx: {
    alignSelf: 'center',
    fontWeight: 'normal',
    fontFamily: 'RobotoMedium',
    fontSize: '11px',
    color: 'text.disabled',
    marginBottom: '4px',
  },
};

export const LabelComponentBasicSx = {
  labelBoxSx: {
    ...LabelComponentBaseSx.labelBoxSx,
    marginBottom: '1em',
  },
  titleFontSx: {
    alignSelf: 'center',
    ...muiTheme.typography.body2,
    marginBottom: '4px',
    color: 'text.secondary',
  },
  textFontSx: {
    ...LabelComponentBaseSx.textFontSx,
    ...muiTheme.typography.subtitle1,
  },
};

export const LabelComponentVariantBorder = {
  labelBoxSx: {
    ...LabelComponentBaseSx.labelBoxSx,
    display: 'flex',
    border: muiTheme.variants.basicSolidBorder,
    borderColor: 'secondary.30p',
    padding: '1em 1.25em',
    marginBottom: '0',
    justifyContent: 'space-between',
  },
  titleFontSx: {
    ...LabelComponentBaseSx.titleFontSx,
    ...muiTheme.typography.caption,
    marginBottom: '0',
  },
  textFontSx: {
    ...LabelComponentBaseSx.textFontSx,
    ...muiTheme.typography.body1,
    fontWeight: 500,
    lineHeight: '28px',
  },
};

export const LabelComponentVariantIconWarning = {
  labelBoxSx: {
    display: 'flex',
    alignItems: 'center',
    padding: '1em',
    backgroundColor: muiTheme.palette.addition.iconWarningBackgroundColor,
    borderRadius: '4px',
    border: muiTheme.variants.basicSolidBorder,
    borderColor: 'secondary.30p',
  },
  textFontSx: { ...LabelComponentBaseSx.textFontSx },
  titleFontSx: {
    ...LabelComponentBaseSx.textFontSx,
    marginRight: '10px',
    color: muiTheme.palette.addition.taskManagerDarkGreyColor,
  },
};

export const LabelComponentVariant1 = {
  labelBoxSx: {
    ...LabelComponentBaseSx.labelBoxSx,
    marginRight: '77px',
  },
  textFontSx: { ...LabelComponentBaseSx.textFontSx },
  titleFontSx: { ...LabelComponentBaseSx.titleFontSx, fontSize: '14px' },
};

export const LabelComponentVariant2 = {
  labelBoxSx: {
    ...LabelComponentBaseSx.labelBoxSx,
    marginRight: '0px',
    marginBottom: '0',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '65px',
    alignItems: 'flex-end',
  },
  textFontSx: {
    ...LabelComponentBaseSx.textFontSx,
    ...muiTheme.typography.h4,
    alignSelf: 'flex-end',
    '> strong': {
      fontSize: '14px',
    },
  },
  titleFontSx: {
    ...LabelComponentBaseSx.titleFontSx,
    ...muiTheme.typography.caption,
    alignSelf: 'flex-end',
    color: 'primary.main',
  },
};

export const LabelComponentVariant3 = {
  labelBoxSx: {
    ...LabelComponentBaseSx.labelBoxSx,
    marginBottom: '0',
  },
  titleFontSx: { ...muiTheme.typography.subtitle2, color: 'text.primary' },
  textFontSx: { ...muiTheme.typography.caption, color: 'text.secondary' },
};

export const LabelComponentVariantBorder2 = {
  labelBoxSx: {
    ...LabelComponentVariantBorder.labelBoxSx,
  },
  titleFontSx: {
    ...LabelComponentVariantBorder.titleFontSx,
    color: 'text.primary',
  },
  textFontSx: {
    ...muiTheme.typography.subtitle1,
    color: 'primary.main',
  },
};

export const LabelComponentVariantInline = {
  labelBoxSx: {
    ...LabelComponentBaseSx.labelBoxSx,
    display: 'flex',
    pY: '1rem',
    marginBottom: '0',
    justifyContent: 'space-between',
    minWidth: 'unset',
  },
  titleFontSx: {
    ...muiTheme.typography.subtitle2,
    color: 'text.primary',
    marginRight: '2.5rem',
  },
  textFontSx: {
    ...muiTheme.typography.subtitle2,
    color: 'primary.primary',
  },
};

export const LabelComponentVariantInline2 = {
  labelBoxSx: {
    ...LabelComponentBaseSx.labelBoxSx,
    display: 'flex',
    marginBottom: '0',
    alignItems: 'center',
    minWidth: 'unset',
  },
  titleFontSx: {
    ...muiTheme.typography.subtitle3,
    color: 'text.primary',
    marginRight: '0.5rem',
  },
  textFontSx: {
    ...muiTheme.typography.subtitle3,
    color: 'primary.primary',
    mt: '5px',
  },
};

export const LabelComponentVariantExtra = {
  labelBoxSx: {
    ...LabelComponentVariantBorder.labelBoxSx,
    border: '1px solid',
    borderColor: 'secondary.30p',
    backgroundColor: 'secondary.4p',
  },
  titleFontSx: {
    ...LabelComponentVariantBorder.titleFontSx,
    color: 'text.primary',
    fontSize: '14px',
  },
  textFontSx: {
    ...muiTheme.typography.subtitle1,
    color: 'secondary.dark',
  },
};
