import { createSelector } from '@reduxjs/toolkit';
import { identity } from 'fp-ts/lib/function';

import { RootState } from 'types';
import { initialState } from '.';

const sliceUss = (state: RootState) => state?.uss || initialState;

export const selectUss = createSelector([sliceUss], identity);
export const selectUssIsFetching = createSelector(
  [sliceUss],
  s => s.isFetching,
);
export const selectUssIsInitiatePasswordResetSuccess = createSelector(
  [sliceUss],
  s => s.initiatePasswordResetSuccess,
);
export const selectUssIsContinuePasswordResetSuccess = createSelector(
  [sliceUss],
  s => s.continuePasswordResetSuccess,
);
export const selectUssInitiatePasswordResetError = createSelector(
  [sliceUss],
  s => s.initiatePasswordResetError,
);
export const selectUssContinuePasswordResetError = createSelector(
  [sliceUss],
  s => s.continuePasswordResetError,
);
