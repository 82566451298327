/**
 * The function converts a given number of bytes into a human-readable string representation with
 * appropriate units (e.g., KB, MB, GB).
 * @param bytes - The `bytes` parameter represents the number of bytes that you want to convert to a
 * human-readable size format.
 * @param [decimals=2] - The `decimals` parameter is an optional parameter that specifies the number of
 * decimal places to include in the result. By default, it is set to 2.
 * @returns The function `bytesToSize` returns a formatted string representing the size in bytes.
 */
export const bytesToSize = (bytes, decimals = 2) => {
  if (!Number(bytes)) {
    return '0 Bytes';
  }

  const kbToBytes = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const index = Math.floor(Math.log(bytes) / Math.log(kbToBytes));

  return `${parseFloat((bytes / Math.pow(kbToBytes, index)).toFixed(dm))} ${
    sizes[index]
  }`;
};

/* The line `export const bytesToMB = Intl.NumberFormat('en', { notation: 'compact', style: 'unit',
unit: 'byte', unitDisplay: 'narrow', });` is creating a function named `bytesToMB` that uses the
`Intl.NumberFormat` constructor to format numbers in a specific way. */

export const bytesToMB = Intl.NumberFormat('en', {
  notation: 'compact',
  style: 'unit',
  unit: 'byte',
  unitDisplay: 'narrow',
});

/**
 * The function converts a string to camel case by removing spaces and capitalizing the first letter of
 * each word except the first one.
 * @returns The function `camelCase` returns a modified version of the input string `str` in camel case
 * format.
 */
export const camelCase = str => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

/**
 * The function `convertCSVToJson` takes a CSV string as input and converts it into an array of JSON
 * objects.
 * @param csvData - The `csvData` parameter is a string that represents the data in CSV format. Each
 * line in the CSV data represents a row, and the values in each row are separated by commas. The first
 * line of the CSV data is assumed to be the header row, which contains the names of the columns
 * @returns The function `convertCSVToJson` is returning an array of objects. Each object represents a
 * row in the CSV data, with the keys being the headers from the first line of the CSV and the values
 * being the corresponding values from each row.
 */
export const convertCSVToJson = csvData => {
  const lines = csvData.split('\n');
  const headers = lines[0].split(',');
  const result = [] as any;
  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    const currentLine = lines[i].split(',');

    for (let j = 0; j < headers.length; j++) {
      obj[camelCase(headers[j])] = currentLine[j].trim();
    }

    result.push(obj);
  }

  return result;
};

const mimeTypes: { [key: string]: string } = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  webp: 'image/webp',
  pdf: 'application/pdf',
  txt: 'text/plain',
  zip: 'application/zip',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  csv: 'text/csv',
  mp4: 'video/mp4',
  webm: 'video/webm',
  mp3: 'audio/mpeg',
};

export const getAcceptedMimeTypes = () => {
  const accept: { [key: string]: string[] } = {};

  for (const extension in mimeTypes) {
    const mimeType = mimeTypes[extension];
    if (!accept[mimeType]) {
      accept[mimeType] = [];
    }
    accept[mimeType].push(`.${extension}`);
  }

  return accept;
};

export const getMimeType = (extension: string): string => {
  return mimeTypes[extension.toLowerCase()] || 'application/octet-stream';
};
