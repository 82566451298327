import { put, takeLatest, call, select } from 'redux-saga/effects';
import { getInstance } from 'utils/http';
import { OSS_URL } from 'utils/urls';
import { userTasksActions as actions } from '.';
import { groupActions } from '../GroupSlice';
import { assetLiabilityActions } from '../AssetLiabilitySlice';
import { userTasksActions } from '../UserTasksSlice';

import { defaultErrorMessage } from 'constants/error';
import {
  ConnectionItemAccessData,
  ConnectionStatus,
  Contact,
  ContactPayloadOperation,
  ImpersonateIdPayloadOperation,
  NotificationType,
  UpdateConnectionPayloadOperation,
  UserConnectionPayload,
  ImpersonateDeletePayloadOperation,
} from './types';
import { selectConnectionItemSummary } from './selectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { toastActions } from '../ToastSlice';

export function* getContactSummaryList() {
  try {
    const instance = getInstance(OSS_URL);
    const searchParams = new URLSearchParams();
    searchParams.append('aggregateContacts', String(true));
    searchParams.append('status', [ConnectionStatus.ACCEPTED].join(','));

    const response = yield call(
      instance.get,
      `/user-connection?${searchParams.toString()}`,
    );
    yield put(actions.getContactSummaryListSuccess(response.data.payload));
  } catch (error: Error | any) {
    yield put(
      actions.getContactSummaryListError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* getContactImpersonatedSummaryList() {
  try {
    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.get,
      '/user-connection?aggregateConnections=true&impersonated=true',
    );
    yield put(
      actions.getContactImpersonatedSummaryListSuccess(response.data.payload),
    );
  } catch (error: Error | any) {
    yield put(
      actions.getContactImpersonatedSummaryListError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* createImpersonateUser(action) {
  try {
    const instance = getInstance(OSS_URL);
    const payload = {
      user: action.payload,
    };
    const response = yield call(
      instance.post,
      `/impersonate/user?invite=${action?.payload?.signup}`,
      payload,
    );
    yield put(
      actions.createImpersonateUserSuccess({
        message: 'Client added successfully',
      }),
    );
  } catch (error: Error | any) {
    yield put(
      actions.createImpersonateUserError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}
export function* getImpersonateToken(action) {
  try {
    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.post,
      '/auth/impersonate/login',
      action.payload,
    );
    yield put(actions.getImpersonateTokenSuccess(response.data.payload));
  } catch (error: Error | any) {
    yield put(
      actions.getImpersonateTokenError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}
export function* getContactSummaryFilteredList(
  payload: PayloadAction<{ groupId?: string; itemId?: string }>,
) {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('aggregateContacts', String(true));
    searchParams.append('status', [ConnectionStatus.ACCEPTED].join(','));
    payload.payload.groupId &&
      searchParams.append('groupId', payload.payload.groupId);
    payload.payload.itemId &&
      searchParams.append('itemId', payload.payload.itemId);

    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.get,
      `/user-connection?${searchParams.toString()}`,
    );
    yield put(
      actions.getContactSummaryFilteredListSuccess(response.data.payload),
    );
  } catch (error: Error | any) {
    yield put(
      actions.getContactSummaryFilteredListError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* getContactAccessData(
  action: PayloadAction<ContactPayloadOperation>,
) {
  try {
    yield put(actions.getContactAccessDataCleanUp());
    const targetUserId = action.payload.targetUserId.replace('+', '%2B');
    const groupIdQuery = action.payload.groupId
      ? `&groupId=${action.payload.groupId}`
      : '';
    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.get,
      `/user-connection?aggregateAccess=true&targetUserId=${targetUserId}${groupIdQuery}`,
    );
    yield put(actions.getContactAccessDataSuccess(response.data.payload[0]));
  } catch (error: Error | any) {
    yield put(
      actions.getContactAccessDataError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* getConnectionItemSummary(
  action: PayloadAction<ContactPayloadOperation>,
) {
  try {
    yield put(actions.getContactAccessDataCleanUp());
    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.get,
      '/user-connection?aggregateConnections=true',
    );
    yield put(actions.getConnectionItemSummarySuccess(response.data.payload));
  } catch (error: Error | any) {
    yield put(
      actions.getConnectionItemSummaryError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* addConnectionAccount(
  action: PayloadAction<UserConnectionPayload>,
) {
  try {
    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.post,
      '/user-connection',
      action.payload,
    );
    if (!response.data.payload._id) {
      throw new Error('Could not add the new contact');
    }
    yield put(
      actions.addConnectionAccountSuccess({
        message: 'Contact added successfully',
      }),
    );
  } catch (error: Error | any) {
    yield put(
      actions.addConnectionAccountError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* updateContactUserInformation(action: PayloadAction<Contact>) {
  try {
    const { targetUserId, targetUserMeta } = action.payload;
    const searchParams = new URLSearchParams();
    searchParams.append('targetUserId', targetUserId);

    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.patch,
      `/user-connection?${searchParams.toString()}`,
      {
        targetUserMeta,
      },
    );
    if (!response.data.payload.updated) {
      throw new Error('Could not update contact information');
    }
    yield put(
      actions.updateContactUserInformationSuccess({
        message: 'Contact information updated successfully',
      }),
    );
  } catch (error: Error | any) {
    yield put(
      actions.updateContactUserInformationError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* updateContactAccessData(
  action: PayloadAction<ConnectionItemAccessData & ContactPayloadOperation>,
) {
  try {
    const { targetUserId, entityAccessList, itemAccessList } = action.payload;
    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.patch,
      `/user-connection/${targetUserId}`,
      {
        entityAccessList,
        itemAccessList,
      },
    );
    if (!response.data.payload.updated) {
      throw new Error('Could not update contact information');
    }
    yield put(
      actions.updateContactAccessDataSuccess({
        message: 'Contact information updated successfully',
      }),
    );
  } catch (error: Error | any) {
    yield put(
      actions.updateContactAccessDataError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* deleteAccountConnection(
  action: PayloadAction<ContactPayloadOperation>,
) {
  try {
    const searchParams = new URLSearchParams();
    const { groupId, targetUserId, canImpersonate } = action.payload;
    groupId && searchParams.append('groupId', groupId);
    canImpersonate &&
      searchParams.append('canImpersonate', canImpersonate.toString());
    searchParams.append('targetUserId', targetUserId);

    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.delete,
      `/user-connection?${searchParams.toString()}`,
    );
    if (!response.data.deleted) {
      throw new Error('Could not delete the stakeholder');
    }
    yield put(
      actions.deleteAccountConnectionSuccess({
        message: 'The stakeholder was deleted successfully',
      }),
    );
  } catch (error: Error | any) {
    yield put(
      actions.deleteAccountConnectionError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* deleteImpersonateConnection(
  action: PayloadAction<ImpersonateDeletePayloadOperation>,
) {
  try {
    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.delete,
      `/user-connection/${action.payload.clientId}`,
    );
    if (!response.data.deleted) {
      throw new Error('Could not delete the client');
    }
    yield put(
      actions.deleteImpersonateConnectionSuccess({
        message: 'The client was deleted successfully',
      }),
    );
  } catch (error: Error | any) {
    yield put(
      actions.deleteImpersonateConnectionError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}
export function* updateStatusAccountConnection(
  action: PayloadAction<UpdateConnectionPayloadOperation>,
) {
  try {
    const summary = yield select(selectConnectionItemSummary);

    const instance = getInstance(OSS_URL);
    const response = yield call(
      instance.patch,
      `/task/user-connection`,
      action.payload,
    );
    if (!response) {
      throw new Error('an error ocurred updating stakeholder');
    }

    const itemFiltered = summary.map(item => {
      if (item._id !== response.data.baseContext.userConnection._id)
        return { ...item };

      return {
        ...item,
        status: response.data.baseContext.userConnection.status,
      };
    });
    yield put(actions.updateStatusAccountConnectionSuccess(itemFiltered));
  } catch (error: Error | any) {
    yield put(
      actions.updateStatusAccountConnectionError({
        message:
          error.response?.data?.payload?.message &&
          error.response.data.payload.message.length > 0
            ? error.response.data.payload.message
            : error.message,
      }),
    );
  }
}

export function* handleImpersonateSignOut() {
  sessionStorage.removeItem('userImpersonated');
  yield put(actions.getContactSummaryListCleanUp());
  yield put(actions.getContactImpersonatedSummaryListCleanUp());
  yield put(userTasksActions.cleanUpTasks());
  yield put(assetLiabilityActions.cleanUpItemOperation());
  yield put(groupActions.cleanupGroupOperation());
  sessionStorage.removeItem('supertoken');
}

export function* handleAddressBookError(
  action: PayloadAction<NotificationType>,
) {
  yield put(
    toastActions.showErrorToast({
      show: true,
      message: action.payload.message || defaultErrorMessage,
    }),
  );
}

export function* handleAddressBookSuccess(
  action: PayloadAction<NotificationType>,
) {
  yield put(
    toastActions.showSuccessToast({
      show: true,
      message: action.payload.message,
    }),
  );
}

export function* addressBookSaga() {
  yield takeLatest(actions.getContactSummaryList.type, getContactSummaryList);
  yield takeLatest(
    actions.getContactSummaryFilteredList.type,
    getContactSummaryFilteredList,
  );
  yield takeLatest(
    actions.getContactImpersonatedSummaryList.type,
    getContactImpersonatedSummaryList,
  );
  yield takeLatest(actions.createImpersonateUser.type, createImpersonateUser);
  yield takeLatest(
    actions.createImpersonateUserSuccess.type,
    handleAddressBookSuccess,
  );
  yield takeLatest(
    actions.createImpersonateUserError.type,
    handleAddressBookError,
  );
  yield takeLatest(actions.getImpersonateToken.type, getImpersonateToken);
  yield takeLatest(
    actions.getImpersonateTokenSuccess.type,
    handleAddressBookSuccess,
  );
  yield takeLatest(
    actions.getImpersonateTokenError.type,
    handleAddressBookError,
  );
  yield takeLatest(actions.getContactAccessData.type, getContactAccessData);
  yield takeLatest(
    actions.getConnectionItemSummary.type,
    getConnectionItemSummary,
  );
  yield takeLatest(actions.addConnectionAccount.type, addConnectionAccount);
  yield takeLatest(
    actions.deleteAccountConnection.type,
    deleteAccountConnection,
  );
  yield takeLatest(
    actions.deleteImpersonateConnection.type,
    deleteAccountConnection,
  );
  yield takeLatest(
    actions.deleteAccountConnectionSuccess.type,
    handleAddressBookSuccess,
  );
  yield takeLatest(
    actions.deleteAccountConnectionError.type,
    handleAddressBookError,
  );
  yield takeLatest(
    actions.updateContactUserInformation.type,
    updateContactUserInformation,
  );
  yield takeLatest(
    actions.updateContactAccessData.type,
    updateContactAccessData,
  );
  yield takeLatest(
    actions.updateStatusAccountConnection.type,
    updateStatusAccountConnection,
  );

  yield takeLatest(
    actions.updateStatusAccountConnectionError.type,
    handleAddressBookError,
  );
  yield takeLatest(
    actions.addConnectionAccountError.type,
    handleAddressBookError,
  );
  yield takeLatest(
    actions.getContactSummaryFilteredListError.type,
    handleAddressBookError,
  );
  yield takeLatest(
    actions.getContactSummaryListError.type,
    handleAddressBookError,
  );
  yield takeLatest(
    actions.getContactImpersonatedSummaryListError.type,
    handleAddressBookError,
  );
  yield takeLatest(
    actions.updateContactAccessDataError.type,
    handleAddressBookError,
  );
  yield takeLatest(
    actions.updateContactUserInformationError.type,
    handleAddressBookError,
  );
  yield takeLatest(
    actions.deleteAccountConnectionError.type,
    handleAddressBookError,
  );

  yield takeLatest(
    actions.updateStatusAccountConnectionSuccess.type,
    handleAddressBookSuccess,
  );
  yield takeLatest(
    actions.addConnectionAccountSuccess.type,
    handleAddressBookSuccess,
  );
  yield takeLatest(
    actions.updateContactUserInformationSuccess.type,
    handleAddressBookSuccess,
  );
  yield takeLatest(
    actions.deleteAccountConnectionSuccess.type,
    handleAddressBookSuccess,
  );
  yield takeLatest(
    actions.updateContactAccessDataSuccess.type,
    handleAddressBookSuccess,
  );
  yield takeLatest(
    actions.signOutImpersonateUser.type,
    handleImpersonateSignOut,
  );
}
