import { muiTheme } from 'styles/muiTheme';

export default {
  cardStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1em 2em',
    margin: '1em 1em',
    backgroundColor: muiTheme.palette.primary.contrast,
    border: '1px solid',
    borderColor: muiTheme.palette.addition.tableListGreyColor,
    borderRadius: '4px',
    width: 'initial',
  },
  typographySx: {
    TypographyTransform: 'capitalize',
    fontFamily: 'RobotoRegular',
  },
};
