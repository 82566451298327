import { Grid, Typography } from '@mui/material';
import SavedScenario from './components/SavedScenario';
import { LabelText } from 'app/components/LabelText/Loadable';
import { useTranslation } from 'react-i18next';
import { ErrorOutline } from '@mui/icons-material';

type Props = {
  scenarios?: any; //TODO: Fix this type
};

export const SavedScenarios = ({ scenarios }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid>
        <Typography variant="h6" fontWeight="700" mb={4}>
          {t('scenarioModeling.savedScenarios.headers.savedScenarios')}
        </Typography>

        {scenarios?.length ? (
          scenarios.map(scenario => (
            <SavedScenario key={scenario.title} scenario={scenario} />
          ))
        ) : (
          <Grid mb={4}>
            <LabelText
              title={<ErrorOutline sx={{ verticalAlign: 'middle' }} />}
              text={t('scenarioModeling.savedScenarios.notSavedScenarios')}
              variant="variantIcon"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
