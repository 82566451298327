import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { toastSaga } from './saga';
import { ToastData, ToastType } from './types';

const defaultValue: ToastType = { show: false, message: '' };

export const initialState: ToastData = {
  successToast: defaultValue,
  errorToast: defaultValue,
};

const slice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showErrorToast(state, action: PayloadAction<ToastType>) {
      state.errorToast = action.payload;
    },
    showSuccessToast(state, action: PayloadAction<ToastType>) {
      state.successToast = action.payload;
    },
    hideErrorToast(state) {
      state.errorToast = defaultValue;
    },
    hideSuccessToast(state) {
      state.successToast = defaultValue;
    },
  },
});

export const { actions: toastActions, reducer } = slice;

export const useToastSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: toastSaga });
  return { actions: slice.actions };
};

// Extract the type of toastActions
export type ToastActionsType = typeof toastActions;
