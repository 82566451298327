import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { Box, TextField } from '@mui/material';
import { styled } from '@mui/system';
import style from './style';
import { Input } from 'app/components/Inputs';
import { ControlledInputPhone } from 'app/components/Inputs/ControlledInputPhone';
import { UseFormReturn } from 'react-hook-form';
import { ControlledInputCountry } from 'app/components/Inputs/ControlledInputCountry';
import { ControlledInput } from 'app/components/Inputs/ControlledInput';
import { Checkboxes, basicOptions } from 'app/components/Inputs/Checkboxes';

interface ClientInformationFormProps {
  //TODO : fix the type in all forms
  useFormInstance: UseFormReturn<any>;
  editUserInfo?: boolean | string;
  hideSignup?: boolean | string;
}

export function ClientInformationForm({
  useFormInstance,
  editUserInfo,
  hideSignup,
}: ClientInformationFormProps) {
  const { t } = useTranslation();
  const {
    watch,
    register,
    formState: { errors },
  } = useFormInstance;

  return (
    <Box sx={style.boxContainerStyles}>
      <ControlledInput
        watch={watch}
        type="text"
        register={register}
        errors={errors.firstName}
        name="firstName"
        label={capitalize(t('signUp.placeholders.firstName'))}
        size="medium"
        disabled={Boolean(editUserInfo)}
      />
      <ControlledInput
        watch={watch}
        type="text"
        register={register}
        errors={errors.lastName}
        name="lastName"
        label={capitalize(t('signUp.placeholders.lastName'))}
        size="medium"
        disabled={Boolean(editUserInfo)}
      />

      <ControlledInput
        watch={watch}
        type="text"
        register={register}
        errors={errors.email}
        name="email"
        label={capitalize(t('stakeholder.emailField'))}
        size="medium"
        disabled={Boolean(hideSignup)}
        otherRules={{
          pattern: {
            value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
            message: capitalize(t('signIn.emailAddress.formatError')),
          },
        }}
      />

      <Input
        id="company"
        label={capitalize(t('advisors.companyField'))}
        size="medium"
        error={Boolean(errors.company)}
        value={watch('company')}
        disabled={Boolean(editUserInfo)}
        {...register('company')}
      />
      <ControlledInputCountry
        useFormInstance={useFormInstance}
        size="medium"
        name="country"
        disabled={Boolean(editUserInfo)}
      />
      <ControlledInputPhone
        useFormInstance={useFormInstance}
        size="small"
        disableInput={Boolean(editUserInfo)}
      />
      {!hideSignup && (
        <Checkboxes
          id="leverageable"
          label={capitalize(t('advisors.signupLabel'))}
          value={watch('signup')}
          options={basicOptions}
          disabled={Boolean(editUserInfo)}
        />
      )}
    </Box>
  );
}
