import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.user || initialState;

export const selectUser = createSelector([selectSlice], state => state);

export const selectIsFetching = createSelector(
  [selectSlice],
  userState => userState.isFetching,
);

export const selectIsSuccess = createSelector(
  [selectSlice],
  userState => userState.isSuccess,
);

export const selectError = createSelector(
  [selectSlice],
  userState => userState.signinError,
);

export const selectLoginResponse = createSelector(
  [selectSlice],
  userState => userState.signinResponse,
);

export const selectFormData = createSelector(
  [selectSlice],
  userState => userState.formData,
);

export const selectUserData = createSelector(
  [selectSlice],
  userState => userState.user,
);

export const selectUserToken = createSelector(
  [selectSlice],
  userState => userState.userToken,
);

export const selectUserDefaultGroup = createSelector(
  [selectSlice],
  userState => userState.user?.groups[0],
);

export const selectEditUserError = createSelector(
  [selectSlice],
  userState => userState.editUserError,
);

export const selectUserEmail = createSelector(
  [selectSlice],
  userState => userState.userEmail,
);

export const selectValidateTokenIsSuccess = createSelector(
  [selectSlice],
  userState => userState.validateTokenIsSuccess,
);

export const selectVerified = createSelector([selectSlice], userState =>
  userState.user ? userState.user.verified : false,
);
