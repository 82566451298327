import { createTheme } from '@mui/material/styles';
import typography, { TypographysType } from './typographys';
import { components } from './components';
import { variants } from './variants';
import { utilities } from './utilities';
import { themes } from './themes';
import { PaletteType, PaletteDirectoryType } from './themes/palette';
import shadows from './shadows';

type OptionsFlags<Type> = {
  [Property in keyof Type]: true;
};

const [siteHostname] = window.location.hostname.split('.');
const theme = themes[siteHostname];
const palette: PaletteType = theme || themes.application;

const themeOptions = {
  palette,
  typography,
  shadows,
  components,
  variants,
  utilities,
};

type ThemeOptionsType = typeof themeOptions;
declare module '@mui/material/styles' {
  interface Theme extends ThemeOptionsType {}
  interface ThemeOptions extends ThemeOptionsType {}
  interface Palette extends PaletteType {}
  interface PaletteColor extends PaletteDirectoryType {}
  interface SimplePaletteColorOptions extends PaletteDirectoryType {}
  interface PaletteOptions extends PaletteType {}
  interface TypographyOptions extends TypographysType {}
  interface TypographyVariants extends TypographysType {}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides
    extends OptionsFlags<TypographysType> {}
}

export const muiTheme = createTheme(themeOptions);
