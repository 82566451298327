import React from 'react';
import {
  FolderOutlined,
  ExpandMore,
  FolderOpenOutlined,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
} from '@mui/material';
import * as style from './style';
import { Folder, SelectedFolder } from 'app/pages/vault/models/vault.types';
import { isFolder } from '../../../../utils/guards.utils';
export const ASSET_ROW_WIDTH = 260;

type Props = {
  folder: Folder;
  depth: number;
  selectedFolder: SelectedFolder | null;
  onSelectFolder: (folder: Folder) => void;
};

export const FolderAccordion = ({
  folder,
  depth,
  selectedFolder,
  onSelectFolder,
}: Props) => {
  const [expanded, setExpanded] = React.useState(false);

  const isSelected =
    selectedFolder && folder && selectedFolder.id === folder.id;

  const handleExpansion = () => {
    if (folder.children) {
      setExpanded(prevExpanded => !prevExpanded);
    }
  };

  return (
    <div>
      <Accordion
        sx={{
          ...style.accordion,
          backgroundColor: isSelected ? '#FFFFFF' : '#F8F8F9',
        }}
        expanded={expanded}
        onChange={handleExpansion}
      >
        <AccordionSummary
          expandIcon={folder?.children ? <ExpandMore /> : null}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={style.accordionSummary}
        >
          <Grid sx={style.accordionHeader}>
            <Grid
              width={ASSET_ROW_WIDTH - depth * 20}
              display="flex"
              flexDirection="row"
              onClick={() => onSelectFolder(folder)}
            >
              <>
                {expanded ? (
                  <FolderOpenOutlined
                    sx={{
                      color: depth > 1 ? 'secondary.light' : 'secondary.main',
                    }}
                  />
                ) : (
                  <FolderOutlined
                    sx={{
                      color: depth > 1 ? 'secondary.light' : 'secondary.main',
                    }}
                  />
                )}
              </>

              <Typography marginLeft={1} variant="body1">
                {folder?.name}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={style.accordionDetails}>
          {isFolder(folder) &&
          folder.children?.length &&
          isFolder(folder.children[0]) ? (
            <div
              style={{
                paddingLeft: `${depth * 25}px`,
              }}
            >
              {folder.children.map(child =>
                isFolder(child) ? (
                  <FolderAccordion
                    key={child.name}
                    folder={child}
                    depth={depth + 1}
                    selectedFolder={selectedFolder}
                    onSelectFolder={() => onSelectFolder(child)}
                  />
                ) : null,
              )}
            </div>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
