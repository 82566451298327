import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { userSaga } from './saga';
import { UserFormData, UserState } from './types';

export const initialState: UserState = {
  user: null,
  userEmail: null,
  userToken: sessionStorage.token || null,
  isFetching: false,
  isSuccess: false,
  signinError: null,
  userEmailError: null,
  formData: undefined,
  getUserError: null,
  isEditingUser: false,
  editUserError: null,
  validateTokenError: null,
  validateTokenIsSuccess: false,
  verified: sessionStorage.verified || false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    doSignin(state, action: PayloadAction<UserFormData>) {
      state.isFetching = true;
      state.formData = action.payload;
      state.signinError = null;
    },
    signinSuccess(state, action: PayloadAction<string>) {
      state.isFetching = false;
      state.isSuccess = true;
      state.userToken = action.payload;
      sessionStorage.setItem('demographicInfo', JSON.stringify({}));
      state.signinError = null;
    },
    signinError(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.signinError = action.payload;
    },
    doSignoff(state) {
      state.isFetching = false;
      state.isSuccess = false;
      state.user = null;
      state.userToken = null;
      state.signinError = null;
    },
    clearSigninState(state) {
      state.isFetching = false;
      state.isSuccess = false;
      state.signinError = null;
      state.formData = undefined;
    },
    setUser(state, action: PayloadAction<UserState['user']>) {
      state.user = action.payload;
      state.isSuccess = false;
    },
    getUser(state) {
      state.isFetching = true;
      state.getUserError = null;
      state.isSuccess = false;
    },
    getUserSuccess(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.user = action.payload;
      state.getUserError = null;
      state.isSuccess = false;
    },
    getUserError(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.getUserError = action.payload;
      state.isSuccess = false;
    },
    editUser(state, action) {
      state.isEditingUser = true;
      state.editUserError = null;
      state.isSuccess = false;
    },
    editUserSuccess(state) {
      state.isSuccess = true;
      state.isEditingUser = false;
      state.editUserError = null;
    },
    editUserError(state, action) {
      state.editUserError = action.payload;
      state.isEditingUser = true;
      state.isSuccess = false;
    },
    getUserByEmail(state, action: PayloadAction<string>) {
      state.isFetching = true;
      state.userEmail = action.payload;
    },
    getUserByEmailSuccess(state, action: PayloadAction<UserState['user']>) {
      state.isFetching = false;
      state.user = action.payload;
    },
    getUserByEmailError(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.userEmailError = action.payload;
    },
    validateToken(state, action: PayloadAction<string>) {
      state.isFetching = true;
      state.isSuccess = false;
    },
    validateTokenSuccess(state) {
      state.isFetching = false;
      state.validateTokenIsSuccess = true;
    },
    validateTokenError(state, action: PayloadAction<any>) {
      state.isFetching = false;
      state.validateTokenError = action.payload;
    },
  },
});

export const { actions: userActions, reducer } = slice;

export const useUserSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: userSaga });
  return { actions: slice.actions };
};
