import { createContext, ReactElement, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGroupSlice } from 'app/features/GroupSlice';
import { UserStateProps, UserProviderProps, UserUpdaterProps } from './types';
import {
  useSignin,
  useSignoff,
  useClearSigninState,
  useSetUser,
  useGetUser,
  useEditUser,
  useGetUserByEmail,
  useValidateToken,
  useIsTokenValid,
  useRemindLater,
  useClearEditState,
} from './updaters';

const defaultUserState: UserStateProps = {
  user: null,
  userEmail: null,
  userToken: sessionStorage.token || null,
  isFetching: false,
  isSuccess: false,
  signinError: null,
  userEmailError: null,
  formData: undefined,
  getUserError: null,
  isEditingUser: false,
  editUserError: null,
  validateTokenError: null,
  validateTokenIsSuccess: false,
  verified: sessionStorage.verified || false,
  remindLaterDate: undefined,
};

const defaultUpdaters: UserUpdaterProps = {
  signIn: () => {},
  signOff: () => {},
  clearSigninState: () => {},
  clearEditState: () => {},
  setUser: () => {},
  getUser: () => {},
  editUser: () => {},
  getUserByEmail: () => {},
  validateToken: () => {},
  useIsTokenValid: () => true,
  setRemindLater: () => {},
};

const UserContext = createContext<UserStateProps & UserUpdaterProps>({
  ...defaultUserState,
  ...defaultUpdaters,
});

export const UserProvider = ({
  initUser,
  children,
}: UserProviderProps): ReactElement => {
  const [userState, setUserState] = useState<UserStateProps>({
    ...defaultUserState,
    user: initUser,
  });
  const dispatch = useDispatch();
  const { actions: groupActions } = useGroupSlice();

  const wrapFunc = (func, isDispatch = false) =>
    function (...args) {
      // @ts-ignore
      const context = this;
      const changedArgs = [...args];
      changedArgs.push(userState, setUserState);
      if (isDispatch) {
        changedArgs.push(dispatch);
        changedArgs.push(groupActions);
      }
      return func.apply(context, changedArgs);
    };

  return (
    <UserContext.Provider
      value={{
        ...userState,
        signIn: wrapFunc(useSignin),
        signOff: wrapFunc(useSignoff),
        clearSigninState: wrapFunc(useClearSigninState),
        clearEditState: wrapFunc(useClearEditState),
        setUser: wrapFunc(useSetUser),
        getUser: wrapFunc(useGetUser),
        editUser: wrapFunc(useEditUser, true),
        getUserByEmail: wrapFunc(useGetUserByEmail),
        validateToken: wrapFunc(useValidateToken),
        useIsTokenValid: wrapFunc(useIsTokenValid),
        setRemindLater: wrapFunc(useRemindLater),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useUser(): UserStateProps & UserUpdaterProps {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export function useVerified(): boolean {
  const { user } = useUser();
  return user ? user.verified : false;
}
