import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import { Modal } from 'app/components/Modal.new';
import { VaultFile as FileType } from 'app/pages/vault/models/vault.types';

import { useGetFileByIdQuery, getURL } from '../../services';
import { getExtensionType } from '../../utils/guards.utils';
import { VaultFile } from './components/file';
import { TopBar } from './components/top-bar';
import * as styles from './style';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  file: FileType;
};

export const ModalFilePreview = ({ isOpen, onClose, file }: Props) => {
  const [fileUrl, setFileUrl] = useState('');

  const type = file ? getExtensionType(file?.extension) : '';

  const { data: fileData } = useGetFileByIdQuery(file);

  useEffect(() => {
    async function fetchData() {
      if (fileData?.payload && fileData?.payload?.getUrl) {
        const response = await getURL(fileData.payload.getUrl);
        setFileUrl(response);
      }
    }

    if (fileData?.payload) {
      fetchData();
    } else if (file && file.previewUrl) {
      setFileUrl(file?.previewUrl);
    }
  }, [fileData, file]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header=""
      contentSx={styles.modalPreview}
      hideCloseButton
    >
      <Grid sx={styles.container}>
        <TopBar
          type={type}
          fileName={`${file?.fileName}.${file?.extension}`}
          fileExtension={file?.extension}
          url={fileUrl}
        />
        <Grid sx={styles.fileContainer}>
          {fileUrl && (
            <VaultFile
              src={fileUrl}
              type={type}
              fileName={`${file?.fileName}.${file?.extension}`}
            />
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};
