import { Modal } from 'app/components/Modal.new';
import { Box, Card, Grid, Typography } from '@mui/material';
import { UploadFile } from 'app/components/Inputs/UploadFile';
import { useForm } from 'react-hook-form';
import { BottomRightGroupButtons } from 'app/components/Buttons/BottomRightGroupButtons';
import { useAddFileMutation, putURL } from '../../services';
import { getFileNameAndExtension } from '../../utils/files.utils';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useState } from 'react';
import { getAcceptedMimeTypes } from 'utils/files';
import {
  FileUploadConfig,
  VaultFile,
} from 'app/pages/vault/models/vault.types';
import * as styles from './style';

type Props = {
  isOpen: boolean;
  fileUploadConfig: FileUploadConfig | null;
  onClose: () => void;
};

export const ModalFileUpload = ({
  isOpen,
  fileUploadConfig,
  onClose,
}: Props) => {
  const [isUploading, setIsUploading] = useState(false);

  const { t } = useTranslation();

  const {
    register,
    unregister,
    watch,
    setValue,
    control,
    resetField,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  const [addFile] = useAddFileMutation();

  const uploadedFile = watch('uploadedFile');

  const accept = getAcceptedMimeTypes();

  const handleFileUpload = () => {
    if (
      !fileUploadConfig ||
      !fileUploadConfig.folder ||
      !fileUploadConfig.callback
    ) {
      return;
    }

    setIsUploading(true);
    const { folder, callback } = fileUploadConfig;
    const { fileName, extension } = getFileNameAndExtension(uploadedFile?.name);

    const filePath = folder?.id
      ? `/${folder?.groupId}/${folder?.id}`
      : `/${folder?.groupId}`;

    const payload = [
      {
        fileName,
        filePath,
        extension,
        groupId: folder?.groupId,
      },
    ];

    const data = {
      groupId: folder?.groupId,
      payload,
    };

    addFile(data).then(async result => {
      if ('data' in result) {
        const payload = result.data.payload;
        if (payload && payload[0] && payload[0].putUrl) {
          const url = payload[0].putUrl;
          const file = uploadedFile.file;
          await putURL(url, file, extension);

          const newFile: VaultFile = {
            _id: `${filePath}/${fileName}.${extension}`,
            extension,
            fileName,
            filePath,
            lastModified: moment(file.lastModified).toISOString(),
            size: file.size,
            createdBy: folder?.id,
            groupId: folder?.groupId,
            contentLength: file.size,
            contentType: file.type,
            key: `some-key`,
            createdOn: new Date().toISOString(),
            itemType: 'file',
            previewUrl: URL.createObjectURL(file),
          };
          callback(newFile);
        } else {
          console.error('Payload or putUrl is missing.');
        }
      } else {
        console.error('Error adding file:', result.error);
      }
      setIsUploading(false);
      onClose();
    });
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={!!isOpen}
      onClose={onClose}
      header=""
      contentSx={styles.modalPreview}
      hideCloseButton
    >
      <Box width="100%">
        <Grid item xs={12} sx={{ padding: '0 20px' }}>
          <Typography variant="h5" mb={3} mt={3}>
            {t('vault.modals.fileUpload.title')}
          </Typography>
          <UploadFile
            accept={accept}
            register={register}
            errors={errors}
            setValue={setValue}
            unregister={unregister}
            watch={watch}
            control={control}
            resetField={resetField}
            name="uploadedFile"
            disclaimer="PDF, DOCS, SVG, PNG, JPG or GIF (max. 200MB)"
          ></UploadFile>
        </Grid>
        <Card elevation={4} sx={{ padding: '20px' }}>
          <BottomRightGroupButtons
            onPrimaryBtnClick={handleCancel}
            onSecondaryBtnClick={handleFileUpload}
            primaryBtnText={t('vault.modals.fileUpload.buttons.cancel')}
            secondaryBtnText={t('vault.modals.fileUpload.buttons.upload')}
            secondaryBtnDisabled={!uploadedFile || isUploading}
          />
        </Card>
      </Box>
    </Modal>
  );
};
