/**
 *
 * Asynchronously loads the component for EditTaskManagerPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const EditTaskManagerPage = lazyLoad(
  () => import('./index'),
  module => module.EditTaskManagerPage,
);
