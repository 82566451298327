import { Grid, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './style';
import { AdvisorCard } from '../AdvisorCard';
import { ImpersonateUserConnection } from 'app/features/AddressBookSlice/types';

interface Props {
  clientList: ImpersonateUserConnection[] | undefined;
  editOnClick?: (client: ImpersonateUserConnection) => () => void;
  deleteOnClick?: (client: ImpersonateUserConnection) => () => void;
  viewConsolidation?: (client: ImpersonateUserConnection) => () => void;
  viewOnClick?: (client: ImpersonateUserConnection) => () => void;
}

const renderEqGridItems = (titleList: string[]) => {
  return titleList.map((title: string, index: number) => (
    <Grid item key={index} xs={4}>
      <Typography
        variant="caption"
        color="text.secondary"
        textTransform="capitalize"
      >
        {title}
      </Typography>
    </Grid>
  ));
};

export function AdvisorTable({
  clientList,
  viewOnClick,
  viewConsolidation,
  editOnClick,
  deleteOnClick,
}: Props) {
  const { t } = useTranslation();
  const tableHeadersList = [t('name'), t('contactDetails'), '', ''];

  return (
    <Box sx={style.boxStyles}>
      <Box sx={style.topBorderStyles}></Box>

      <Box sx={style.containerSx}>
        <Grid container sx={style.headerStakeholderStyles}>
          {renderEqGridItems(tableHeadersList)}
        </Grid>
        {clientList?.map((client, index) => (
          <Box>
            <AdvisorCard
              groupIdList={[]}
              tags={[]}
              targetUserMeta={{
                _id: '',
                email: '',
                firstName: undefined,
                lastName: undefined,
                phoneNumber: undefined,
                countryCode: undefined,
                country: undefined,
                address: undefined,
                company: undefined,
                title: undefined,
              }}
              {...client}
              key={client._id}
              viewOnClick={viewOnClick && viewOnClick(client)}
              viewConsolidation={viewConsolidation && viewConsolidation(client)}
              editOnClick={editOnClick && editOnClick(client)}
              deleteOnClick={deleteOnClick && deleteOnClick(client)}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
