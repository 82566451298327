import { Action, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { stripeSaga } from './saga';
import {
  StripeState,
  NotificationType,
  StripeUserBasicInfo,
  StripeCardBasicInfo,
  StripeCardUpdateUrl,
  StripePaymentHistory,
} from './types';

export const initialState: StripeState = {
  stripeContactDetails: undefined,
  stripeDefaultCardDetails: undefined,
  stripeUpdateDetailsUrl: undefined,
  isFetching: false,
  isAdding: false,
  isModifying: false,
  isUpdating: false,
  isDeleting: false,
  isSuccess: false,
  paymentHistory: [],
};

const slice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    getStripeCardDetails(state, action: PayloadAction<StripeUserBasicInfo>) {
      state.isFetching = true;
      state.error = undefined;
    },
    getStripeCardDetailsSuccess(
      state,
      action: PayloadAction<StripeCardBasicInfo>,
    ) {
      state.isSuccess = true;
      state.isDeleting = true;
      state.stripeDefaultCardDetails = action.payload;
      state.isFetching = false;
      state.error = undefined;
    },
    getStripeCardDetailsError(state, action: PayloadAction<NotificationType>) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isDeleting = false;
      state.error = action.payload;
    },
    updateStripeCardDetails(state, action: PayloadAction<StripeUserBasicInfo>) {
      state.isFetching = true;
      state.error = undefined;
    },
    updateStripeCardDetailsSuccess(
      state,
      action: PayloadAction<StripeCardUpdateUrl>,
    ) {
      state.isSuccess = true;
      state.isDeleting = true;
      state.stripeUpdateDetailsUrl = action.payload;
      state.isFetching = false;
      state.error = undefined;
    },
    updateStripeCardDetailsError(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isDeleting = false;
      state.error = action.payload;
    },
    updateStripeAccountDetails(
      state,
      action: PayloadAction<StripeUserBasicInfo>,
    ) {
      state.isFetching = true;
      state.error = undefined;
    },
    updateStripeAccountDetailsSuccess(
      state,
      action: PayloadAction<StripeUserBasicInfo>,
    ) {
      state.isSuccess = true;
      state.isDeleting = true;
      state.stripeContactDetails = action.payload;
      state.isFetching = false;
      state.error = undefined;
    },
    updateStripeAccountDetailsError(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isDeleting = false;
      state.error = action.payload;
    },
    getStripeAccountDetails(state, action: PayloadAction<StripeUserBasicInfo>) {
      state.isFetching = true;
      state.error = undefined;
    },
    getStripeAccountDetailsSuccess(
      state,
      action: PayloadAction<StripeUserBasicInfo>,
    ) {
      state.isSuccess = true;
      state.isDeleting = true;
      state.stripeContactDetails = action.payload;
      state.isFetching = false;
      state.error = undefined;
    },
    getStripeAccountDetailsError(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isDeleting = false;
      state.error = action.payload;
    },
    getStripePaymentDetails(state, action: PayloadAction<StripeUserBasicInfo>) {
      state.isFetching = true;
      state.error = undefined;
    },
    getStripePaymentDetailsSuccess(
      state,
      action: PayloadAction<StripePaymentHistory[]>,
    ) {
      state.isSuccess = true;
      state.isDeleting = true;
      state.paymentHistory = action.payload;
      state.isFetching = false;
      state.error = undefined;
    },
    getStripePaymentDetailsError(
      state,
      action: PayloadAction<NotificationType>,
    ) {
      state.isFetching = false;
      state.isSuccess = false;
      state.isDeleting = false;
      state.error = action.payload;
    },
  },
});

export const { actions: stripeActions, reducer } = slice;

export const useStripeSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: stripeSaga });
  return { actions: slice.actions };
};
