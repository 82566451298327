import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from '@mui/material';
import { InputError } from 'app/components/Inputs';
import { useEffect, useState } from 'react';
export interface CheckboxOption {
  value: string;
  label: string;
}

interface Props {
  id: string;
  label: string;
  options: CheckboxOption[];
  errors?: any;
  value?: string | null;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

export const basicOptions = [
  {
    value: 'true',
    label: 'Yes',
  },
  {
    value: 'false',
    label: 'No',
  },
];

export function Checkboxes({ disabled, ...props }: Props) {
  const { label, options, errors, value, onChange = x => x } = props;
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleChange = e => {
    if (!disabled) {
      setSelected(e.target.value);
      onChange(e.target.value);
    }
  };

  return (
    <>
      <Typography variant="body1" mb={1} color="text.primary">
        {label}
      </Typography>
      <FormControl>
        <RadioGroup>
          {options.map((opt, index) => (
            <FormControlLabel
              key={index}
              control={
                <Radio
                  {...props}
                  id={opt.value}
                  value={opt.value}
                  checked={selected === opt.value}
                  onChange={handleChange}
                  size="medium"
                  sx={{
                    py: 0.5,
                  }}
                />
              }
              sx={{
                '&.Mui-checked': {
                  color: 'primary.main',
                },
              }}
              label={
                <Typography
                  variant="body1"
                  color={
                    selected === opt.value ? 'text.primary' : 'text.secondary'
                  }
                >
                  {opt.label}
                </Typography>
              }
              value="end"
            />
          ))}
        </RadioGroup>
      </FormControl>
      {errors && errors.message && <InputError message={errors.message} />}
    </>
  );
}
