import { useToastSlice } from 'app/features/ToastSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import style from './style';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

interface Props {
  isSuccess: boolean;
  successText: string;
}

export function SuccessToast({ isSuccess, successText }: Props) {
  const { actions } = useToastSlice();
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(actions.hideSuccessToast());
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success(successText, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: onClose,
        style: style.successToastStyles,
        icon: ({ theme, type }) => (
          <CheckCircleOutlineOutlinedIcon sx={{ color: 'success.contrast' }} />
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, successText]);

  return <ToastContainer />;
}
