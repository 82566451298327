/**
 *
 * Asynchronously loads the component for SellAssetModal
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SellAssetModal = lazyLoad(
  () => import('./index'),
  module => module.SellAssetModal,
);
