import { Grid, Tab, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { Helmet } from 'react-helmet-async';
import UHstyle from 'app/pages/user-home/UserHome/style';
import { muiTheme } from 'styles/muiTheme';
import React from 'react';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PaymentOutlined from '@mui/icons-material/PaymentOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ProfileTab, SubscriptionTab, PaymentTab } from './components';

import style from './style';

export const ProfileSettingsPage = () => {
  const { t } = useTranslation();

  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>{capitalize(t('profile.helmet.profilePage'))}</title>
        <meta name="description" content="MyFO application user profile" />
      </Helmet>

      <Grid sx={UHstyle.userHomeStyles} container spacing={2}>
        <Grid item md={2}></Grid>

        <Grid
          sx={muiTheme.variants.userHomeContentStyles}
          item
          md={true}
          style={style.gridBackground}
        >
          <Typography variant="h4" sx={style.heading}>
            {capitalize(t('profileSettings.header'))}
          </Typography>
          <TabContext value={value}>
            <Box sx={style.boxStyles}>
              <TabList onChange={handleChange}>
                <Tab
                  icon={<AccountBoxIcon />}
                  iconPosition="start"
                  label={capitalize(t('profileSettings.profileHeader'))}
                  value="1"
                />
                <Tab
                  icon={<SubscriptionsIcon />}
                  iconPosition="start"
                  label={capitalize(t('profileSettings.subcriptionHeader'))}
                  value="2"
                />
                <Tab
                  icon={<PaymentOutlined />}
                  iconPosition="start"
                  label={capitalize(t('profileSettings.paymentHeader'))}
                  value="3"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ProfileTab />
            </TabPanel>
            <TabPanel value="2">
              <SubscriptionTab />
            </TabPanel>
            <TabPanel value="3">
              <PaymentTab />
            </TabPanel>
          </TabContext>
        </Grid>

        <Grid item md={2}></Grid>
      </Grid>
    </>
  );
};
