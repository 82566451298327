import { Option } from 'app/components/Inputs';

export enum DistributionTypeList {
  DIVIDEND = 'DIVIDEND',
  CAPITAL = 'CAPITAL',
  INTEREST = 'INTEREST',
  FROM_SALE = 'FROM_SALE',
  SPECIAL = 'SPECIAL',
}

export enum ShareOperationTypeList {
  UPDATE = 'UPDATE',
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum FundOperationTypeList {
  UPDATE = 'UPDATE',
  UPDATE_COMMITMENT = 'UPDATE_COMMITMENT',
  COST_ADJUSTMENT = 'COST_ADJUSTMENT',
}

export enum InsuranceOperationTypeList {
  COST = 'COST',
  CASH_SURRENDER = 'CASH_SURRENDER',
  DEATH_BENEFIT = 'DEATH_BENEFIT',
}

export type DistributionType = keyof typeof DistributionTypeList;
export type ShareOperationType = keyof typeof ShareOperationTypeList;
export type FundOperationType = keyof typeof FundOperationTypeList;
export type InsuranceOperationType = keyof typeof InsuranceOperationTypeList;

export const TransactionTypeListFund = Object.assign(
  {},
  DistributionTypeList,
  FundOperationTypeList,
);

export const TransactionTypeList = Object.assign(
  {},
  DistributionTypeList,
  ShareOperationTypeList,
);

export type TransactionFundTypes = DistributionType | FundOperationType;

export type TransactionTypes = DistributionType | ShareOperationType;

export const buildOptionList = (list, translations): Option[] => {
  return Object.keys(list)
    .map(
      (type): Option => ({
        value: type,
        label: translations[type],
      }),
    )
    .filter(({ label }) => label);
};
