/**
 *
 * ListItemAccordion
 *
 */
import * as React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SxProps,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

import Card from '@mui/material/Card';
import style from './style';

interface Props {
  name: string;
  title: string | React.ReactNode;
  children: React.ReactNode;
  expandIcon?: React.ReactNode;
  disableExpandIcon?: boolean;
  defaultExpanded?: boolean;
  accordionSx?: SxProps;
  accordionSummarySx?: SxProps;
  accordionDetailsSx?: SxProps;
  handleExpanded?: (isExpanded: boolean) => void;
  handleClick?: (event: React.MouseEvent) => void;
  contentListSx?: SxProps;
}

export function ListItemAccordion({
  title,
  children,
  disableExpandIcon,
  expandIcon,
  name,
  defaultExpanded,
  accordionSx,
  accordionSummarySx,
  accordionDetailsSx,
  contentListSx,
  handleExpanded,
  handleClick,
}: Props) {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (!disableExpandIcon) {
      handleExpanded && handleExpanded(isExpanded);
      setExpanded(isExpanded);
    }
  };

  React.useEffect(() => {
    setExpanded(Boolean(defaultExpanded));
  }, [defaultExpanded]);

  return (
    <Card sx={{ ...style.contentLiStyles, ...contentListSx }} elevation={0}>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        elevation={0}
        sx={{
          ...style.accordionStyles,
          ...accordionSx,
        }}
      >
        <AccordionSummary
          sx={{
            ...style.accordionSummaryStyles,
            ...accordionSummarySx,
          }}
          expandIcon={disableExpandIcon ? '' : expandIcon || <ArrowDropDown />}
          aria-controls={`${name}-content`}
          id={`${name}-header`}
          onClick={handleClick}
        >
          {title}
        </AccordionSummary>
        <AccordionDetails
          sx={{ ...style.accordionDetailsStyles, ...accordionDetailsSx }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
