import { ToastActionsType } from 'app/features/ToastSlice';
import { Entity, FileOperation } from '../models/vault.types';
import {
  addFileToFolderTree,
  deleteFileFromFolderTree,
  updateFileInFolderTree,
} from 'app/pages/vault/utils/files.utils';

/**
 * Highlights a matched item within a list of entities by traversing the tree structure.
 * If a match is found, it updates the `isHighlighted` and `isIncluded` properties of the nodes.
 *
 * @param {Entity[]} entities - An array of entities representing the tree structure to traverse.
 * @param {object} targetItem - The item to match against entities in the tree.
 * @returns {Entity[]} A new array of entities with updated highlighting and inclusion statuses.
 */
export const highlightMatchedItem = (entities, targetItem) => {
  /**
   * Recursively traverses nodes and highlights the matched item without mutating the original data.
   *
   * @param {Entity[]} nodes - The current level of nodes to traverse.
   * @returns {{ updatedNodes: Entity[], isItemFound: boolean }} An object containing the updated nodes and a boolean indicating if the item was found.
   */
  const traverseAndHighlight = nodes => {
    let isItemFound = false;

    // Use map to create a new array with updated nodes
    const updatedNodes = nodes.map(node => {
      const updatedNode = { ...node }; // Create a shallow copy of the node

      if (updatedNode.children && updatedNode.children.length > 0) {
        // Recursively process child nodes
        const { updatedNodes: updatedChildren, isItemFound: isFound } =
          traverseAndHighlight(updatedNode.children);
        updatedNode.children = updatedChildren; // Assign updated children
        updatedNode.isIncluded = isFound; // Update the 'isIncluded' property
        isItemFound = isItemFound || isFound;
      } else if (updatedNode._id === targetItem._id) {
        // Check if the current node matches the target item
        updatedNode.isHighlighted = true; // Update the 'isHighlighted' property
        isItemFound = true;
      } else {
        updatedNode.isHighlighted = false; // Set 'isHighlighted' to false if not matched
      }

      // Ensure 'isIncluded' is false when not found
      if (!updatedNode.isIncluded) {
        updatedNode.isIncluded = false;
      }

      return updatedNode; // Return the updated node
    });

    return { updatedNodes, isItemFound };
  };

  // Use map to create a new array of entities with updated file structures
  const updatedEntities = entities.map(entity => {
    if (
      entity.files &&
      entity.files.children &&
      entity.files.children.length > 0
    ) {
      const { updatedNodes: updatedChildren, isItemFound } =
        traverseAndHighlight(entity.files.children);
      return {
        ...entity,
        files: {
          ...entity.files,
          children: updatedChildren, // Assign updated children
          isIncluded: isItemFound, // Update the 'isIncluded' property
        },
      };
    }
    return entity; // Return the entity unchanged if no children are found
  });

  return updatedEntities;
};

/**
 * Performs a file operation (ADD, DELETE, UPDATE) on the entity's file tree.
 *
 * @param {Entity} entities - The entity whose file tree needs to be updated.
 * @param {FileOperation} fileOperation - The file operation to perform, including type and file data.
 * @returns {Entity} The updated entity with modified file tree.
 */
export const updateTreeOperations = (
  entities: Entity,
  fileOperation: FileOperation,
) => {
  switch (fileOperation.type) {
    case 'ADD': {
      return addFileToFolderTree(entities.files, fileOperation.file);
    }
    case 'DELETE': {
      return deleteFileFromFolderTree(entities.files, fileOperation.file);
    }
    case 'UPDATE': {
      return updateFileInFolderTree(entities.files, fileOperation.file);
    }
  }
};

/**
 * Executes a file operation on a list of entities and dispatches a success toast.
 *
 * @param {Entity[]} entities - The list of entities to update.
 * @param {FileOperation} fileOperation - The file operation to perform, including type and file data.
 * @param {Function} dispatch - The dispatch function for Redux actions.
 * @param {object} toastActions - The toast actions object containing different toast methods.
 * @returns {Entity[]} A new array of entities with the file operation applied.
 */
export const performFileOperation = (
  entities: Entity[],
  fileOperation: FileOperation,
  dispatch: Function,
  toastActions: ToastActionsType,
): Entity[] => {
  const currentEntity = entities.find(
    entity => entity.groupId === fileOperation.file.groupId,
  );

  if (currentEntity) {
    currentEntity.files = updateTreeOperations(currentEntity, fileOperation);

    dispatch(
      toastActions.showSuccessToast({
        show: true,
        message: fileOperation.message,
      }),
    );
  }

  return entities.map(entity =>
    entity.groupId === currentEntity?.groupId ? currentEntity : entity,
  );
};
