/**
 *
 * Asynchronously loads the component for ModalCompanyTrust
 *
 */

import { lazyLoad } from 'utils/loadable';
import { SuspenseSpinner } from '../SuspenseSpinner';

export const ManageEntityModal = lazyLoad(
  () => import('./index'),
  module => module.ManageEntityModal,
  {
    fallback: <SuspenseSpinner type="linear" color="primary" />,
  },
);
