import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Stack,
} from '@mui/material';
import { capitalize } from '@mui/material/utils';
import {
  DeleteOutlined,
  EditOutlined,
  KeyOutlined,
  MoreVert,
} from '@mui/icons-material';
import { formatPhoneNumber } from 'utils/formats';
import style from './style';
import { ContactSummary } from 'app/features/AddressBookSlice/types';
import { useUser } from 'providers/UserProvider';

type Props = {
  editOnClick?: () => void;
  accessOnClick?: () => void;
  deleteOnClick?: () => void;
} & ContactSummary;

export function StakeholderCard({
  targetUserId,
  targetUserMeta,
  accessOnClick,
  deleteOnClick,
  editOnClick,
}: Props) {
  const { email, lastName, firstName, phoneNumber, countryCode } =
    targetUserMeta;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const { user } = useUser();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container key={targetUserId} sx={style.cardStyles}>
      <Grid item xs={4}>
        <Typography
          variant="body1"
          color="black.100"
          textTransform="capitalize"
        >
          {firstName} {lastName}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        {email && (
          <Box>
            <Typography variant="caption" color="text.secondary">
              {email}
            </Typography>
          </Box>
        )}
        {phoneNumber && (
          <Box>
            <Typography variant="caption" color="text.disabled">
              {formatPhoneNumber(phoneNumber, countryCode)}
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={3}></Grid>
      {targetUserId !== user?._id ? (
        <Grid item xs={1} sx={style.boxButtons}>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>

          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          >
            {editOnClick && (
              <MenuItem onClick={editOnClick} sx={style.menuItemStyles}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <EditOutlined sx={style.iconStyles} />
                  <Typography variant="body2">
                    {capitalize(t('stakeholder.button.edit'))}
                  </Typography>
                </Stack>
              </MenuItem>
            )}
            {accessOnClick && (
              <MenuItem onClick={accessOnClick} sx={style.menuItemStyles}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <KeyOutlined sx={style.iconStyles} />
                  <Typography variant="body2">
                    {capitalize(t('stakeholder.button.manageAccess'))}
                  </Typography>
                </Stack>
              </MenuItem>
            )}
            {deleteOnClick && (
              <MenuItem onClick={deleteOnClick} sx={style.menuItemStyles}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <DeleteOutlined sx={style.iconStyles} />
                  <Typography variant="body2">
                    {capitalize(t('stakeholder.button.delete'))}
                  </Typography>
                </Stack>
              </MenuItem>
            )}
          </Menu>
        </Grid>
      ) : (
        <Grid item xs={1}></Grid>
      )}
    </Grid>
  );
}
