import React from 'react';
import { Box, Typography } from '@mui/material';
import { Modal } from 'app/components/Modal';
import { Item, NameIdType } from 'app/features/AssetLiabilitySlice/types';
import { MultiselectDropdown } from 'app/components/MultiselectDropdown';
import { useTranslation } from 'react-i18next';
import { BottomRightGroupButtons } from 'app/components/Buttons/BottomRightGroupButtons/Loadable';
import style from './style';

interface Props {
  isOpen: boolean;
  selectLabel: string;
  placeholder?: string;
  optionsData?: Item[];
  setModalOpen: (value: boolean) => void;
  handleAssociate: (selectedValues: NameIdType[]) => void;
  modalText?: string;
  selectedValues?: Item[];
}

const getLiabilityListForSelectedAsset = (itemList?: Item[]): NameIdType[] => {
  return (
    itemList?.map(g => ({
      name: g?.baseContext.name,
      id: g?._id,
    })) || []
  );
};

export function AssociatedItemModal({
  isOpen,
  setModalOpen,
  handleAssociate,
  optionsData,
  placeholder,
  selectLabel,
  selectedValues,
  modalText,
}: Props) {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<NameIdType[]>([]);

  React.useEffect(() => {
    if (!isOpen) {
      setSelected(getLiabilityListForSelectedAsset(selectedValues));
    }
  }, [isOpen, selectedValues]);

  const handleSelect = (selectedList: NameIdType[]) => {
    setSelected(selectedList);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setModalOpen(false)}>
      <Box sx={style.modalContentStyles}>
        <Typography sx={style.modalTextStyles}>{modalText}</Typography>
        <MultiselectDropdown
          label={selectLabel}
          onSelect={handleSelect}
          onRemove={handleSelect}
          displayValue={'name'}
          placeholder={placeholder}
          optionsData={getLiabilityListForSelectedAsset(optionsData)}
          selectedValues={selected}
        />
      </Box>
      <BottomRightGroupButtons
        primaryBtnText={t('associateItem.buttons.cancel') as string}
        onPrimaryBtnClick={() => {
          setModalOpen(false);
        }}
        secondaryBtnText={t('associateItem.buttons.associate') as string}
        onSecondaryBtnClick={() => {
          handleAssociate(selected);
          setModalOpen(false);
        }}
      />
    </Modal>
  );
}
