/**
 *
 * Asynchronously loads the component for ErrorToast
 *
 */

import { lazyLoad } from 'utils/loadable';
import { SuspenseSpinner } from 'app/components/SuspenseSpinner';

export const Totals = lazyLoad(
  () => import('./index'),
  module => module.Totals,
  { fallback: <SuspenseSpinner type="circular" color="primary" /> },
);
