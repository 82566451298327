/**
 *
 * Asynchronously loads the component for ViewLiabilityPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ViewLiabilityPage = lazyLoad(
  () => import('./index'),
  module => module.ViewLiabilityPage,
);
