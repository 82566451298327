import React, { forwardRef } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Stack,
  //IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import style from './style';
// import Button from 'app/components//Buttons/Button/Button';

interface CardProps {
  Icon?: (props: any) => JSX.Element | null;
  title?: string;
  description?: string;
  action?: string;
}

export const AddingCard = forwardRef((props: CardProps, ref) => {
  const { title, description, action } = props;

  return (
    <Card sx={style.cardStyle}>
      <Stack sx={style.stackStyle}>
        <CardContent>
          <Typography sx={style.titleStyle}>{title}</Typography>
          <Typography sx={style.descriptionStyle}>{description}</Typography>
        </CardContent>
        <CardActions>
          <Button startIcon={<AddIcon />} sx={style.actionButtonStyle}>
            {action}
          </Button>
        </CardActions>
      </Stack>
    </Card>
  );
});
