import React from 'react';
import { useUser } from 'providers/UserProvider';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material/utils';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { UserAddress } from 'providers/UserProvider/types';
import { UserInformationForm } from 'app/components/Forms/UserInformationForm';
import { useDispatch } from 'react-redux';
import { toastActions } from 'app/features/ToastSlice';
import { useNavigate } from 'react-router';

export function ProfileTab() {
  const {
    editUser,
    user,
    isFetching,
    editUserSuccess,
    editUserError,
    isEditingUser,
    clearEditState,
  } = useUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLocked, setIsLocked] = React.useState(true);
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    countryCode: '',
    address: '',
    country: '',
    profile: '',
    interests: '',
    fears: '',
  };

  const useFormInstance = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isValid },
    setValue,
  } = useFormInstance;

  React.useEffect(() => {
    if (!user) return;
    if (user) {
      let address: UserAddress | undefined;
      if (user.addresses && user.addresses[0]) {
        address = user.addresses[0];
      }
      const fieldValues = {
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email || '',
        phoneNumber: user.phoneNumber || '',
        countryCode: user.countryCode || '',
        country: address?.country || '',
        address: address?.addressLine || '',
        profile: user.profile || '',
        interests: user.interests || '',
        fears: user.fears || '',
      };

      Object.keys(fieldValues).forEach(field => {
        setValue(field as keyof typeof fieldValues, fieldValues[field]);
      });
    }
  }, [user, setValue]);

  React.useEffect(() => {
    if (isEditingUser) return;
    if (editUserSuccess) {
      dispatch(
        toastActions.showSuccessToast({
          show: true,
          message: 'You have updated your profile succesfully',
        }),
      );
      setIsLocked(true);
      clearEditState();
    }
    if (editUserError) {
      dispatch(
        toastActions.showErrorToast({
          show: true,
          message: "Your profile couldn't be updated",
        }),
      );
    }
  }, [
    editUserSuccess,
    dispatch,
    isEditingUser,
    editUserError,
    clearEditState,
    navigate,
    setIsLocked,
  ]);

  const onSubmit = ({ country, address, ...values }) => {
    const formatted = {
      ...values,
      addresses: [
        {
          type: 'PRIMARY',
          addressLine: address,
          locality: '',
          region: '',
          postcode: '',
          country: country,
        },
      ],
    };
    if (user?.addresses?.length) {
      formatted.addresses.push(...user.addresses.slice(1));
    }
    editUser(formatted);
  };

  let loadingMessage = t('loading.header');
  if (isFetching) {
    loadingMessage = t('loading.body2');
  } else if (!user) {
    loadingMessage = t('loading.error', { message: 'User data not found' });
  }

  return (
    <>
      <Helmet>
        <title>{capitalize(t('profile.helmet.profilePage'))}</title>
        <meta name="description" content="MyFO application user profile" />
      </Helmet>
      <Grid spacing={1}>
        <Grid item md={true}>
          {(isFetching || !user) && (
            <div id="isLoading">{capitalize(loadingMessage)}</div>
          )}
          {!isFetching && user && (
            <Box my={2} component="form" onSubmit={handleSubmit(onSubmit)}>
              <UserInformationForm
                useFormInstance={useFormInstance}
                isValid={isValid}
                isLocked={isLocked}
                setIsLocked={setIsLocked}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}
