import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Typography } from '@mui/material';
import { useUser } from 'providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { getRoutePath } from 'app/routes/routePath';
import style from './style';
import { BottomRightGroupButtons } from 'app/components/Buttons/BottomRightGroupButtons/Loadable';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const InvitationPage = () => {
  const { t } = useTranslation();
  const { validateToken, validateTokenIsSuccess } = useUser();
  const navigate = useNavigate();
  const query = useQuery();

  useEffect(() => {
    sessionStorage.setItem('invitationTaskId', query.get('taskId') as string);
    sessionStorage.setItem('invitationGroupId', query.get('groupId') as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (validateTokenIsSuccess) {
      sessionStorage.setItem(
        'invitationToken',
        query.get('token') || 'invitationtoken',
      );
      navigate(getRoutePath({ target: 'signin' }));
    }
  }, [navigate, query, validateTokenIsSuccess]);

  const handleOnClickVerify = () => {
    validateToken(query.get('token') as string);
  };

  return (
    <Card sx={style.cardStyles}>
      <Typography
        data-test="invitation-page-heading-text"
        variant="h4"
        color="#101820"
        textTransform="capitalize"
      >
        {t('invitationPage.heading')}
      </Typography>
      <Typography
        variant="body1"
        color="text.disabled"
        sx={style.contentStyles}
      >
        {t('invitationPage.content')}
      </Typography>
      <BottomRightGroupButtons
        primaryBtnText={t('cancelButton') as string}
        onPrimaryBtnClick={() => navigate('/home')}
        secondaryBtnText={t('invitationPage.button.yesVerifyMe') as string}
        onSecondaryBtnClick={() => handleOnClickVerify()}
        buttonGroupStyles="center"
      />
    </Card>
  );
};
