import React from 'react';
import {
  LineChart as RechartsChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import {
  ChartData,
  chartSize,
  lineChartMargin,
  lineStyle,
  lineType,
} from '../style';

interface ChartProps {
  data: ChartData[];
  isDashboard?: boolean;
}

const dateFormatter = date => {
  return moment(date).format('MM/DD/YYYY');
};

export const LineChart = ({ data, isDashboard }: ChartProps) => {
  const chartData = data.map(report => ({
    ...report,
    date: moment(report.date, 'MM/DD/YYYY').valueOf(),
  }));
  return (
    <ResponsiveContainer {...chartSize}>
      <RechartsChart data={chartData} margin={{ ...lineChartMargin }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          domain={[chartData[0].date, chartData[chartData.length - 1].date]}
          scale="time"
          type="number"
          tickFormatter={dateFormatter}
        />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip labelFormatter={dateFormatter} />
        <Legend />
        <Line {...lineStyle} dataKey="value" type={lineType} />
        <Line {...lineStyle} dataKey="cost" type={lineType} stroke="#82ca9d" />
        <Line
          {...lineStyle}
          dataKey="totalValue"
          hide={!isDashboard}
          type={lineType}
          stroke="#000000"
        />
        <Line
          {...lineStyle}
          dataKey="purchasePrice"
          hide={!isDashboard}
          type={lineType}
          stroke="red"
        />
      </RechartsChart>
    </ResponsiveContainer>
  );
};
