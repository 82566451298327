import { Button, Input } from 'app/components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InfoOutlined } from '@mui/icons-material';
import { capitalize } from '@mui/material/utils';
import { Typography, Box } from '@mui/material';
import { muiTheme } from 'styles/muiTheme';

type Props = {
  onSubmit: (data: any) => void;
};

export function CreatePasswordForm({ onSubmit }: Props) {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  });

  const { t } = useTranslation();

  const mbTextSecondary = { marginBottom: 4 };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={muiTheme.variants.boxStyles}>
        <Typography variant="h5" sx={muiTheme.variants.mbTextPrimary}>
          {capitalize(t('createPasswordForm.header.createNewPassword'))}
        </Typography>

        <Typography
          sx={{ ...muiTheme.variants.secondaryLabel, mbTextSecondary }}
        >
          {capitalize(t('createPasswordForm.helperText.instructions'))}
        </Typography>
        <Box sx={muiTheme.variants.boxMarginStyles}>
          <Box sx={muiTheme.variants.pxBox}>
            <Input
              id="password"
              label={capitalize(t('createPasswordForm.label.setPassword'))}
              type="password"
              placeholder={capitalize(
                t('createPasswordForm.label.setPassword'),
              )}
              defaultValue=""
              helperText={
                errors.password
                  ? capitalize(`${errors.password.message}`)
                  : capitalize(t('passwordComplexityRule'))
              }
              HelperTextIcon={InfoOutlined}
              isError={Boolean(errors.password)}
              {...register('password', {
                required: {
                  value: true,
                  message: capitalize(t('thisFieldIsRequired')),
                },
                minLength: {
                  value: 8,
                  message: capitalize(t('passwordsShouldBe8CharactersOrMore')),
                },
                maxLength: {
                  value: 12,
                  message: capitalize(
                    t('passwordShouldNotBeMoreThan24CharactersLong'),
                  ),
                },
                validate: {
                  uppercase: v => {
                    const checkPassword =
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/.test(
                        v,
                      );

                    if (checkPassword) {
                      return true;
                    }

                    return capitalize(t('passwordComplexityRule'));
                  },
                },
              })}
            />
          </Box>
        </Box>
        <Box sx={muiTheme.variants.boxMarginStyles}>
          <Box sx={muiTheme.variants.pxBox}>
            <Input
              id="confirm"
              label={capitalize(
                t('createPasswordForm.label.passwordConfirmation'),
              )}
              type="password"
              placeholder={capitalize(
                t('createPasswordForm.label.confirmPassword'),
              )}
              defaultValue=""
              isError={Boolean(errors.confirm)}
              helperText={
                errors.confirm && capitalize(`${errors.confirm.message}`)
              }
              HelperTextIcon={InfoOutlined}
              {...register('confirm', {
                required: {
                  value: true,
                  message: capitalize(t('thisFieldIsRequired')),
                },
                validate: value =>
                  value === getValues('password') ||
                  capitalize(
                    t('createPasswordForm.helperText.passwordNotMatch'),
                  ),
              })}
            />
          </Box>
        </Box>
        <Box sx={muiTheme.variants.mtmbBox}>
          <Button
            id="submitButton"
            data-test="reset-password-submit-button"
            label={capitalize(t('createPasswordForm.button.resetPassword'))}
            fullWidth
            disabled={!isValid}
            variant="contained"
            type="submit"
          />
        </Box>
      </Box>
    </form>
  );
}
