export const container = {
  display: 'flex',
  flexDirection: 'column',
  background: 'white',
  padding: '20px',
  marginTop: '150px',
  width: '380px',
};

export const button = {
  background: '#ebf7ee',
  color: '#2d3c3d',
  alignSelf: 'center',
};
