import { Dispatch, SetStateAction } from 'react';
import { GlobalAppStateProps } from './types';

export const useSetFullView = (
  isFullView: boolean,
  globalAppState: GlobalAppStateProps,
  setGlobalAppState: Dispatch<SetStateAction<GlobalAppStateProps>>,
) => {
  setGlobalAppState({
    ...globalAppState,
    isFullView,
  });
};

export const useSetImpersonatedUser = (
  userImpersonated: boolean,
  globalAppState: GlobalAppStateProps,
  setGlobalAppState: Dispatch<SetStateAction<GlobalAppStateProps>>,
) => {
  setGlobalAppState({
    ...globalAppState,
    userImpersonated,
  });
};
