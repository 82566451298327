import { muiTheme } from 'styles/muiTheme';

export const modalContent = {
  width: '600px',
  maxWidth: '60vh',
  padding: '0px',
  paddingTop: '0px',
};

export const modalHeader = {
  borderBottom: '1px solid rgba(156, 150, 142, 0.3)',
  pb: 2,
  px: 2,
  mb: 3,
};

export const modalButtonStyle = {
  padding: 0,
  margin: 0,
};

export const modalBodyContainer = {
  padding: '0px 24px',
};

export const modalFooterButtons = {
  ...muiTheme.variants.bottomRightButtonsBox,
  marginTop: 0,
  paddingY: '15px',
  paddingRight: '16px',
};
