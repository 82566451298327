import { palette } from '../palette';

// TODO TEMP: Change below after finanlly set and activaged
export const dark = {
  ...palette,
  primary: {
    ...palette.primary,
    main: '#2C3C3D',
    dark: '#1B2A2B',
    medium: '#37494A',
    light: '#455556',
    contrast: '#FFFFFF',
    '4p': '#2C3C3D0A',
    '8p': '#2C3C3D14',
    '12p': '#2C3C3D1F',
    '30p': '#2C3C3D4D',
    '50p': '#2C3C3D80',
  },
  secondary: {
    main: '#5BBD77',
    dark: '#48925C',
    light: '#73CE8A',
    contrast: '#FFFFFF',
    '4p': '#5BBD770A',
    '8p': '#5BBD7714',
    '12p': '#5BBD771F',
    '30p': '#5BBD774D',
    '50p': '#5BBD7780',
  },
};
