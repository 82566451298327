import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.assetLiability || initialState;

export const selectAssetLiability = createSelector(
  [selectSlice],
  state => state,
);

export const selectAssets = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.assets,
);

export const selectGetAssetsError = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.getAssetsError,
);

export const selectIsAssociatingItem = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.isAssociatingItem,
);

export const selectIsCreatingAsset = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.isCreatingAsset,
);

export const selectCreateAssetStatus = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.assetCreateStatus,
);

export const selectCreatedAsset = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.createdAsset,
);

export const selectIsEditingAsset = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.isEditingAsset,
);

export const selectEditItemSuccess = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.itemEditStatus === 'success',
);

export const selectEditAssetSuccess = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.assetEditStatus === 'success',
);

export const selectIsCreatingLiability = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.isCreatingLiability,
);

export const selectCreateLiabilityStatus = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.liabilityCreateStatus,
);

export const selectCreatedLiability = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.createdLiability,
);

export const selectIsEditingLiability = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.isEditingLiability,
);
export const selectEditLiabilitySuccess = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.liabilityEditStatus === 'success',
);
export const selectisGettingViewLiability = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.isFetchingAssetView,
);

export const selectLiabilities = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.liabilities,
);

export const selectGetLiabilitiesError = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.getLiabilitiesError,
);

export const selectAssetLiabilityCalculation = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.assetsLiabilityCalculation,
);

export const selectSingleAsset = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.asset,
);

export const selectIsFetchingAssetView = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.isFetchingAssetView,
);

export const selectSingleLiability = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.liability,
);
export const selectIsdeletingLiability = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.isDeletingLiability,
);
export const selectIsdeletingAsset = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.isDeletingAsset,
);

export const selectDeleteItemSuccess = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.deleteItemStatus === 'success',
);

export const selectDeletingAssetError = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.deleteAssetError,
);
export const selectDeletingLiabilityError = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.deleteLiabilityError,
);
export const selectHoldingReadings = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.assestPercentageByClass,
);

export const selectAssetById = (assetId: string) =>
  createSelector([selectSlice], assetLiabilityState =>
    assetLiabilityState.assets?.find(({ _id }) => assetId === _id),
  );

export const selectLiabilityById = (liabilityId: string) =>
  createSelector([selectSlice], assetLiabilityState =>
    assetLiabilityState.liabilities?.find(({ _id }) => liabilityId === _id),
  );

export const selectIsFetchingAccountingData = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.isFetchingAccountingData,
);

export const selectAccountingData = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.accountingData,
);

export const selectAccountingBalanceSheet = createSelector(
  [selectSlice],
  assetLiabilityState =>
    assetLiabilityState.accountingData?.accountingBalanceSheet,
);

export const selectAccountingProfitAndLoss = createSelector(
  [selectSlice],
  assetLiabilityState =>
    assetLiabilityState.accountingData?.accountingProfitAndLoss,
);

export const selectAccountingCashFlow = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.accountingData?.accountingCashFlow,
);

export const selectGetAccountingDataError = createSelector(
  [selectSlice],
  assetLiabilityState => assetLiabilityState.getAccountingDataError,
);
