export const container = {
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: 'addition.lightGreyLabelColor',
};

export const textEllipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
