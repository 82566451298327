import React from 'react';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';
import { muiTheme } from 'styles/muiTheme';

type ButtonTypes =
  | 'outline'
  | 'contained'
  | 'text'
  | 'primary'
  | 'secondary'
  | 'tertiary';

interface Props {
  fontSize?: number;
  label?: string;
  variant?: ButtonTypes;
  Icon?: React.ComponentFactory<any, any>;
  iconRight?: boolean;
  iconOnly?: boolean;
}

export function Button({ children, ...props }: Props & MuiButtonProps) {
  const {
    variant = 'contained',
    fontSize,
    Icon,
    iconRight,
    label,
    iconOnly,
    sx,
  } = props;

  const svgMargins = {
    marginRight: !iconRight && !iconOnly ? '0.5rem' : 0,
    marginLeft: iconRight ? '0.5rem' : 0,
  };
  return (
    <MuiButton
      {...props}
      variant={variant}
      sx={{
        svg: {
          ...svgMargins,
        },
        fontSize: fontSize ?? 14,
        flexDirection: iconRight ? 'row-reverse' : 'row',
        ':disabled': {
          boxShadow: 'none',
        },
        color: muiTheme.palette.primary.contrast,
        ...sx,
      }}
    >
      {Icon && <Icon width={fontSize} />}
      {!iconOnly && (label || children)}
    </MuiButton>
  );
}
