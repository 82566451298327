import { AssetByType, HoldingReadingInterface } from './types';
import {
  getTotalDashboardItemsValue,
  getTotalValueOfClass,
} from '../../../utils/assetValues';
import { CurrencyStateProps } from 'providers/CurrencyProvider/types';
import { GroupData } from '../GroupSlice/types';

export const createHoldingReadings = ({
  data,
  currencyState,
  groups,
}: {
  data: AssetByType;
  currencyState: CurrencyStateProps;
  groups: GroupData[];
}) => {
  if (!data || !Object.keys(data).length) return [];
  const holdingReadings: HoldingReadingInterface[] = [];
  const totalValue = getTotalDashboardItemsValue(data, groups);

  Object.keys(data).forEach(eachAsset => {
    const addedSum: number = getTotalValueOfClass(
      data[eachAsset],
      eachAsset,
      groups,
    );
    const value: number = parseFloat(
      ((addedSum / totalValue) * 100).toFixed(2),
    );
    const className: string = data[eachAsset][0]?.baseContext?.type;

    holdingReadings.push({ className, value });
  });
  return holdingReadings;
};
