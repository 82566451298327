/**
 *
 * CustomTablePagination
 *
 */
import React from 'react';
import {
  Typography,
  Box,
  TableFooter,
  Table,
  TableRow,
  TablePagination,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import style from './style';

interface Props {
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowPerPage: number) => void;
  labelRowsPerPage: string;
  page?: number;
  rowsPerPage?: number;
  rowsPerPageOptions: number[];
  count: number;
  children: React.ReactNode;
}

export function CustomTablePagination(props: Props) {
  const {
    onPageChange,
    onRowsPerPageChange,
    labelRowsPerPage,
    rowsPerPageOptions,
    count,
    children,
  } = props;
  const [page, setPage] = React.useState(props.page || 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.rowsPerPage || rowsPerPageOptions[0],
  );

  const onItemsPerPageHandler = event => {
    onRowsPerPageChange(event.target.value);
    setRowsPerPage(event.target.value);
  };

  const onPageChangeHandler = (event, page) => {
    onPageChange(page);
    setPage(page);
  };

  return (
    <Box sx={style.boxStyles}>
      {children}
      <Table>
        <TableFooter sx={style.paginationStyles}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onPageChangeHandler}
              onRowsPerPageChange={onItemsPerPageHandler}
              labelRowsPerPage={
                <Typography component="span" variant="subtitle3">
                  {labelRowsPerPage}
                </Typography>
              }
              SelectProps={{
                IconComponent: KeyboardArrowDown,
                sx: style.muiSelectStyles,
              }}
              sx={style.tablePaginationStyles}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}
