import { createContext, ReactElement, useContext, useState } from 'react';
import {
  GlobalAppStateProps,
  GlobalAppProviderProps,
  GlobalAppUpdaterProps,
} from './types';
import { useSetFullView, useSetImpersonatedUser } from './updaters';

const defaultGlobalAppState: GlobalAppStateProps = {
  isFullView: false,
  userImpersonated: false,
};

const defaultUpdaters: GlobalAppUpdaterProps = {
  setFullView: () => {},
  setUserImpersonated: () => {},
};

const GlobalAppContext = createContext<
  GlobalAppStateProps & GlobalAppUpdaterProps
>({
  ...defaultGlobalAppState,
  ...defaultUpdaters,
});

export const GlobalAppProvider = ({
  initGlobalState,
  children,
}: GlobalAppProviderProps): ReactElement => {
  const [globalAppState, setGlobalAppState] = useState<GlobalAppStateProps>({
    ...defaultGlobalAppState,
    ...initGlobalState,
  });

  const wrapFunc = func =>
    function (...args) {
      // @ts-ignore
      const context = this;
      const changedArgs = [...args];
      changedArgs.push(globalAppState, setGlobalAppState);
      func.apply(context, changedArgs);
    };

  return (
    <GlobalAppContext.Provider
      value={{
        ...globalAppState,
        setFullView: wrapFunc(useSetFullView),
        setUserImpersonated: wrapFunc(useSetImpersonatedUser),
      }}
    >
      {children}
    </GlobalAppContext.Provider>
  );
};

export function useGlobalApp(): GlobalAppStateProps & GlobalAppUpdaterProps {
  const context = useContext(GlobalAppContext);
  if (context === undefined) {
    throw new Error('use  GlobalApp must be used within a  GlobalAppProvider');
  }
  return context;
}
