import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import { KeyboardArrowDown, Delete } from '@mui/icons-material';
import { ListItemAccordion } from 'app/components/ListItemAccordion/Loadable';
import style from './style';
import type { Scenario as ScenarioType } from 'app/pages/scenario-modeling/components/Scenario';
import { Scenario } from 'app/pages/scenario-modeling/components/Scenario';
import { Button } from 'app/components';
import { useDeleteScenarioMutation } from 'app/features/api/apiSlice';
import { useParams } from 'react-router';

interface Props {
  scenario: ScenarioType;
}

const SavedScenario = ({ scenario }: Props) => {
  const { t } = useTranslation();

  const { groupId } = useParams<{ groupId: string }>();
  const [deleteScenario] = useDeleteScenarioMutation();

  const handleDeleteSavedScenario = () => {
    deleteScenario({ groupId, scenarioId: scenario?._id });
  };

  const renderTitleElement = () => (
    <Grid sx={style.boxTitleStyles}>
      <Grid>
        <Typography variant="body1" sx={style.classTitleStyles}>
          {scenario?.title}
        </Typography>
      </Grid>
      <Grid>
        <Typography fontWeight="bold">
          {scenario?.projectHoldings?.total_net_worth}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid sx={style.boxContainerStyles}>
      <ListItemAccordion
        name={t('scenarioModeling.savedScenarios.headers.savedScenarios')}
        title={renderTitleElement()}
        expandIcon={<KeyboardArrowDown sx={style.boxArrowIconStyles} />}
        accordionSummarySx={style.accordionSummaryStyles}
        accordionSx={style.accordionStyles}
        contentListSx={style.contentLiStyles}
      >
        <Grid sx={style.boxDetailsStyles}>
          <Scenario isSavedScenario={true} scenario={scenario} />
          <Grid sx={style.buttonContainer}>
            <Button
              onClick={handleDeleteSavedScenario}
              style={style.deleteButton}
            >
              Delete
              <Delete sx={style.deleteIcon} />
            </Button>
          </Grid>
        </Grid>
      </ListItemAccordion>
    </Grid>
  );
};

export default SavedScenario;
