/**
 *
 * Asynchronously loads the component for ContinueMarketingUser
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ContinueMarketingUser = lazyLoad(
  () => import('./index'),
  module => module.ContinueMarketingUser,
);
