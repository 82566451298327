import { PDFViewer } from '../pdf-viewer';
import { NoPreview } from '../no-preview';
import { useTranslation } from 'react-i18next';

export const VaultFile = ({ src, type, fileName }) => {
  const { t } = useTranslation();
  switch (type) {
    case 'image':
      return (
        <img
          width="80%"
          src={src}
          alt="Preview"
          style={{ marginTop: '50px' }}
        />
      );
    case 'pdf':
      return <PDFViewer src={src} />;
    case 'video':
      return (
        <video width="100%" height="100%" controls>
          <source src={src} type="video/mp4" />
          {t('vault.labels.noSupport')}
        </video>
      );
    default:
      return <NoPreview url={src} fileName={fileName} />;
  }
};
